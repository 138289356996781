import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useSelector} from 'react-redux';
import Title from '../../components/Title';
import Body from '../../layout/Body';
import { getTablesSchema } from '../../../../api/AdminController';
// import { setColor } from '../../../assets/styles/styles';


const Tables = () => {
    const [tables, setTables] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const token = useSelector((state) => state?.user?.token);


    const fetchTablesData = async () => {
      const response =await getTablesSchema(token)
      console.log(response.data)
      setTables(response.data.data)
      setIsLoading(false)
    }

    useEffect(() => {
      setIsLoading(true)
      fetchTablesData()
    }, []);

    // List of tables showed in admin
    const filteredTables = [
      "action_defitions",
      "avatar",
      "orders",
      "room_detail_images",
      "action_log",
      "form_options",
      "users",
      "events_log",
      "room_settings",
      "vanity_link_data",
      "files",
      "form_questions",
      "forms",
      "rooms",
      "vanity_links"
    ]
    
    return (
      <>
        <Title
                  title={'TABLES OVERVIEW'}
                  description={
                      'Overview of tables schema Intraverse. Click on a table name to get more detailed information on the records.'
                  }
        />
        <Body isLoading={isLoading}>
        <GridTableContainer>
                {
                  tables.map((table, i) => {
                    console.log(table)
                    if (!filteredTables.includes(table.table_name)) {
                      return <></>
                    }
                    return (
                      <Link style={{textDecoration: "none"}} to={`/console/table/${table.table_name}`}>
                        <Card key={`card-${i}-${table.table_name}`}>
                          <p key={`table-${i}-${table.table_name}`}>{table.table_name}</p>
                        </Card>
                      </Link>
                    )
                  })
                }
        </GridTableContainer>
        </Body>
        

      </>
    );
};

const Card = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 6px;
  background-color: #252333;
  cursor: pointer;
  color: #FBFCFF;
  text-decoration: none;
  border-radius: 8px;
  font-size: 14px;
  &:hover {
    background-color: #beaeff;
    color: #000;
  }
`

const GridTableContainer = styled.div`
  display: grid;
  align-self: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  font-size: 22px;
  grid-gap: 10px;
  color: white;
`

export default Tables;
