import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import PlayersHero from './IntraverseHomeImages/Players_Hero_Splash_Cropped.png';
import DiamondBackground from '../../assets/images/backgrounds/Diamond_Checkered_Background.png';
import { blurbTitleData } from '../../constants';
import { setColor } from '../../assets/styles/styles';

const IntraverseBlurbSegment = () => {
    const [blurbIndex, setCurrentBlurbIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsVisible(false);
            setTimeout(() => {
                setCurrentBlurbIndex((prevIndex) => (prevIndex + 1) % blurbTitleData.length);
                setIsVisible(true);
            }, 1000);
        }, 2500);

        return () => clearInterval(interval);
    }, []);

    const currentFeature = blurbTitleData[blurbIndex];

    return (
        <BlurbSegmentContainer>
            <BlurbSegmentSection>
                <BlurbSegmentTextContainer>
                    <TitleJoiner>
                        <FixedText>THE</FixedText>
                        <BlurbSegmentTitle isVisible={isVisible}>{currentFeature.title}</BlurbSegmentTitle>
                    </TitleJoiner>
                    <BlurbSegmentBodyText>
                        Intraverse is a new platform innovating on how music reaches fans, and what artists can do with
                        their music. It is the future of <span> immersive</span> social engagement, e-commerce,
                        community development and virtual experiences on the Internet--where users can immediately join
                        and interact with the artists, creators, brands and content they love.
                    </BlurbSegmentBodyText>
                    {/* <BlurbSegmentBodyText dangerouslySetInnerHTML={{ __html: currentFeature.text }} /> 
          this will allows us to add a span for any changing dynamic text added later on currentFeature list text, 
          can remove if not needed */}
                </BlurbSegmentTextContainer>
                <BlurbSegmentMainImage src={PlayersHero} alt="Blurb Segment Main" />
            </BlurbSegmentSection>
        </BlurbSegmentContainer>
    );
};

export default IntraverseBlurbSegment;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
  `;
const BlurbSegmentContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(27, 27, 35, 1);
`;

const BlurbSegmentSection = styled.div`
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0 0 0;
    background-image: url(${DiamondBackground});
    background-size: 350px auto;
`;

const BlurbSegmentMainImage = styled.img`
    width: 95%;
    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
        margin-top: 2.5rem;
        max-width: none;
        width: 550px;
        margin-left: 1rem;
    }
    @media (min-width: 768px) and (max-width: 960px) {
        margin-top: 2.5rem;
        max-width: none;
        width: 750px;
        margin-left: 1rem;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        max-width: none;
        width: 100%;
        margin-top: 2.5rem;
    }
`;
const BlurbSegmentTextContainer = styled.div`
    width: 80%;
    min-height: 160px;
    margin: 5rem;

    @media (max-width: 960px) {
        margin: 1rem;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        margin: 2rem;
    }
`;
const TitleJoiner = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 580px) {
        flex-direction: column;
        min-height: 200px;
    }

    @media (min-width: 580px) and (max-width: 960px) {
        min-height: 90px;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        min-height: 90px;
    }
`;
const FixedText = styled.h1`
    text-align: left;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 5rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${setColor.nextStepText};

    @media (max-width: 960px) {
        font-size: 3rem;
        line-height: normal;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        font-size: 4rem;
        line-height: normal;
    }
`;

const BlurbSegmentTitle = styled.h1`
    color: ${setColor.accent4};
    text-align: left;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 5rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-left: 0.8rem;

    animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 1s ease-in-out;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 1s ease-in-out;

    @media (max-width: 580px) {
        font-size: 3rem;
        margin-left: 0rem;
        margin: 0;
        line-height: normal;
    }

    @media (min-width: 580px) and (max-width: 960px) {
        font-size: 3rem;
        line-height: normal;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        font-size: 4rem;
        line-height: normal;
    }
`;

const BlurbSegmentBodyText = styled.p`
    color: rgba(251, 251, 251, 0.8);
    text-align: left;
    font-family: 'Barlow', sans-serif;
    font-size: 1.4rem;
    letter-spacing: 1px;

    & > span {
        color: ${setColor.spanTextcolor2};
        font-weight: 700;
    }

    @media (max-width: 960px) {
        font-size: 1rem;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        font-size: 1.2rem;
    }
`;
