import React from 'react';
import styled from 'styled-components';
import RobotImage from './IntraverseHomeImages/Mascot_Robot_Cropped.png';
import DiamondBackground from '../../assets/images/backgrounds/Diamond_Checkered_Background.png';
import { setColor } from '../../assets/styles/styles';

const LandingSubscribe = () => (
    <SubscribeContainer>
        <SubscribeBorder>
            <ImageContainer>
                <SubscribeTextContainer>
                    <WhatsNextText>What's Next?</WhatsNextText>
                    <SubscribeBodyText>
                        Exciting projects coming up! Subscribe to stay ahead of Intraverse news.
                    </SubscribeBodyText>
                    <InputContainer>
                        <SubscribeInput placeholder="Whats your email?" />
                        <SubscribeButton>Subscribe</SubscribeButton>
                    </InputContainer>
                </SubscribeTextContainer>
                <SubscribeImage src={RobotImage} alt="Robot Image" />
            </ImageContainer>
        </SubscribeBorder>
    </SubscribeContainer>
);

export default LandingSubscribe;

const SubscribeContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: rgba(27, 27, 35, 1);
    background-image: url(${DiamondBackground});
    background-size: 350px auto;
    padding-top: 7rem;
    align-items: center;

    @media (max-width: 768px) {
        padding-top: 4rem;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        padding-top: 5rem;
    }
`;

const SubscribeImage = styled.img`
    width: 60%;
    display: flex;
    justify-content: center;
    flex: 0;

    @media (max-width: 768px) {
        width: 40%;
        align-self: center;
    }

    @media (min-width: 768px) and (max-width: 1440px) {
        width: 30%;
        align-self: center;
        margin-top: 2rem;
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        align-content: center;
    }

    @media (min-width: 768px) and (max-width: 1440px) {
        flex-direction: column;
        width: 100%;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    @media (min-width: 768px) and (max-width: 1440px) {
        flex-direction: column;
        width: 100%;
    }
`;

const SubscribeButton = styled.button`
    cursor: pointer;
    height: 60px;
    width: 250px;
    border-radius: 10px;
    border: 2px solid ${setColor.accent4};
    background: rgba(37, 24, 89, 0.5);
    font-family: 'Barlow', sans-serif;
    margin: 2rem 0 0 2rem;

    color: ${setColor.accent4};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2.6px;
    text-transform: uppercase;

    @media (max-width: 768px) {
        height: 50px;
        width: 100%;
        padding-left: 0rem;
        padding-top: 0.7rem;
        padding: 0rem;
        margin: 1rem;
        font-size: 20px;
    }

    @media (min-width: 768px) and (max-width: 1440px) {
        /* padding-left: 0.5rem; */
        height: 60px;
        margin: 0 1.5rem 1.5rem 1.5rem;
        width: 100%;
    }
`;

const SubscribeInput = styled.input`
    border-radius: 10px;
    border: 2px solid ${setColor.loadingButtonBg};
    background: ${setColor.inputBgColor};
    width: 611px;
    height: 60px;
    flex-shrink: 0;
    margin-top: 2rem;
    padding-left: 1rem;
    font-family: 'Barlow', sans-serif;
    font-size: 18px;
    font-style: italic;
    color: white;

    @media (max-width: 768px) {
        height: 50px;
        width: 100%;
        margin-top: 2rem;
        font-size: 20px;
    }

    @media (min-width: 768px) and (max-width: 1440px) {
        width: 100%;
        padding-left: 0.5rem;
        height: 60px;
        margin: 1.5rem;
    }
`;

const SubscribeBorder = styled.div`
    width: 96.7%;
    margin: 0 1.66%;
    padding: 0 6rem;
    display: flex;
    align-items: left;
    flex-direction: column;
    border-bottom: 3px solid ${setColor.tickerBorder};

    @media (max-width: 768px) {
        padding: 0 0;
    }

    @media (min-width: 768px) and (max-width: 1440px) {
        padding: 0 0;
    }
`;

const SubscribeTextContainer = styled.div`
    width: 100%;
    margin: 5rem 1rem 1rem 5rem;
    padding: 3rem;
    display: flex;
    flex: 0;
    align-items: left;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 768px) {
        margin: 0rem;
        padding: 0rem;
        width: 95%;
        align-self: center;
        flex-direction: column;
    }

    @media (min-width: 768px) and (max-width: 1440px) {
        margin: 0rem;
        width: 95%;
        padding: 0rem;
        align-self: center;
        flex-direction: column;
        align-content: center;
    }
`;

const WhatsNextText = styled.h1`
    text-align: left;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 5rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${setColor.nextStepText};
    margin-bottom: 2rem;

    @media (max-width: 768px) {
        font-size: 3rem;
        text-align: center;
    }

    @media (min-width: 768px) and (max-width: 1440px) {
        font-size: 4rem;
        text-align: center;
    }
`;

const SubscribeBodyText = styled.p`
    color: ${setColor.circularProgress};
    text-align: left;
    font-family: 'Barlow', sans-serif;
    font-size: 1.5rem;
    letter-spacing: 1px;
    font-weight: 600;

    @media (max-width: 768px) {
        width: 100%;
        font-size: 1rem;
        text-align: center;
    }

    @media (min-width: 768px) and (max-width: 1440px) {
        width: 100%;
        font-size: 1.3rem;
        text-align: center;
    }
`;
