/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from '../loading/LoadingIcon';
import styled from 'styled-components';
import FwdButtonIcon from '../../../assets/images/spotify/FwdButtonImg.svg';
import PlayButton from '../../../assets/images/spotify/PlayButtonImg.svg';
import PauseButtonIcon from '../../../assets/images/spotify/PauseButtonImg.svg';
import { setAlert, setBoxShadow, setColor, setFont } from '../../../assets/styles/styles';
import Iconify from '../icon/Iconify';
import { NavLink } from 'react-router-dom';

/**
 * Primary UI component for user interaction
 */
const Button = ({
    variant,
    backgroundColor,
    label,
    showMessage,
    error,
    margin,
    success,
    iconCenter,
    iconRight,
    iconLeft,
    iconSize,
    buttonWidth,
    iconTransition,
    labelColor,
    onHoverColor,
    iconColor,
    boxShadow,
    border,
    disabled,
    isPaused,
    handleSpotifyPause,
    customIcon,
    height,
    style,
    isActivated,
    borderRadius,
    ...props
}) => {
    const [loading, setLoading] = useState(false);
    const [onHover, setOnHover] = useState(false);

    const ButtonComponent = Button.variants[variant] || Button.variants['DefaultButton'];

    return (
        <>
            {showMessage && (
                <MessageStyle success={success} error={error}>
                    <p>{showMessage}</p>
                </MessageStyle>
            )}
            {variant === 'SpotifyButtons' ? (
                <PlayButtonsContainer landscape={props.landscape}>
                    <FwdButton
                        src={FwdButtonIcon}
                        alt="Fwd Button"
                        // onClick={() => handleSpotifyNext()}
                    />
                    <PauseButton
                        src={isPaused ? PlayButton : PauseButtonIcon}
                        alt="Pause Button"
                        //    onClick={() => handleSpotifyPause()}
                    />
                </PlayButtonsContainer>
            ) : variant === 'otherButton' ? (
                <PlayButtonsContainer landscape={props.landscape}></PlayButtonsContainer>
            ) : (
                <ButtonComponent
                    {...props}
                    // onClick={() => setLoading(true)}
                    onMouseOver={() => setOnHover(true)}
                    onMouseOut={() => setOnHover(false)}
                    buttonWidth={buttonWidth}
                    backgroundColor={backgroundColor}
                    labelColor={labelColor}
                    onHoverColor={onHoverColor}
                    iconColor={iconColor}
                    margin={margin}
                    boxShadow={boxShadow}
                    border={border}
                    disabled={disabled || loading}
                    style={style}
                    isActivated={isActivated}
                >
                    {iconLeft && (
                        <Iconify
                            icon={iconLeft}
                            size={iconSize}
                            iconMargin={'0px 10px 0px 0px'}
                            iconTransition={iconTransition}
                            Color={
                                onHover && onHoverColor !== null
                                    ? onHoverColor
                                    : onHover && onHoverColor === null
                                    ? setColor.accent1
                                    : iconColor !== null && !onHover
                                    ? iconColor
                                    : setColor.textPrimary
                            }
                        />
                    )}

                    {iconCenter && (
                        <Iconify
                            icon={iconCenter}
                            size={iconSize}
                            iconMargin={'0px'}
                            iconTransition={iconTransition}
                            Color={
                                onHover && onHoverColor !== null
                                    ? onHoverColor
                                    : onHover && onHoverColor === null
                                    ? setColor.accent1
                                    : iconColor !== null && !onHover
                                    ? iconColor
                                    : setColor.textPrimary
                            }
                        />
                    )}

                    {customIcon && (
                        <img
                            src={customIcon}
                            alt="customIcon"
                            style={{
                                margin: '5px',
                                color: backgroundColor ? labelColor : 'white',
                                cursor: 'default',
                                height: '20px',
                                width: '20px',
                            }}
                        />
                    )}

                    {loading ? (
                        <LoadingIconContainer>
                            <LoadingIcon />
                        </LoadingIconContainer>
                    ) : (
                        label
                    )}

                    {iconRight && (
                        <Iconify
                            icon={iconRight}
                            size={iconSize}
                            iconMargin={'0px 0px 0px 10px'}
                            Color={
                                onHover && onHoverColor !== null
                                    ? onHoverColor
                                    : onHover && onHoverColor === null
                                    ? setColor.accent1
                                    : iconColor !== null && !onHover
                                    ? iconColor
                                    : setColor.textPrimary
                            }
                        />
                    )}
                </ButtonComponent>
            )}
        </>
    );
};

export default Button;

Button.variants = {
    DefaultButton: styled.button`
        padding: 10px 30px;
        border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '5px')};
        cursor: pointer;
        display: inline-block;
        line-height: 1;
        height: auto;
        min-height: 58px;
        margin: ${(props) => props.margin};
        width: ${(props) => (props.buttonWidth ? props.buttonWidth : 'fit-content')};
        font-size: 1.125rem;
        color: ${(props) => (props.labelColor ? props.labelColor : '#beaeff')};
        background: ${setColor.onHoverTextcolor2} !important;
        /* background-color: black; */
        border: ${(props) => (props.border != null ? `1px solid  ${props.border}` : 'transparent')};
        transition: 0.3s;
        box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : setBoxShadow.default)};
        font-family: ${setFont.barlow};
        font-weight: 700;
        text-transform: uppercase;
        :hover {
            transition: 0.3s;
            background: #beaeff !important;
            color: ${(props) => (props.onHoverColor ? props.onHoverColor : setColor.onHoverTextcolor2)};
        }
        :active {
            background: #beaeff !important;
            color: ${(props) => (props.onHoverColor ? props.onHoverColor : setColor.onHoverTextcolor2)};
        }
        @media (max-width: 768px) {
            font-size: 16px;
            height: auto;
            min-height: 0;
            padding: 14px;
        }

        @media (max-width: 320px) {
            font-size: 16px;
            height: auto;
            width: 100%;
            padding: 14px;
        }
    `,
    OutlinedButton: styled.button`
        border: ${(props) => (props.border != null ? `2px solid ${props.border}` : '2px solid #beaeff')};
        padding: 10px 45px;
        border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '5px')};
        cursor: pointer;
        display: inline-block;
        line-height: 1;
        height: ${(props) => (props.height ? props.height : 'auto')};
        min-height: 58px;
        margin: ${(props) => props.margin};
        width: ${(props) => (props.buttonWidth ? props.buttonWidth : 'fit-content')};
        font-size: 16px;
        color: ${(props) => (props.labelColor ? props.labelColor : '#beaeff')};
        background: transparent;
        background: ${(props) =>
            props.backgroundColor ? props.backgroundColor : props.disabled ? setColor.grey1 : 'transparent'};
        transition: 0.3s;
        box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : setBoxShadow.default)};
        font-family: ${setFont.barlow};
        font-weight: 700;
        text-transform: uppercase;
        :hover {
            transition: 0.3s;
            background-color: transparent !important;
            color: ${(props) => (props.onHoverColor ? props.onHoverColor : '#beaeff')};
        }

        @media (max-width: 768px) {
            font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
            height: auto;
            min-height: 65px;
            padding: 14px;
            width: 100%;
        }

        @media (max-width: 320px) {
            font-size: 16px;
            height: auto;
            width: 100%;
            padding: 14px;
        }
    `,
    PinkButton: styled.button`
        padding: 10px 45px;
        border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '5px')};
        cursor: pointer;
        display: inline-block;
        line-height: 1;
        height: auto;
        min-height: 50px;
        padding: ${(props) => props.padding};
        margin: ${(props) => props.margin};
        width: ${(props) => (props.buttonWidth ? props.buttonWidth : 'fit-content')};
        font-size: 16px;
        color: ${(props) => (props.labelColor ? props.labelColor : props.disabled ? '#9DA0A7' : setColor.inputText)};
        background: ${(props) => (props.disabled ? setColor.grey1 : setColor.pinkButtonBgGradient)};
        border: ${(props) => (props.border != null ? `1px solid  ${props.border}` : 'transparent')};
        transition: 0.3s;
        box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : setBoxShadow.default)};
        font-family: ${setFont.barlow};
        font-weight: 700;
        text-transform: uppercase;
        :hover {
            transition: 0.3s;
            /* background-color:  transparent !important; */
            background-color: ${(props) => (props.disabled ? 'grey' : `${setColor.grey1} !important`)};
            color: ${(props) => (props.onHoverColor ? props.onHoverColor : setColor.secondary)};
        }

        @media (max-width: 768px) {
            font-size: 16px;
            height: 54px;
            min-height: 50px;
            min-width: 90%;
            padding: 14px;
        }

        @media (max-width: 320px) {
            font-size: 16px;
            height: auto;
            width: 100%;
            padding: 14px;
        }
    `,
    VanityLinkCustomizable: styled.button`
        border: ${(props) =>
            props.border ? `1px solid ${props.border}` : props.disabled ? 'none' : `2px solid ${setColor.accent4}`};
        padding: 10px 45px;
        border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '0px')};
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        height: ${(props) => (props.height ? props.height : 'auto')};
        min-height: 58px;
        margin: ${(props) => (props.margin ? props.margin : '1rem 0rem')};
        width: ${(props) => (props.buttonWidth ? props.buttonWidth : '100%')};
        font-size: 16px;
        color: ${(props) => (props.labelColor ? props.labelColor : props.disabled ? '#9DA0A7' : setColor.accent4)};
        background: ${(props) =>
            props.backgroundColor ? props.backgroundColor : props.disabled ? setColor.grey1 : 'rgba(37, 24, 89, 0.5)'};
        transition: 0.3s;
        box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : setBoxShadow.default)};
        font-family: ${setFont.barlow};
        font-weight: 700;
        text-transform: uppercase;
        :hover {
            transition: 0.3s;
            background-color: ${(props) => (props.disabled ? setColor.grey1 : `transparent !important`)};
            color: ${(props) =>
                props.onHoverColor ? props.onHoverColor : props.disabled ? 'white' : setColor.accent4};
        }

        @media (max-width: 768px) {
            font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
            height: auto;
            min-height: 65px;
            padding: 14px;
            width: 100%;
        }

        @media (max-width: 320px) {
            font-size: 16px;
            height: auto;
            width: 100%;
            padding: 14px;
        }
    `,
    DisabledButton: styled.button`
        border: none;
        padding: 10px 45px;
        border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '0px')};
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        height: ${(props) => (props.height ? props.height : 'auto')};
        min-height: 58px;
        margin: ${(props) => (props.margin ? props.margin : '1rem 0rem')};
        width: ${(props) => (props.buttonWidth ? props.buttonWidth : '100%')};
        font-size: 16px;
        color: #9da0a7;
        background: ${setColor.grey1};
        transition: 0.3s;
        box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : setBoxShadow.default)};
        font-family: ${setFont.barlow};
        font-weight: 700;
        text-transform: uppercase;
        :hover {
            transition: 0.3s;
            background-color: ${setColor.grey1};
            color: white;
        }

        @media (max-width: 768px) {
            font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
            height: auto;
            min-height: 65px;
            padding: 14px;
            width: 100%;
        }

        @media (max-width: 320px) {
            font-size: 16px;
            height: auto;
            width: 100%;
            padding: 14px;
        }
    `,
    NavigationButton: styled(NavLink)`
        text-decoration: none;
        border: 1px solid var(--purple-purple-20, rgba(190, 174, 255, 0.2));
        padding: 5px 20px;
        border-radius: 5px;
        cursor: pointer;
        display: inline-block;
        letter-spacing: 1.5px;
        height: ${(props) => (props.height ? props.height : 'auto')};
        margin: ${(props) => props.margin};
        width: ${(props) => (props.buttonWidth ? props.buttonWidth : 'fit-content')};
        font-size: 16px;
        color: var(--Text-Light-Grey, #9da0a7);
        background: transparent;
        transition: 0.3s;
        font-family: ${setFont.barlow};
        font-weight: 700;
        text-transform: uppercase;

        &.active {
            background: #beaeff;
            color: var(--bg-primary-100, #1b1b23);
        }

        @media (max-width: 768px) {
            font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
            font-weight: 800;
        }

        @media (max-width: 320px) {
            font-size: 16px;
            height: auto;
            width: 100%;
            padding: 10px 20px;
        }
    `,
};

Button.propTypes = {
    variant: PropTypes.oneOf(['DefaultButton', 'OutlinedButton', 'PinkButton', 'SpotifyButtons']),
    backgroundColor: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    /**
     * Show message if trigger was successful or error
     */
    showMessage: PropTypes.string,
    /**
     * Shows red text
     */
    error: PropTypes.bool,
    /**
     * Shows green text
     */
    success: PropTypes.bool,
    /**
     * Shows icon in either side, or both sides
     */
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    iconCenter: PropTypes.string,
    iconSize: PropTypes.string,
    /**
     * Slides Icon from left to right on Horizontal, and size adjusts the icon height
     */
    buttonWidth: PropTypes.string,
    margin: PropTypes.string,
    boxShadow: PropTypes.string,
    disabled: PropTypes.bool,
    isPaused: PropTypes.bool,
    customIcon: PropTypes.node,
    style: PropTypes.object,
    borderRadius: PropTypes.string,
};

Button.defaultProps = {
    variant: 'DefaultButton',
    backgroundColor: '',
    onClick: undefined,
    iconRight: null,
    iconLeft: null,
    iconSize: '',
    buttonWidth: '',
    showMessage: '',
    success: null,
    error: null,
    label: '',
    labelColor: '',
    onHoverColor: '',
    iconColor: '',
    margin: '',
    boxShadow: '',
    isPaused: false,
    customIcon: '',
    style: {},
    borderRadius: '',
};

const MessageStyle = styled.div`
    color: ${(props) => (props.error ? setAlert.danger : props.success ? setAlert.success : setColor.textPrimary)};
    padding: 20px;
    font-family: ${setFont.barlow};
`;
const LoadingIconContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;
const PlayButtonsContainer = styled.div`
    display: flex;
    align-items: stretch;
    padding-top: 10px;
    margin-right: 1.5rem;
    height: 150px;
    width: 150px;

    @media (max-width: 425px) {
        flex-direction: ${(props) => (props.landscape ? 'row' : 'column-reverse')};
        padding: 5px;
        align-items: center;
        justify-content: center;
        padding-top: 0px;
    }
`;

const FwdButton = styled.img`
    flex: 1;
    height: 100%;
    padding: 10px 0 10px 0;
    margin-left: 5px;
    border: none;
    cursor: pointer;
    /* height: 150px; */
    width: 150px;

    @media (max-width: 960px) {
        padding: 5px 0 0 10px;
    }
    @media (max-width: 425px) {
        /* padding: 5px 0 0 0; */
    }
`;
const PauseButton = styled.img`
    height: 100%;
    flex: 1;
    padding: 5px;
    padding: 5px 5px 5px 0;
    /* margin-left: 5px; */
    margin-right: 10px;
    border: none;
    cursor: pointer;

    @media (max-width: 960px) {
        padding: 0 10px 0 0;
    }
    @media (max-width: 425px) {
        margin-right: 0px;
        padding: 5px 0 0 0;
    }
`;
