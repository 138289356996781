import React from 'react';
import styled from 'styled-components';
import IntraverseHeroBanner from './IntraverseHeroBanner';
import IntraverseBlurbSegment from './IntraverseBlurbSegment';
import IntraverseFeatures from './IntraverseFeatures';
import ImmersiveExperiences from './ImmersiveExperiences';
import LandingSubscribe from './LandingSubscribe';
import LandingTicker from './LandingTicker';
import LandingFooter from './LandingFooter';

const IntraverseLandingHome = () => (
    <>
        <IntraverseHomeContainer>
            <IntraverseHeroBanner />
            <BorderOutlineStroke />
            <IntraverseBlurbSegment />
            <IntraverseFeatures />
            <ImmersiveExperiences />
            <LandingSubscribe />
            <LandingTicker />
            <LandingFooter />
        </IntraverseHomeContainer>
    </>
);

export default IntraverseLandingHome;

const BorderOutlineStroke = styled.div`
    position: absolute;
    width: 97%;
    height: 99.4%;
    opacity: 0.2;
    pointer-events: none;
    top: 0.3%;
    left: 1.5%;
    z-index: 3000;
    border: 3px solid #d9d9d9;

    @media (max-width: 768px) {
        top: 0.15%;
        height: 99.7%;
    }
`;

const IntraverseHomeContainer = styled.div`
    width: 100%;
    position: absolute;
    overflow: hidden;
`;
