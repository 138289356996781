import UAParser from 'ua-parser-js';
import { DeleteUser } from '../redux/actions/UserActions';
import { DeleteMerchant } from '../redux/actions/MerchantActions';
import { 
    COREAPI_WEB_USERS_URL, 
    DISCOVERSE_OWNER_ID, 
    COREAPI_WEB_EVENTS_URL, 
    COREAPI_WEB_VANITY_URL,
    COREAPI_WEB_ROOM_URL,
    LOG_EVENTS_DEVICE_DATA,
    LOG_EVENTS_USER_LOGIN_DATA,
} from '../constants';
import axiosInstance, { API_POST, API_GET } from '../config/axiosConfig';
import { getFromSessionStorage } from '../hooks/useSessionStorage';

const COREAPIURL = `${process.env.REACT_APP_CORE_API_URI}${COREAPI_WEB_USERS_URL}`;
const COREAPIEVENTURL = `${process.env.REACT_APP_CORE_API_URI}${COREAPI_WEB_EVENTS_URL}`;
const COREAPIWEBVANITYURL = `${process.env.REACT_APP_CORE_API_URI}${COREAPI_WEB_VANITY_URL}`;
const COREAPIWEBROOMURL = `${process.env.REACT_APP_CORE_API_URI}${COREAPI_WEB_ROOM_URL}`;

async function getDeviceInfo() {
    const userAgentString = window.navigator.userAgent;
    const parser = new UAParser(userAgentString);
    const result = parser.getResult();
    return result;
}

export async function LogInWithEmail(email, password) {
    try {
        const data = {
            email,
            password,
        };

        const respAxios = await axiosInstance.post(`${COREAPIURL}/login`, data);
        respAxios.data.status = respAxios.status;

        return respAxios.data;
    } catch (err) {
        return err;
    }
}

export async function RegisterGuestUser({ 
    email, 
    displayName, 
    vanityLinkId,
    optInMarketing, 
    clientName,
    guestOnlyMode 
}) {
    try {
        const data = {
            email,
            displayName,
            vanityLinkId,
            optInMarketing: optInMarketing || false,
            clientName,
        };

        const respAxios = await axiosInstance.post(`${COREAPIURL}/login/vanity-link`, data);
        respAxios.data.status = respAxios.status;
        if (respAxios.data.status === 200) {
            const data = await respAxios.data;

            if (clientName === 'discoverse') {
                const deviceInfo = await getDeviceInfo();
                await sendInfoToActionLog('USER_DEVICE_INFO', deviceInfo, respAxios.data.token, DISCOVERSE_OWNER_ID);
            }
            if (clientName === 'igt') {
                const deviceInfo = await getDeviceInfo();
                const eventJson = {
                    "guestLogin": guestOnlyMode,
                    "user": data.user,
                    clientName,
                };
                await sendEventsLog(data.token, clientName, data.userId, LOG_EVENTS_USER_LOGIN_DATA, 'login', eventJson, '', '');
                await sendEventsLog(data.token, clientName, data.userId, LOG_EVENTS_DEVICE_DATA, 'info', deviceInfo, '','');
            }
            return data;
        }
        return null;
    } catch (err) {
        console.log(err);
        return err;
    }
}

export async function RegisterUser(
    firstName,
    lastName,
    email,
    password,
    username,
    optInMarketing
    // confirmPassword
) {
    try {
        const config = {
            method: 'post',
            url: `${COREAPIURL}`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                email,
                password,
                firstName: firstName || 'guest',
                lastName: lastName || 'user',
                displayName: username,
                // confirmPassword: password,
                autoLogin: true,
                optInMarketing: optInMarketing || false,
            },
        };

        const response = await axiosInstance.request(config);

        if (response.status === 201) {
            const data = await response.data;
            const deviceInfo = await getDeviceInfo();
            await sendInfoToActionLog('USER_DEVICE_INFO', deviceInfo, data.token);
            const selectedAvatarId = getFromSessionStorage('selectedAvatar');
            if (selectedAvatarId !== '' || selectedAvatarId !== undefined || selectedAvatarId !== null) {
                const avatarInfo = {
                    avatarpresetid: selectedAvatarId,
                };
                await sendInfoToActionLog('avatar_preset_selection', avatarInfo, data.token);
            }
            return data;
        }

        return null;
    } catch (error) {
        console.error('Error during sign up:', error);
        return null;
    }
}

export async function UpdateUserProfileData(token, updatedFields) {
    try {
        const data = {
            ...updatedFields,
        };

        return await API_POST(COREAPIURL, data, token, 'PUT');
    } catch (error) {
        console.error('Error during user data update:', error);
        return false;
    }
}

export async function UpdateUserBirthday(token, birthday, userId) {
    try {
        const userData = await getUserProfileData(token, userId);
        if (userData.data.birthdayUpdateCount >= 3) {
            throw new Error('Exceeded the allowed number of updates for birthday');
        }
        const birthdayClean = new Date(birthday);
        const newBirthdayCount = parseInt(userData.data.birthdayUpdateCount, 10) + 1;
        const data = {
            userBirthday: birthdayClean,
            birthdayUpdateCount: newBirthdayCount,
        };
        return await API_POST(COREAPIURL, data, token, 'PUT');
    } catch (error) {
        console.log(error);
        console.error('Error during user birthday update:', error);
        return false;
    }
}

// eslint-disable-next-line consistent-return
export async function getUserProfileData(token, userId) {
    try {
        const response = await axiosInstance.get(`${COREAPIURL}/${userId}`);

        if (response.status === 200) {
            const userData = await response;
            return userData.data;
        }
    } catch (error) {
        console.error('Error during user profile data fetch:', error);
        return false;
    }
}

export async function UpdateUserEmail(token, newEmail) {
    try {
        const config = {
            method: 'PUT',
            url: `${COREAPIURL}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: `Bearer ${token}`,
            },
            data: { email: newEmail },
        };

        const response = await axiosInstance.request(config);

        if (response.status === 200) {
            const responseData = response.data;
            return {
                success: true,
                message: responseData,
            };
        }
        const errorData = await response.data;
        return {
            success: false,
            message: errorData,
        };
    } catch (error) {
        console.log(error);
        console.error('Error during user data update:', error);
        return false;
    }
}

export async function signUpUser(displayName, email) {
    try {
        const config = {
            method: 'POST',
            url: `${COREAPIURL}/login`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data: { displayName, email },
        };

        const response = await axiosInstance.request(config);

        if (response.status === 200) {
            const data = await response.data;
            // send deviceInfo to action log
            const deviceInfo = await getDeviceInfo();
            await sendInfoToActionLog('USER_DEVICE_INFO', deviceInfo, data.token);
            // send avatar selection to action log
            const selectedAvatarId = getFromSessionStorage('selectedAvatar');
            if (selectedAvatarId !== '' || selectedAvatarId !== undefined || selectedAvatarId !== null) {
                const avatarInfo = {
                    avatarpresetid: selectedAvatarId,
                };
                await sendInfoToActionLog('avatar_preset_selection', avatarInfo, data.token);
            }
            return data;
        }

        return null;
    } catch (error) {
        console.error('Error during singn up:', error);
        return null;
    }
}

export async function sendInfoToActionLog(actionType, data, userToken = '', ownerId = '') {
    try {
        console.log('sendInfoToActionLog freeform:', data);
        const response = await axiosInstance.post(
            `${COREAPIURL}/action-log`,
            {
                actionType,
                freeform: data || {},
                ownerId,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userToken}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error sending message:', error);
        return false;
    }
}

export async function getOwnerId(clientName) {
    try {
        const response = await axiosInstance.get(`${COREAPIWEBVANITYURL}/${clientName}`);
        if (response.status === 200) {
            const roomId = response.data.roomId;
            const ownerResp = await axiosInstance.get(`${COREAPIWEBROOMURL}/${roomId}`);
            if(ownerResp.status === 200) {
                const returnResp = {
                    ownerId: ownerResp.data.room.ownerId,
                    roomId
                }
                return returnResp;
            }
        }
    } catch (error) {
        console.error('Error during user profile data fetch:', error);
        return false;
    }
}

export async function sendEventsLog(userToken, clientName, userId, eventName, eventType, eventJson, clientSessionId, envUnity
    ) {
    try {

        const respOwnerData = await getOwnerId(clientName);

        const data = {
            "source": 'web',
            "buildId":"1", 
            "serverId": "1",
            "roomType": clientName,
            "eventTs": new Date().toISOString(),
            "roomId": respOwnerData.roomId,
            "ownerId": respOwnerData.ownerId,
            eventName,
            eventType,
            "eventJson": JSON.stringify(eventJson),
            "url": window.location.href,
            "userId": userId,
            clientSessionId,
            envUnity,
            envApi: process.env.REACT_APP_CORE_API_URI,
        };

        const response = await axiosInstance.post(
            `${COREAPIEVENTURL}/log`, data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userToken}`,
                },
            }
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        console.error('Error sending message:', error);
        return false;
    }
}

export async function LogInWithForgerock(code) {
    try {
        console.log('LogInWithForgerock code::', code);
        const response = await API_GET(`${COREAPIURL}/get-token/forgerock/${code}`, '');
        console.log('LogInWithForgerock resp:::', response);
        console.log(response.status);

        if (response.status === 400 || response.status === 500 || response.status === 401) {
            return response;
        }
        if (response.status === 200) {
            console.log('access token data: ', JSON.stringify(response));

            console.log('accessToken', response.data.access_token);
            const accessToken = response.data.access_token;
            console.log('accessToken set: ', accessToken);

            const userInfoResponse = await API_GET(`${COREAPIURL}/get-user/info/${accessToken}`, '');

            console.log('userInfoResponse>>>>', userInfoResponse);
            console.log(userInfoResponse.status, userInfoResponse.data);

            if (userInfoResponse.status === 400 || userInfoResponse.status === 500 || userInfoResponse.status === 401) {
                return userInfoResponse;
            }
            if (userInfoResponse.status === 200) {
                console.log('user info:', JSON.stringify(userInfoResponse));
                // if (respData.data.token) {
                //   const deviceInfo = await getDeviceInfo();
                //   await sendInfoToActionLog('USER_DEVICE_INFO', deviceInfo, respData.data.token);
                // }
                return userInfoResponse;
            }

            // const respData = {
            //       "data": {
            //         "sub": "cachu",
            //         "email": "cachu@hk.estee.com",
            //         "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI0ZjU0NGNlYS1mMjMwLTQxMjUtYjgzOC1lY2M1ODFlYWEwYzMiLCJpYXQiOjE2OTY1NDUyNTgsImV4cCI6MTY5NjU0ODg1OCwicm9sZXMiOltdLCJpc3MiOiJodHRwOi8vMTI3LjAuMC4xOjUwMDEifQ.bgl2FOhwA1JWVI9WLGrw43NROP7rIjmdgAFHISTO3zk",
            //         "userId": "4f544cea-f230-4125-b838-ecc581eaa0c3"
            //     }
            //   };
        }
    } catch (err) {
        console.log('LogInWithForgerock Error::: ', err);
        return err;
    }
}

export async function Logout() {
    try {
        await axiosInstance.post(`${COREAPIURL}/logout`);
        DeleteUser();
        DeleteMerchant();
    } catch (err) {
        return 500;
    }
}

export async function forgotPasswordEmail(passwordResetURL, email) {
    try {
        const data = {
            email,
            passwordResetURL,
        };
        const response = await axiosInstance.post(`${COREAPIURL}/password`, data);

        if (response.status === 200) {
            const responseData = response;
            return {
                success: true,
                message: responseData,
            };
        }
        const errorData = await response;
        return {
            success: false,
            message: errorData,
        };
    } catch (err) {
        console.log(err);
        return {
            success: false,
            error: err,
        };
    }
}

export async function ConfirmPasswordReset(token, newPassword) {
    try {
        const data = {
            password: newPassword,
        };
        const response = await API_POST(COREAPIURL, data, token, 'PUT');

        if (response.status === 200) {
            const responseData = response;
            return {
                success: true,
                message: responseData,
            };
        }
        const errorData = await response;
        return {
            success: false,
            message: errorData,
        };
    } catch (err) {
        console.log(err);
        return false;
    }
}

export const currentPasswordChecker = async (currentPassword, token, userId) => {
    try {
        return await API_POST(
            `${COREAPIURL}/check/current-password/`,
            { password: currentPassword, userId },
            token,
            'POST'
        );
    } catch (err) {
        return false;
    }
};

export const emailExistsChecker = async (email) => {
    try {
        // email is passed as part of the URL path, not in the body.
        const response = await API_GET(`${COREAPIURL}/check-email/${email}`);
        if (response && response.exists) {
            return true;
        }
        return false; // Email does not exist
    } catch (err) {
        console.error('Error checking email existence:', err);
        return false; // Assume false if there's an error during the check
    }
};

export const usernameExistsChecker = async (username) => {
    try {
        const response = await API_GET(`${COREAPIURL}/check-username/${username}`);
        if (response && response.exists) {
            return true; // Username exists
        }
        return false; // Username does not exist
    } catch (err) {
        console.error('Error checking username existence:', err);
        return false;
    }
};

export const createAvatar = async (userId, avatarJsonData, token) => {
    try {
        const parsedData = JSON.stringify(avatarJsonData);
        return await API_POST(
            `${process.env.REACT_APP_CORE_API_URI}/web/avatar`,
            { userId, avatarJson: parsedData },
            token,
            'POST'
        );
    } catch (error) {
        console.error('Error creating avatar:', error);
        throw error;
    }
};

export const checkAvatarExistsByUserId = async (userId, token) => {
    try {
        const response = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/avatar/${userId}`, token);

        if (response.ok) {
            const data = await response.json();
            // Check if a record exists for the given userId
            return data; // Convert to boolean
        }
        // Handle error response
        console.error('Failed to check avatar existence:', response.statusText);
        return false;
    } catch (error) {
        console.error('Error checking avatar existence:', error);
        throw error;
    }
};
