import { authenticateAutoLoginLink } from '../../../api/VanityLinkController';

export const AuthenticateAutoLogin = async (id, access, vanity) => {
    try {
        const authenticatedLink = await authenticateAutoLoginLink(id, access, vanity);

        if (!authenticatedLink) {
            return null;
        }

        return { ...authenticatedLink, vanity };
    } catch (error) {
        console.error('Error fetching the vanity link:', error);
        return null;
    }
};
