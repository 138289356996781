import { useElements, useStripe, CardExpiryElement, CardNumberElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useState } from 'react';
import styled from 'styled-components';
import { getWindowSize } from '../../../../../utils/generalUtils';
import BillingDetailsFields from './BillingDetailsField';
import Button from '../../../../../components/common/button/Button';
import Typography from '../../../../../components/common/text/Typography';
import { setColor } from '../../../../../assets/styles/styles';

export default function CheckoutForm({ clientSecret }) {
    const stripe = useStripe();
    const elements = useElements();
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [checkoutError, setCheckoutError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isProcessing, setIsProcessing] = useState(false);

    const handleCardDetailsChange = (ev) => {
        if (ev.error) {
            setCheckoutError(ev.error.message);
        } else {
            setCheckoutError();
        }
    };

    const handleSubmit = async (ev) => {
        setIsProcessing(true)
        ev.preventDefault();

        console.log('EV', ev);

        const billingDetails = {
            name: ev.target.name.value,
            email: ev.target.email.value,
            address: {
                city: ev.target.city.value,
                line1: ev.target.address.value,
                state: ev.target.state.value,
                postal_code: ev.target.zip.value,
                country: ev.target.country.value,
            },
            phone: ev.target.phone.value,
        };

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);

        const result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: billingDetails,
            },
        });

        if (result.setupIntent.status === 'succeeded') {
            if (window.location.search.includes('source=web_unity')) {
                window.close();
            } else {
                window.location.reload();
            }
        }

        if (result.error) {
            setCheckoutError(result.error.message);
            setIsProcessing(false)
        }

        setIsLoading(false);
    };

    const stripeIframeStyles = {
        base: {
            fontSize: '18px',
            iconColor: 'red',
            opacity: '10%',
            color: `${setColor.iconColor}`,
            fontFamily: 'Barlow, sans-serif',
            fontStyle: 'italic',
            '::placeholder': {
                padding: '20px',
                color: `rgba(157, 160, 167, 0.5)`,
                opacity: '10%',
                fontSize: windowSize.innerWidth > 769 ? '14px' : '10px',
            },
        },
        invalid: {
            iconColor: 'red',
            color: 'red',
        },
        complete: {
            iconColor: 'green',
        },
    };

    const cardElementOpts = {
        iconStyle: 'solid',
        style: stripeIframeStyles,
        hidePostalCode: true,
    };

    return (
        <FormContainer>
            <form id="card-form" onSubmit={(ev) => handleSubmit(ev)}>
                <Typography variant="h2PurpleHeading" margin={'0'}>
                    New Payment Method
                </Typography>
                <Subtitle>Credit Card Information</Subtitle>
                <InputLabel>Credit Card Number</InputLabel>
                <CardElementContainer>
                    <CardNumberElement options={cardElementOpts} onChange={(ev) => handleCardDetailsChange(ev)} />
                </CardElementContainer>
                <InputLabel>Expiry Date</InputLabel>
                <CardElementContainer>
                    <CardExpiryElement options={cardElementOpts} onChange={(ev) => handleCardDetailsChange(ev)} />
                </CardElementContainer>
                <InputLabel>CVC</InputLabel>
                <CardElementContainer>
                    <CardCvcElement options={cardElementOpts} onChange={(ev) => handleCardDetailsChange(ev)} />
                </CardElementContainer>

                <BillingDetailsFields />
                {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}

                <ButtonHolder>
                    <Button
                        buttonWidth="100%"
                        label={'Add Card'}
                        variant="PinkButton"
                        type="submit"
                        disabled={isProcessing || !stripe}
                    />
                </ButtonHolder>
            </form>
        </FormContainer>
    );
}

const InputLabel = styled.div`
    text-align: left;
    color: #ced1d9;
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 5px;

    @media (max-width: 425px) {
        font-size: 14px;
        margin-top: 5px;
    }

    @media (max-height: 742px) {
        margin-top: 5px;
    }
`;

const FormContainer = styled.div`
    max-width: 500px;
    width: 100%;
    margin: auto;
`;

const CheckoutError = styled.h2`
    color: ${setColor.textPrimary};
    text-align: center;
`;

const ButtonHolder = styled.div`
    width: 100%;
    background-color: green;
`;

const Subtitle = styled.p`
    font-family: 'Barlow', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: ${setColor.circularProgress};
    text-align: start;
    font-family: Barlow;
    font-size: 22px;
    padding-top: 1rem;
    padding-bottom: 1rem;
`;

const Label = styled.label`
    flex: 1;
    background: #1b1b23;
    outline: none;
    padding-left: 0.5rem;
    margin: 0.5rem 0rem;
    font-size: 18px;
    font-family: 'Barlow', sans-serif;
    /* color: ${setColor.iconColor}; */
    color: red;
    width: 100%;
    height: 41px;
`;

const CardElementContainer = styled.div`
    flex: 1;
    background: #1b1b23;
    border: 2px solid #504c5c;
    border-radius: 5px;
    outline: none;
    /* padding: 0.5rem 1rem; */
    padding-left: 0px;
    margin: 0.5rem 0rem;
    font-size: 16px;
    font-style: italic;
    font-family: 'Barlow', sans-serif;
    color: ${setColor.iconColor};
    width: 100%;
    height: 41px;

    ::placeholder {
        color: ${setColor.iconColor};
        opacity: 50%;
        @media (max-width: 1440px) {
            font-size: 10px;
        }
    }

    & .StripeElement {
        width: 100%;
        padding: 8px 10px;
        font-size: 12px;
    }
`;
