import React from 'react';
import styled from 'styled-components';
import IntraverseLogoFull from './IntraverseHomeImages/IntraverseLogoFull.png';
import TZLogoFull from './IntraverseHomeImages/TZLogoFull.png';
import Facebook from './IntraverseHomeImages/FB.png';
import Insta from './IntraverseHomeImages/Insta.png';
import Dicscord from './IntraverseHomeImages/Discord.png';
import Twitter from './IntraverseHomeImages/Twitter.png';
import TikTok from './IntraverseHomeImages/Tiktok.png';
import { setColor } from '../../assets/styles/styles';

const LandingFooter = () => (
    <FooterContainer>
        <FooterSocialButtons>
            <a
                style={{ textDecoration: 'none' }}
                href="https://www.facebook.com/terrazerotech/"
                target="_blank"
                rel="noreferrer"
            >
                <SocialLogo src={Facebook} alt="Facebook" />
            </a>
            <a
                style={{ textDecoration: 'none' }}
                href="https://www.instagram.com/terrazerotech/"
                target="_blank"
                rel="noreferrer"
            >
                <SocialLogo src={Insta} alt="Insta" />
            </a>
            <a
                style={{ textDecoration: 'none' }}
                href="https://www.linkedin.com/company/terrazerotech/mycompany/"
                target="_blank"
                rel="noreferrer"
            >
                <SocialLogo src={Dicscord} alt="Dicscord" />
            </a>
            <a
                style={{ textDecoration: 'none' }}
                href="https://twitter.com/terrazerotech/"
                target="_blank"
                rel="noreferrer"
            >
                <SocialLogo src={Twitter} alt="Twitter" />
            </a>
            <a
                style={{ textDecoration: 'none' }}
                href="https://www.linkedin.com/company/terrazerotech/mycompany/"
                target="_blank"
                rel="noreferrer"
            >
                <SocialLogo src={TikTok} alt="TikTok" />
            </a>
        </FooterSocialButtons>
        <FooterLogoButtons>
            <FooterLogo src={IntraverseLogoFull} alt="Intraverse Logo" />
            <FooterLogo src={TZLogoFull} alt="TZ Logo" />
        </FooterLogoButtons>
        <PrivacyLine>
            ©${new Date().getFullYear()} cc, Intraverse, and any associated logos are trademarks, service marks, and/or
            registered trademarks of TerraZero Technologies.
        </PrivacyLine>
        <FooterMenu>
            <FooterItems>
                <a
                    style={{ textDecoration: 'none' }}
                    href="https://terrazero.com/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                >
                    <FooterItem>privacy notice</FooterItem>
                </a>
                <a
                    style={{ textDecoration: 'none' }}
                    href="https://terrazero.com/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                >
                    <FooterItem>Terms of service</FooterItem>
                </a>
                <FooterItem>cookie preferences</FooterItem>
            </FooterItems>
        </FooterMenu>
    </FooterContainer>
);

export default LandingFooter;

const FooterContainer = styled.div`
    position: relative;
    bottom: 2.6%;
    left: 0;
    right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: ${setColor.inputBgColor};
    z-index: 1000;
    padding: 25px 40px;

    @media (max-width: 768px) {
        padding: 20px 5px;
        z-index: 0;
    }

    @media (max-height: 600px) {
        padding: 20px 5px;
        z-index: 0;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 20px 5px;
        z-index: 0;
    }
`;

const FooterLogo = styled.img`
    max-width: 260px;
    margin: 0.5rem 3rem 2rem 1rem;
    @media (max-width: 768px) {
        margin: 1.5rem 0 2rem -1rem;
    }
`;

const SocialLogo = styled.img`
    height: 70px;
    margin: 4rem 1rem 2rem 1rem;
    cursor: pointer;

    @media (max-width: 768px) {
        margin: 1rem 0.3rem;
        height: 50px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        height: 60px;
        margin: 1rem;
        margin: 1rem 0.5rem;
    }
`;

const FooterMenu = styled.div`
    display: flex;
    align-items: center;
`;
const FooterItems = styled.div`
    display: flex;
    align-items: center;
    justify-items: center;
    padding-bottom: 2rem;
    padding-top: 1rem;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        flex-direction: column;
    }
`;

const FooterItem = styled.div`
    color: ${setColor.inputText};
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    justify-items: center;
    margin-right: 50px;
    cursor: pointer;

    @media (max-width: 768px) {
        font-size: 0.8rem;
        margin: 10px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 1rem;
        margin: 10px;
    }
`;

const FooterSocialButtons = styled.div`
    display: flex;
    align-items: center;
    margin-left: -20px;

    @media (max-width: 1024px) {
        margin-left: 0px;
    }
`;
const FooterLogoButtons = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        flex-direction: column;
    }
`;

const PrivacyLine = styled.p`
    color: ${setColor.circularProgress};
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
        font-size: 14px;
        margin: 0rem 2rem;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 16px;
        margin: 0rem 2rem;
        width: 80%;
    }
`;
