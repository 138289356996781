import UAParser from 'ua-parser-js';
import { DeleteUser } from '../redux/actions/UserActions';
import { DeleteMerchant } from '../redux/actions/MerchantActions';
import { COREAPI_WEB_USERS_URL } from '../constants';
import axiosInstance, { API_POST, API_GET } from '../config/axiosConfig';

const COREAPIURL = `${process.env.REACT_APP_CORE_API_URI}/web`;

export const getTablesSchema = async (token) => {
      try {
        const response = await axiosInstance.get(`${COREAPIURL}/admin/tables_schema`, {
          headers: {
            'Content-Type': 'application/json',
            autorization: `Bearer ${token}`,
        }
        });
        return response; // Username does not exist
    } catch (err) {
        console.error('Error in request:', err);
        return err;
    }
};

export const getTableInfo = async (token, tableName) => {
  try {
    const response = await axiosInstance.get(`${COREAPIURL}/admin/table/${tableName}`, {
      headers: {
        'Content-Type': 'application/json',
        autorization: `Bearer ${token}`,
    }
    });
    return response; // Username does not exist
} catch (err) {
    console.error('Error in request:', err);
    return err;
}
};

export const getRecordInfo = async (token, tableName, id, primaryKey) => {
  try {
    const response = await axiosInstance.get(`${COREAPIURL}/admin/record/table/${tableName}/primary_key/${primaryKey}/id/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        autorization: `Bearer ${token}`,
    }
    });
    return response; // Username does not exist
} catch (err) {
    console.error('Error in request:', err);
    return err;
}
};

export const updateRecordDynamically = async (token, tableName, id, primaryKey, data) => {
  console.log("DATA HERR", data)
  try {
    const response = await axiosInstance.put(`${COREAPIURL}/admin/record/table/${tableName}/primary_key/${primaryKey}/id/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        autorization: `Bearer ${token}`,
    },
    });
    return response; // Username does not exist
} catch (err) {
    console.error('Error in request:', err);
    return err.response;
  }
};


