import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { isAdminRole } from '../../../redux/slices/users';
import IntraverseMiniLogoIcon from '../user/profile/images/ColoredIntraverseLogo.png';
import { default as Button } from '../../../components/common/button/Button';
import { getWindowSize } from '../../../utils/generalUtils';
import { setColor } from '../../../assets/styles/styles';
import { ReactComponent as UserLogo } from '../user/wallet/images/user_mobile_nav.svg';
import { ReactComponent as CardLogo } from '../user/wallet/images/card_mobile_nav.svg';
import { default as Dropdown } from '../../../components/common/dropdown/Dropdown';

// Future Hambuger Menu Functionality
// import MenuIcon from '../../intraverseLandingPage/IntraverseHomeImages/HamburgerIconSVG.svg';
// import IntraverseLogoFull from '../../intraverseLandingPage/IntraverseHomeImages/IntraverseLogoFull.png';
// import CloseMenu from '../../intraverseLandingPage/IntraverseHomeImages/CloseMenu.png';
// import DiamondBackground from '../../../assets/images/backgrounds/Diamond_Checkered_Background.png';

const NavigationMenu = () => {
    const isAdmin = useSelector(isAdminRole);

    const [isOpen, setIsOpen] = useState(false);
    const [activeMobileTab, setActiveMobileTab] = useState('Account');
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const navigate = useNavigate();

    const routeChange = (path) => {
        navigate(path);
    };

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    // Future Hambuger Menu Functionality
    // const toggleMenu = () => {
    //   setIsOpen(!isOpen);
    // };

    return (
        <>
            {windowSize.innerWidth > 769 ? (
                <>
                    {/* Future Hambuger Menu Functionality */}
                    {/* {windowSize.innerWidth < 769 && (
        <PortalMobileHeader>
          {!isOpen && (
            <PortalHeaderMobileInnerLogo
              onClick={() => routeChange('/')}
              src={IntraverseMiniLogoIcon}
              alt="Intraverse Logo"
            />
          )}
          {isOpen ? (
            <CloseButton onClick={toggleMenu} src={CloseMenu} alt="Close Menu" />
          ) : (
            <MenuIconContainer onClick={toggleMenu} src={MenuIcon} alt="Menu Icon" />
          )}
        </PortalMobileHeader>
      )}
      {isOpen && (
        <PortalMobileMenu>
          {' '}
          aspectRatio={windowSize.aspectRatio}
          <PortalMobileLogo src={IntraverseLogoFull} alt="Intraverse Full Logo" />
          <PortalMobileMenuItems>
            <PortalMobileMenuItem aspectRatio={windowSize.aspectRatio} onClick={() => routeChange('/console/profile')}>
              Profile
            </PortalMobileMenuItem>
            <PortalMobileMenuItem aspectRatio={windowSize.aspectRatio} onClick={() => routeChange('/console/wallet')}>
              Wallet
            </PortalMobileMenuItem>
            <PortalMobileMenuItem aspectRatio={windowSize.aspectRatio} onClick={() => routeChange('/console/orders')}>
              Orders
            </PortalMobileMenuItem>
          </PortalMobileMenuItems>
          <PortalMobileMenuButtons>
            <MobilePlayButton>Play Now</MobilePlayButton>
          </PortalMobileMenuButtons>
        </PortalMobileMenu>
      )} */}
                    <DropdownContainer>
                        <LogoAndDropDownContainer>
                            <PortalHeaderLogo
                                onClick={() => routeChange('/')}
                                src={IntraverseMiniLogoIcon}
                                alt="Intraverse Logo"
                            />
                            <Dropdowns>
                                <Dropdown
                                    title="Account"
                                    items={[
                                        {
                                            id: 0,
                                            value: 'Profile',
                                            route: '/console/profile',
                                        },
                                        {
                                            id: 1,
                                            value: 'Integrations',
                                            route: '/console/integrations',
                                        },
                                    ]}
                                />
                                <Dropdown
                                    title="Billing"
                                    items={[
                                        {
                                            id: 2,
                                            value: 'Wallet',
                                            route: '/console/wallet',
                                        },
                                        {
                                            id: 3,
                                            value: 'Orders',
                                            route: '/console/orders',
                                        },
                                    ]}
                                />
                                {isAdmin && (
                                    <Dropdown
                                        title="Admin"
                                        items={[
                                            {
                                                id: 4,
                                                value: 'Users',
                                                route: '/console/users',
                                            },
                                            {
                                                id: 5,
                                                value: 'Rooms',
                                                route: '/console/rooms',
                                            },
                                            {
                                                id: 5,
                                                value: 'Vanity Links',
                                                route: '/console/vanityLinks',
                                            },
                                            {
                                                id: 6,
                                                value: 'Tables',
                                                route: '/console/tables',
                                            },
                                        ]}
                                    />
                                )}
                            </Dropdowns>
                        </LogoAndDropDownContainer>
                        <PortalHeaderMenuButtons>
                            <Button onClick={() => routeChange('/')} label="Play Now" variant="DefaultButton" />
                        </PortalHeaderMenuButtons>
                    </DropdownContainer>
                </>
            ) : (
                <>
                    <MobileNavigation>
                        <MobileTopNav>
                            <PortalHeaderMenu isOpen={isOpen}>
                                <PortalHeaderLogo
                                    onClick={() => routeChange('/')}
                                    src={IntraverseMiniLogoIcon}
                                    alt="Intraverse Logo"
                                />
                            </PortalHeaderMenu>
                            <PortalHeaderMenuButtons>
                                <Button
                                    exact
                                    onClick={() => routeChange('/')}
                                    label="Play Now"
                                    variant="DefaultButton"
                                    margin={'0'}
                                />
                            </PortalHeaderMenuButtons>
                        </MobileTopNav>
                        <MobileBottomNav>
                            <PortalHeaderMenuButtons>
                                {activeMobileTab === 'Account' ? (
                                    <>
                                        <Button
                                            to="/console/profile"
                                            label="Profile"
                                            variant="NavigationButton"
                                            margin={'0 30px 0 0'}
                                        />
                                        <Button to="/console/wallet" label="Integrations" variant="NavigationButton" />
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            to="/console/wallet"
                                            exact
                                            label="Wallet"
                                            variant="NavigationButton"
                                            isActivated
                                            margin={'0 30px 0 0'}
                                        />
                                        <Button to="/console/orders" label="Orders" variant="NavigationButton" />
                                    </>
                                )}
                            </PortalHeaderMenuButtons>
                        </MobileBottomNav>
                    </MobileNavigation>

                    <MobileFooterNav>
                        <Tab
                            to="/console/profile"
                            isactive={activeMobileTab === 'Account'}
                            onClick={() => setActiveMobileTab('Account')}
                        >
                            <NavUserImage isactive={activeMobileTab === 'Account'} />
                            Account
                        </Tab>
                        <Tab
                            to="/console/wallet"
                            isactive={activeMobileTab === 'Billing'}
                            onClick={() => setActiveMobileTab('Billing')}
                        >
                            <NavCardImage isactive={activeMobileTab === 'Billing'} />
                            Billing
                        </Tab>
                    </MobileFooterNav>
                </>
            )}
        </>
    );
};

export default NavigationMenu;

const DropdownContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    min-height: 78px;
    background-color: ${setColor.black};
    padding: 0px 40px;
`;

const LogoAndDropDownContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Dropdowns = styled.div`
    display: flex;
    height: 50px;
    align-self: flex-end;
`;

const NavUserImage = styled(UserLogo)`
    width: 22px;
    height: 22px;
    margin-bottom: 5px;
    fill: ${(props) => (props.isactive ? setColor.iconColor3 : setColor.iconColor)};
`;

const NavCardImage = styled(CardLogo)`
    width: 22px;
    height: 22px;
    margin-bottom: 5px;
    fill: ${(props) => (props.isactive ? setColor.iconColor3 : setColor.iconColor)};
`;

const Tab = styled(NavLink)`
    text-decoration: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    color: ${setColor.iconColor};
    font-family: Barlow;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    ${(props) => {
        if (props.isactive) {
            return `
        color: ${setColor.iconColor3};
      `;
        }
    }}
`;

const MobileFooterNav = styled.div`
    z-index: 1000;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
    height: 60px;
    background: ${setColor.mobileFooterBackground};
`;

const Navigation = styled.div`
    position: sticky;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${setColor.black};
    z-index: 1000;
    padding: 0px 40px;

    height: 78px;

    @media (max-width: 768px) {
        padding: 12px 20px;
    }
`;

const MobileNavigation = styled.div`
    position: sticky;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
`;

const MobileTopNav = styled.div`
    position: sticky;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${setColor.black};
    z-index: 1000;
    padding: 0px 40px;

    @media (max-width: 768px) {
        padding: 12px 20px;
    }
`;

const MobileBottomNav = styled.div`
    position: sticky;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${setColor.innerProfileContainer};
    z-index: 1000;
    padding: 0px 40px;

    @media (max-width: 768px) {
        padding: 12px 20px;
    }
`;

const PortalHeaderLogo = styled.img`
    cursor: pointer;
    width: 40px;
    height: 27px;
    margin-right: 3rem;
    margin-left: 1rem;

    @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 80px;
        margin-right: 2rem;
        margin-left: 0rem;
    }

    @media (max-width: 768px) {
        margin: 0;
    }
`;
const PortalHeaderMenu = styled.div`
    display: flex;
    align-items: center;
`;

const PortalHeaderMenuItems = styled.div`
    display: flex;
    align-items: center;
    justify-items: center;
`;

const PortalHeaderMenuItem = styled(NavLink)`
    text-decoration: none;
    color: ${setColor.inputText};
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    justify-items: center;
    margin-right: 35px;
    cursor: pointer;
    padding-bottom: 18px;
    margin-bottom: -34px;

    &.active {
        /* Style for active NavLink */
        border-bottom: 6px solid #beaeff;
        color: #beaeff;
    }

    &:hover {
        color: #beaeff;
    }

    @media (max-width: 768px) {
        font-size: 1rem;
        letter-spacing: 1px;
        margin-right: 25px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 1rem;
        letter-spacing: 1px;
        margin-right: 25px;
    }
`;

const PortalHeaderMenuButtons = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 10px;

    @media (max-width: 768px) {
        margin: 0px;
    }
`;

// Future Hambuger Menu Functionality

// const MenuIconContainer = styled.img`
//   width: 50px;
//   height: 50px;
//   z-index: 1000;
//   position: fixed;
//   top: 1rem;
//   right: 1.5rem;
//   display: flex;
//   @media (max-width: 768px) {
//     height: 35px;
//     width: 35px;
//     top: 0.6rem;
//     right: 0.5rem;
//   }
// `;
// const PortalHeaderMobileInnerLogo = styled.img`
//   width: 50px;
//   height: 50px;
//   z-index: 1000;

//   position: fixed;
//   top: 2rem;
//   left: 1.5rem;
//   display: flex;

//   @media (max-width: 768px) {
//     height: 30px;
//     width: 40px;
//     top: 0.6rem;
//     left: 0.5rem;
//   }

//   /* @media (min-width: 768px) and (max-width: 1024px) {
//     height: 45px;
//     width: 60px;
//   } */
// `;

// const PortalMobileMenu = styled.div`
//   display: flex;
//   position: absolute;
//   top: -1.1rem;
//   left: 1.5%;
//   align-items: center;
//   flex-direction: column;
//   width: 97vw;
//   height: fit-content;
//   padding-bottom: 1.5rem;
//   background: rgba(27, 27, 35, 1);
//   background-image: url(${DiamondBackground});
//   background-size: 350px auto;
// `;

// const PortalMobileHeader = styled.div`
//   display: flex;
//   align-items: center;
//   overflow-x: hidden;
// `;
// const CloseButton = styled.img`
//   width: 60px;
//   height: 60px;
//   z-index: 1000;

//   position: fixed;
//   top: 3.7%;
//   right: 2rem;
//   display: flex;

//   @media (max-width: 600px) {
//     width: 40px;
//     height: 40px;
//   }

//   @media (max-height: 600px) {
//     width: 40px;
//     height: 40px;
//   }
// `;
// const PortalMobileLogo = styled.img`
//   position: relative;
//   top: -4.5rem;
//   left: -7rem;
//   width: 50vw;
//   margin-top: 6rem;

//   @media (max-width: 600px) {
//     top: -3.7rem;
//     left: -3rem;
//     width: 50vw;
//   }

//   @media (max-height: 768px) and (min-width: 600px) {
//     width: 30%;
//     left: -14rem;
//   }
// `;

// const PortalMobileMenuItems = styled.div`
//   display: flex;
//   align-items: flex-start;
//   justify-items: flex-start;
//   flex-direction: column;
//   width: 80%;

//   @media (max-height: 768px) {
//     margin-top: -3rem;
//   }
// `;
// const PortalMobileMenuItem = styled.div`
//   color: #fbfcff;
//   text-align: left;
//   font-family: 'Barlow', sans-serif;
//   font-size: 20px;
//   font-weight: 700;
//   letter-spacing: 2px;
//   text-transform: uppercase;
//   cursor: pointer;
//   margin-bottom: ${(props) => (props.aspectRatio === '16:9' ? '2.7rem' : '1rem')};
// `;

// const PortalMobileMenuButtons = styled.div`
//   display: flex;
//   align-items: flex-start;
//   flex-direction: column;
//   width: 80%;
// `;

// const MobilePlayButton = styled.button`
//   border-radius: 5px;
//   background: linear-gradient(45deg, #f0153f 5.13%, #dd42ce 100.27%);
//   padding: 15px 35px;
//   border: none;
//   cursor: pointer;
//   width: 100%;
//   margin-top: 1rem;

//   color: #fbfcff;
//   text-align: center;
//   font-family: 'Barlow', sans-serif;
//   font-size: 20px;
//   font-weight: 700;
//   letter-spacing: 2px;
//   text-transform: uppercase;
// `;
