// StripeContainer.js
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';

const StripeContainer = ({ clientSecret }) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

    const options = {
        clientSecret,
    };

    return (
        <Elements stripe={stripePromise} options={options}>
            <CheckoutForm clientSecret={clientSecret} />
        </Elements>
    );
};

export default StripeContainer;
