import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import axiosInstance from '../../../../config/axiosConfig';
import { ReactComponent as DropdownIcon } from '../../../../assets/images/icons/Chevron.svg';
import OrderHistoryTable from '../orders/components/OrderHistoryTable';
import { setColor } from '../../../../assets/styles/styles';
import Title from '../../components/Title';
import Body from '../../layout/Body';

const Integrations = () => {
    const [isDropdownOpenStatus, setIsDropdownOpenStatus] = useState(false);
    const [isDropdownOpenTime, setIsDropdownOpenTime] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [totalNumber, setTotalNumber] = useState(0);
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const userId = useSelector((state) => state?.user?.userId);

    const applyFiltersStatus = (status) => {
        let filTableData = [...tableData];
        if (status !== 'allstatus') {
            filTableData = filTableData.filter((order) => order.status === status);
        }
        setFilteredTableData(filTableData);
        setTotalNumber(filTableData.length);
    };

    const applyFiltersTime = (time) => {
        let filTableData = [...tableData];

        if (time !== 'alltime') {
            const today = moment();
            const last30Days = moment().subtract(30, 'days');
            const yearToDate = moment().startOf('year');

            if (filTableData.length > 0) {
                filTableData = filTableData.filter((order) => {
                    const orderDate = moment(order.orderDate);
                    switch (time) {
                        case 'today':
                            return orderDate.isSame(today, 'day');
                        case 'last30days':
                            return orderDate.isAfter(last30Days);
                        case 'yeartodate':
                            return orderDate.isAfter(yearToDate);
                        default:
                            return true;
                    }
                });
            }
        }
        setFilteredTableData(filTableData);
        setTotalNumber(filTableData.length);
    };

    const toggleDropdownStatus = () => {
        setIsDropdownOpenStatus(!isDropdownOpenStatus);
    };
    const toggleDropdownTime = () => {
        setIsDropdownOpenTime(!isDropdownOpenTime);
    };

    const handleOptionClickStatus = (status) => {
        setIsDropdownOpenStatus(false);
        applyFiltersStatus(status);
    };

    const handleOptionClickTime = (time) => {
        setIsDropdownOpenTime(false);
        applyFiltersTime(time);
    };

    useEffect(() => {
        const fetchOrdersData = async () => {
            setIsLoading(true);
            try {
                const response = await axiosInstance.get(`${process.env.REACT_APP_CORE_API_URI}/web/orders/${userId}`);
                const ordersCardsData = [];

                if (response.data.data) {
                    for (let i = 0; i < response.data.data.length; i += 1) {
                        const itemsNames = response.data.data[i].items.map((item) => item.productName).join(', ');
                        const formattedOrderDate = new Date(response.data.data[i].orderDate).toLocaleDateString(
                            'en-US',
                            {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                            }
                        );
                        ordersCardsData.push({
                            ...response.data.data[i],
                            item: itemsNames,
                            orderDate: formattedOrderDate,
                        });
                    }
                    ordersCardsData.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
                    setTotalNumber(response.data.totalOrders);
                    setFilteredTableData(ordersCardsData);
                    setTableData(ordersCardsData);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };

        fetchOrdersData();
    }, []);

    return (
        <>
            <Title
                title={'Integrations'}
                description={'Elevate your Intraverse experience by integrating with your favorite apps.'}
            />
            <Body isLoading={isLoading}>
                <>
                    <DropdownContainer>
                        <DropdownButton onClick={toggleDropdownStatus}>
                            All Status
                            <StyledDropdownIcon isopen={isDropdownOpenStatus.toString()} />
                            {isDropdownOpenStatus && (
                                <DropdownContentStatus>
                                    <DropdownItem onClick={() => handleOptionClickStatus('allstatus')}>
                                        All Status
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleOptionClickStatus('succeeded')}>
                                        Succeeded
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleOptionClickStatus('failed')}>
                                        Failed
                                    </DropdownItem>
                                </DropdownContentStatus>
                            )}
                        </DropdownButton>
                        <DropdownButton onClick={toggleDropdownTime}>
                            All Time
                            <StyledDropdownIcon isopen={isDropdownOpenTime.toString()} />
                            {isDropdownOpenTime && (
                                <DropdownContentTime>
                                    <DropdownItem onClick={() => handleOptionClickTime('alltime')}>
                                        All Time
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleOptionClickTime('today')}>Today</DropdownItem>
                                    <DropdownItem onClick={() => handleOptionClickTime('last30days')}>
                                        Last 30 Days
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleOptionClickTime('yeartodate')}>
                                        Year To Date
                                    </DropdownItem>
                                </DropdownContentTime>
                            )}
                        </DropdownButton>
                        <TotalOrdersLabel> Total Orders: {totalNumber}</TotalOrdersLabel>
                    </DropdownContainer>
                    <OrderHistoryTable ordersData={filteredTableData} tableData={tableData} />
                </>
            </Body>
        </>
    );
};

export default Integrations;

const DropdownContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 768px) {
        justify-content: center;
    }
`;

const DropdownButton = styled.button`
    position: relative;
    background-color: TRANSPARENT;
    color: ${setColor.inputText};
    font-family: 'Barlow-Bold', Helvetica;
    font-size: 16px;
    font-weight: 700;
    border: none;
    margin-right: 20px;
    @media (max-width: 768px) {
        font-size: 13px;
    }
`;
const StyledDropdownIcon = styled(DropdownIcon)`
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    margin-left: 5px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isopen === 'true' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
const DropdownContentStatus = styled.div`
    position: absolute;
    right: 0;
    top: 2.2rem;
    width: fit-content;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 1);
    text-align: center;
    border: 4px solid rgba(36, 36, 47, 1);
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    cursor: pointer;
    color: ${setColor.inputText};
    &:hover {
        background-color: rgba(190, 174, 255, 1);
        color: rgba(0, 0, 0, 1);
    }
`;
const DropdownContentTime = styled.div`
    position: absolute;
    right: 0;
    top: 2.2rem;
    width: fit-content;
    border-radius: 10px;
    border: 4px solid rgba(36, 36, 47, 1);
    background-color: rgba(0, 0, 0, 1);
    text-align: center;
`;

const TotalOrdersLabel = styled.div`
    background-color: transparent;
    color: ${setColor.inputText};
    font-family: 'Barlow-Bold', Helvetica;
    font-size: 16px;
    font-weight: 700;
    @media (max-width: 768px) {
        font-size: 13px;
    }
`;
