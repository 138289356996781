import { fetchVanityLinkByRoomId } from '../api/VanityLinkController';
import '../features/vagon/VagonStream.css';

const uuid = require('uuid');

export const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};

export const isvalidPassword = (password) => {
    // eslint-disable-next-line no-useless-escape
    const passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/; // allows special chars
    return passwordReg.test(password);
};

export const isMobileDevice = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobile;
};

export const isTabletDevice = () => {
    const isTablet = /iPad|Android/i.test(navigator.userAgent);
    return isTablet;
};

export const nameValidation = (firstName, lastName) => {
    const nameRegex = /^[A-Za-z\s]+$/;
    return nameRegex.test(firstName + lastName);
};

export const passwordValidator = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&*()_+[\]{};':"\\|,.<>/?-]).{8,}$/;
    return passwordRegex.test(password);
    // fixed password regex to match backend
};

export const isMobileDeviceAndSafari = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    return isMobile && isSafari;
};

export const isIOSDevice = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    return isIOS;
};

/**
 * Validates a username based on the following criteria:
 * - Should be between 3 and 20 characters.
 * - Can contain letters (both uppercase and lowercase), numbers, underscores, and hyphens.
 * - Must start with a letter.
 * @param {string} username - The username to validate.
 * @returns {boolean} - True if the username is valid, false otherwise.
 */
export const validateUsername = (username) => {
    const usernameRegex = /^[a-zA-Z][a-zA-Z0-9_-]{2,19}$/;
    return usernameRegex.test(username);
};

export const secondsToHms = (d) => {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
    const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
    const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
    return hDisplay + mDisplay + sDisplay;
};

export const changeURLByRoomId = async (roomId) => {
    try {
        const vanityLink = await fetchVanityLinkByRoomId(roomId);

        if (vanityLink && vanityLink?.vanityLink) {
            // window.history.pushState({}, '', vanityLink?.vanityLink);
            return vanityLink?.vanityLink;
        }

        if (!vanityLink) {
            window.history.pushState({}, '', '/'); // Set URL to an empty string
        }

        return null;
    } catch (error) {
        console.error('Error fetching vanity link data:', error);
        return null;
    }
};

export const updateUrlWithVanity = (vanity) => {
    window.history.pushState({}, '', `/${vanity}`);
};

export const enterFullScreen = (elementRef) => {
    if (elementRef.current) {
        if (elementRef.current.requestFullscreen) {
            elementRef.current.requestFullscreen();
        } else if (elementRef.current.mozRequestFullScreen) {
            // Firefox
            elementRef.current.mozRequestFullScreen();
        } else if (elementRef.current.webkitRequestFullscreen) {
            // Chrome, Safari, and Opera
            elementRef.current.webkitRequestFullscreen();
        } else if (elementRef.current.msRequestFullscreen) {
            // Edge
            elementRef.current.msRequestFullscreen();
        } else if (elementRef.current.webkitEnterFullscreen) {
            // For iOS Safari
            elementRef.current.webkitEnterFullscreen();
        } else if (elementRef.current.webkitEnterFullScreen) {
            // For iOS Chrome (tentative)
            elementRef.current.webkitEnterFullScreen();
        }
    }
};

export const exitFullScreen = () => {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
        // Chrome, Safari, and Opera
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
        // Edge
        document.msExitFullscreen();
        // eslint-disable-next-line no-dupe-else-if
    } else if (document.webkitExitFullscreen) {
        // For iOS Safari
        document.webkitExitFullscreen();
    } else if (document.webkitExitFullScreen) {
        // For iOS Chrome (tentative)
        document.webkitExitFullScreen();
    }
};

export const ColorGenerator = {
    getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i += 1) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    },
};

export function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    const aspectRatio = innerWidth / innerHeight;
    return { innerWidth, innerHeight, aspectRatio };
}

export const generateUniqueEmail = (roomName) => {
    const uniqueId = uuid.v4();
    return `${uniqueId}-${roomName}@terrazero.com`;
};

export function capitalizeString(str) {
    return str
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatDateTimeWithTimeZone(dateTimeString) {
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short',
    };

    return new Date(dateTimeString).toLocaleDateString('en-US', options);
}
export function getDeviceOrientation() {
    return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
}

export function hexToRGBA(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function getFileType(url) {
    // Create a new URL object
    const parsedUrl = new URL(url);

    // Get the pathname from the URL object
    const { pathname } = parsedUrl;

    // Split the pathname by '/' and get the last part (the file name)
    const filename = pathname.split('/').pop();

    // Split the filename by '.' and get the last part (the file extension)
    const extension = filename.split('.').pop();

    // Return the file extension
    return extension;
}

export const minLengthRegex = /^.{8,}$/;
export const uppercaseRegex = /[A-Z]/;
export const numberRegex = /\d/;
export const specialCharacterRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/;
