import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import axiosInstance from '../../../../../config/axiosConfig';
import { setColor } from '../../../../../assets/styles/styles';
import { default as Button } from '../../../../../components/common/button/Button';
import Typography from '../../../../../components/common/text/Typography';

const DeleteModal = ({ id, closeModal }) => {
    const navigate = useNavigate();
    const [deleteText, setDeleteText] = useState('');
    const [deleteError, setDeleteError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleDeleteClick = async () => {
        if (deleteText.toLowerCase() === 'delete') {
            try {
                const response = await axiosInstance.delete(`${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}`);
                if (response.status === 200) {
                    localStorage.setItem('deleteBannerSuccess', `Room data successfully deleted: ${id}`);
                    localStorage.setItem('deleteBannerFlag', 'true');
                    navigate(`/console/rooms`);
                } else if (response.status === 404) {
                    setDeleteError(`Already deleted room or not found room ${id}`);
                } else {
                    setDeleteError(`Failed to delete room ${id}`);
                }
            } catch (error) {
                console.log('error', error);
                setDeleteError(
                    `Unauthorized: Insufficient privileges. This operation requires superadmin access. Please contact your administrator to request the necessary permissions.`
                );
            }
        } else {
            setDeleteError('Write DELETE to proceed.');
        }
        setDeleteText('');
    };

    useEffect(() => {
        if (deleteError) {
            const timer = setTimeout(() => {
                setDeleteError('');
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [deleteError]);

    return (
        <>
            <Typography variant="h2PurpleHeading" margin={`0 0 20px 0`}>
                Permanently Delete?
            </Typography>

            <Card>
                <Typography variant="pWhite" margin={'0'}>
                    This action is <YellowText>permanent </YellowText> and <YellowText> irreversible.</YellowText>{' '}
                    Please type DELETE below to proceed.
                </Typography>
                {deleteError && <MessageDesc>{deleteError}</MessageDesc>}
                <InputBox type="text" value={deleteText} onChange={(e) => setDeleteText(e.target.value)} />
                <Button
                    variant={'PinkButton'}
                    buttonWidth={'100%'}
                    disable={deleteText.toLowerCase() !== 'delete'}
                    label={'PERMANENTLY DELETE'}
                    margin={' 0  0 2rem 0'}
                    onClick={handleDeleteClick}
                />
                <Typography variant="GreyText" onClick={closeModal} margin={'0'}>
                    GO BACK
                </Typography>
            </Card>
        </>
    );
};

export default DeleteModal;

const Card = styled.div`
    height: auto;
    position: relative;
    padding: 10px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const YellowText = styled.span`
    color: ${setColor.yellowText};
    font-weight: 700;
`;

const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 50px 0;
    height: 50%;
    width: 100%;
    background-color: transparent;
`;

const MessageDesc = styled.div`
    font-size: 14px;
    color: ${setColor.errorColor};
    margin: 0rem 0rem 1rem 2rem;
`;
