import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Typography from '../../components/common/text/Typography';
import Spotify from '../../features/spotify/Spotify';
import SelectorIntraverseLogoIcon from '../intraverseLandingPage/IntraverseHomeImages/IntraverseLogoIcon2.png';
import { breakpoints } from '../../assets/styles/mediaBreakpoints';

const NavigationMenu = (props) => (
    <NavigationMenuContainer orientation={props.orientation}>
        <div>
            <Icon src={SelectorIntraverseLogoIcon} alt="Intraverse Logo" />
        </div>
        <NavigationTabsContainer>
            <div onClick={() => props.setSelectorScreenPage('play')}>
                <Typography
                    variant="h1"
                    style={{ color: 'white', textTransform: 'uppercase', lineHeight: ' 0.8', cursor: 'pointer' }}
                >
                    Play Now
                </Typography>
            </div>
            <MenuOption>
                <UnStyledLink to="/console/profile">
                    <Typography variant="h1" style={{ color: 'white', textTransform: 'uppercase', lineHeight: ' 0.8' }}>
                        My Console
                    </Typography>
                </UnStyledLink>
            </MenuOption>
            {/* <MenuOption>
                <Spotify setSpotifyNotPremiumAccount={props.setSpotifyNotPremiumAccount} />
            </MenuOption> */}
        </NavigationTabsContainer>
        <NavigationTabsContainer>
            <div>
                <Typography variant="p" style={{ color: 'var(--iconColor)', fontWeight: 'bold' }}>
                    VERSION {process.env.REACT_APP_VERSION}
                </Typography>
            </div>
            <div>
                <Typography
                    variant="p"
                    style={{
                        color: 'var(--iconColor)',
                        fontWeight: 'bold',
                        paddingTop: '5px',
                        textTransform: 'uppercase',
                    }}
                >
                    ©{new Date().getFullYear()} TerraZero Technologies. All rights reserved.
                </Typography>
            </div>
        </NavigationTabsContainer>
    </NavigationMenuContainer>
);

export default NavigationMenu;

const UnStyledLink = styled(Link)`
    text-decoration: none;
`;

const NavigationMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    z-index: 5;
    height: 100%;
    width: 100%;

    ${(props) => {
        if (props.orientation && props.orientation === 'portrait') {
            return `
        padding: 25px;
        transform: rotate(-90deg);
        height:100vw;
        width: 100vh;
      `;
        }
        return `
        transform: none;
        padding: none;
        height: 100%;
        width: 100%;
      `;
    }}
`;

const NavigationTabsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
`;

const MenuOption = styled.div`
    margin-top: 50px;

    @media (max-width: ${breakpoints.tablet}px), (max-height: ${breakpoints.mobile}px) {
        margin-top: 30px;
    }
`;

const Icon = styled.img`
    width: 80px;
    height: 80px;

    @media (max-width: ${breakpoints.tablet}px), (max-height: ${breakpoints.mobile}px) {
        width: 50px;
        height: 50px;
    }
`;
