import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import X from '../../../assets/images/buttonIcons/X.png';
import ErrorIcon from '../../../assets/images/buttonIcons/errorSymbol.png';
import MegaphoneIcon from '../../../assets/images/buttonIcons/megaphoneIcon.svg';
import SuccessIcon from '../../../assets/images/icons/SuccessBanner.svg';
import { setColor } from '../../../assets/styles/styles';

const Banner = (props) => {
    let icon = null;

    if (!icon) {
        switch (props.type) {
            case 'ERROR':
                icon = ErrorIcon;
                break;
            case 'MESSAGE':
                icon = MegaphoneIcon;
                break;
            case 'SUCCESS':
                icon = SuccessIcon;
                break;
            default:
                icon = null;
                break;
        }
    }

    const handleClose = () => {
        props.setOpenBanner('');
    };

    if (props.timer > 0) {
        setTimeout(() => {
            props.setOpenBanner('');
        }, props.timer);
    }

    return (
        <Container
            gameScreen={props.gameScreen}
            width={props.width}
            open={props.openBanner}
            type={props.type}
            topDisplay={props.topDisplay}
            left={props.left}
            top={props.top}
        >
            <ErrorContent>
                {icon && <Icon src={icon} alt="icon" />}
                <ErrorMessageContainer onClick={() => props.func()}>
                    <ErrorMessage>{props.message}</ErrorMessage>
                </ErrorMessageContainer>
                {props.showCloseButton && (
                    <div>
                        <button type="button" onClick={() => handleClose()} src={X}>
                            <CloseIcon src={X} alt="Close" />
                        </button>
                    </div>
                )}
            </ErrorContent>
        </Container>
    );
};

export default Banner;

Banner.defaultProps = {
    timer: 0,
    topDisplay: true,
    showCloseButton: true,
    width: '100%',
    gameScreen: null,
    func: () => {},
};

Banner.propTypes = {
    message: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['ERROR', 'SUCCESS', 'MESSAGE', '']).isRequired,
    timer: PropTypes.number,
    openBanner: PropTypes.any.isRequired,
    setOpenBanner: PropTypes.any.isRequired,
    topDisplay: PropTypes.bool,
    showCloseButton: PropTypes.bool,
    width: PropTypes.string,
    gameScreen: PropTypes.bool,
    func: PropTypes.func,
    top: PropTypes.string,
};

const Icon = styled.img`
    resizemode: contain;
    height: 35px;
    width: auto;

    @media (max-width: 960px) {
        height: 30px;
    }
`;

const CloseIcon = styled.img`
    padding-left: 10px;
    resizemode: contain;
    height: 30px;
    width: auto;

    @media (max-width: 960px) {
        height: 20px;
        padding-left: 0;
    }
`;

const ErrorMessageContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;

    @media (max-width: 960px) {
        justify-content: center;
    }
`;

const ErrorMessage = styled.p`
    cursor: pointer;
    font-size: 1.2rem;
    color: white;
    font-family: 'Barlow', sans-serif;
    font-style: normal;
    font-weight: 400;
    padding-left: 15px;

    @media (max-width: 960px) {
        font-size: 0.8rem;
        padding: 0 15px;
    }
`;

const ErrorContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button {
        background-color: transparent;
        border: none;
        color: white;
        cursor: pointer;
        font-size: 16px;
        margin-top: 10px;
    }
`;

const Container = styled.div`
    color: white;
    padding: 10px;
    display: ${(props) => (props.open ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    width: ${(props) => props.width};
    z-index: 1000;
    left: ${(props) => (props.left ? '0' : 'auto')};
    top: ${(props) => (props.top ? props.top : '0')};

    ${(props) => {
        if (props.topDisplay) return 'position: fixed;';
        return '';
    }}
    ${(props) => {
        switch (props.type) {
            case 'ERROR':
                return 'background-color: rgba(102, 16, 16, 0.5);';
            case 'MESSAGE':
                return 'background-color: rgba(13, 77, 118, 0.5);';
            case 'SUCCESS':
                return `background-color: ${setColor.successBox};`;
            default:
                return '';
        }
    }};

    ${(props) => {
        switch (props.type) {
            case 'ERROR':
                return 'border: 2px solid #ff8484;';
            case 'MESSAGE':
                return 'border: 2px solid #83CEFF;';
            default:
                return '';
        }
    }};

    @media (max-width: 960px) {
        text-align: center;
        padding: 5px;
    }

    ${(props) => {
        if (props.gameScreen) {
            return `@media (max-width: 415px) {
        transform: rotate(-90deg);
        left: -185px;
        top: initial;
      }`;
        }
        return '';
    }};
`;
