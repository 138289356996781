import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './VagonStream.css';
import { useSelector } from 'react-redux';
import { fetchSpotify } from '../../redux/slices/spotify';
import { changeURLByRoomId, exitFullScreen, enterFullScreen, isIOSDevice } from '../../utils/generalUtils';
import SpotifyInterface from '../spotify/components/SpotifyControls';
import * as constants from '../../constants';
import { resumePlayer } from '../spotify/api/SpotifyController';
import { fetchVanityLinkData } from '../../api/VanityLinkController';
import { startInterval, stopInterval, alterState, sendVagonMessage } from './helpers/utils';
import VanityFeatures from './components/VanityFeatures';
import { setToSessionStorage, getFromSessionStorage } from '../../hooks/useSessionStorage';

const VagonStreamIntraverse = (props) => {
    const spotifyToken = useSelector(fetchSpotify);
    const isIOS = isIOSDevice();

    const elementRef = useRef(null);
    const intervalIdRef = useRef(null);

    const [vanityLinkInfo, setVanityLinkInfo] = useState({});
    const [showCornerButtons, setShowCornerButtons] = useState(true);
    const [landscape, setLandscape] = useState(false);
    const [fullScreen, setFullScreen] = useState(JSON.parse(getFromSessionStorage('fullscreen') || 'false'));
    const [openBroadcastModal, setOpenBroadcastModal] = useState(false);
    const [playingMusic, setPlayingMusic] = useState(false);
    const [openSpotify, setOpenSpotify] = useState(false);

    // These features are WIP, need to be made dynamically configurable
    const [teddyNoteOpen, setTeddyNoteOpen] = useState(false);
    const [openTeddySelfiePopup, setOpenTeddySelfiePopup] = useState(false);
    const [openContestModalState, setOpenContestModalState] = useState(false);

    const setVanityLinkData = async (roomId) => {
        const vanityLink = await changeURLByRoomId(roomId);
        if (vanityLink) {
            const data = await fetchVanityLinkData(vanityLink);
            setVanityLinkInfo((value) => ({ ...value, ...data }));
        }
    };

    const removeVanityLinkData = (vagonPlayingMusic) => {
        if (vagonPlayingMusic) {
            // This is to disconnect the spotify player when the room is unloaded
            props.spotifyPlayer?.disconnect();
            alterState(setPlayingMusic);
        }
        setVanityLinkInfo({});
    };

    const messageActions = {
        [constants.OPEN_BROADCAST_MODAL]: () => {
            sendVagonMessage(constants.UNITY_OPEN_WEB_INTERFACE);
            alterState(setOpenBroadcastModal);
        },
        [constants.OPEN_TEDDY_SELFIE]: () => alterState(setOpenTeddySelfiePopup),
        [constants.OPEN_SPOTIFY]: () => alterState(setOpenSpotify),
        [constants.PDF_OPEN]: () => alterState(setShowCornerButtons),
        [constants.PDF_CLOSE]: () => alterState(setShowCornerButtons),
        [constants.OPEN_TEDDY_CONTEST]: () => {
            sendVagonMessage(constants.UNITY_OPEN_WEB_INTERFACE);
            alterState(setOpenContestModalState);
        },
        [constants.OPEN_TEDDY_NOTE]: () => {
            sendVagonMessage(constants.UNITY_OPEN_WEB_INTERFACE);
            alterState(setTeddyNoteOpen);
        },
        [constants.FULL_SCREEN]: () => {
            alterState(setFullScreen);
        },
        [constants.SETUPCARD]: () => {
            window.open('/wallet?source=web_unity');
        },
        [constants.PAUSE_BACKGROUND_MUSIC]: () => {
            const audio = document.getElementById('audio')
            audio.pause();
        },
        [constants.PLAY_BACKGROUND_MUSIC]: () => {
            const audio = document.getElementById('audio')
            audio.play();
        },
        [constants.ENTERING_NAV_MENU]: () => {
            const audio = document.getElementById('audio')
            audio.stop();
        }
    };

    // This function is called when the script is loaded, it is used to listen to the messages sent from the application
    // States can not be updated within the function, so we are using alterState function to update the state
    const loadedScript = () => {
        let vagonPlayingMusic = false;
        startInterval(intervalIdRef);

        window.Vagon.onConnected(() => {
            setTimeout(() => {
                const audio = document.getElementById('audio')
                audio.pause();
            }, 12000)
        });

        window.Vagon.onApplicationMessage((evt) => {
            const message = evt.message.toString();
            console.log(`EVENT MESSAGE: ${message}`);

            if (messageActions[message]) {
                messageActions[message]();
            } else if (message.includes(constants.ROOM_UNLOADED)) {
                removeVanityLinkData(vagonPlayingMusic);
                vagonPlayingMusic = false;
            } else if (message.includes(constants.NEW_ROOM_LOADING)) {
                const roomId = message.split(':');
                setVanityLinkData(roomId[1]);
                // Checking if the message is a spotify track and if the spotify player is not already playing music
            } else if (Object.prototype.hasOwnProperty.call(constants.spotifyTracks, message) && !vagonPlayingMusic) {
                const type = constants.ALBUM;
                resumePlayer(spotifyToken.token, constants.spotifyTracks[message], type).then(() => {
                    vagonPlayingMusic = true;
                    alterState(setPlayingMusic);
                });
            }
        });
    };

    const updateOrientation = () => {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

        const aspectRatio = width / height;
        const iframeElement = document.getElementById('vagonFrame');

        // second check to ensure aspect Ratio is below 1
        if (aspectRatio < 1) {
            iframeElement.classList.add('force-landscape');
            iframeElement.style.removeProperty('width');
            iframeElement.style.removeProperty('height');
            setLandscape(true);
        } else {
            iframeElement.classList.remove('force-landscape');
            iframeElement.style.width = '100vw';
            iframeElement.style.height = '100vh';
            setLandscape(false);
        }
        if (isIOS) iframeElement.style.padding = '10px';
    };

    useEffect(() => {
        if (fullScreen === true || fullScreen === 'true') {
            enterFullScreen(elementRef);
        } else if (
            (document.fullscreenElement ||
                document.webkitFullscreenElement ||
                document.mozFullScreenElement ||
                document.msFullScreenElement) &&
            !fullScreen
        ) {
            setToSessionStorage('fullscreen', false);
            exitFullScreen();
        }
        updateOrientation();
    }, [fullScreen]);

    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', 'https://app.vagon.io/vagonsdk.js');
        script.addEventListener('load', () => {
            loadedScript();
        });
        head.appendChild(script);

        window.addEventListener('resize', updateOrientation);
        return () => window.removeEventListener('resize', updateOrientation);
    }, []);

    useEffect(() => {
        if (openBroadcastModal || teddyNoteOpen || openContestModalState || openTeddySelfiePopup) {
            stopInterval(intervalIdRef);
        } else {
            startInterval(intervalIdRef);
        }
    }, [openBroadcastModal, teddyNoteOpen, openContestModalState, openTeddySelfiePopup]);

    return (
        <div
            ref={elementRef}
            className="iframe-container"
            style={{ width: '100vw', height: '100vh', background: 'black', zIndex: 100 }}
        >
            <VanityFeatures
                fullScreen={fullScreen}
                setFullScreen={setFullScreen}
                vanityLinkInfo={vanityLinkInfo}
                openBroadcastModal={openBroadcastModal}
                setOpenBroadcastModal={setOpenBroadcastModal}
                showCornerButtons={showCornerButtons}
                landscape={landscape}
            />
            <SpotifyInterface
                player={props?.spotifyPlayer}
                setFullScreen={setFullScreen}
                landscape={landscape}
                teddyNoteOpen={teddyNoteOpen}
                setTeddyNoteOpen={setTeddyNoteOpen}
                openTeddySelfiePopup={openTeddySelfiePopup}
                setOpenTeddySelfiePopup={setOpenTeddySelfiePopup}
                playingMusic={playingMusic}
                openContestModalState={openContestModalState}
                setOpenContestModalState={setOpenContestModalState}
                openSpotify={openSpotify}
                setOpenSpotify={setOpenSpotify}
            />
            <iframe
                id="vagonFrame"
                title="Vagon Stream Content"
                allow="microphone *; clipboard-read *; clipboard-write *; encrypted-media *;"
                style={{ width: '100vw', height: '100vh', border: 0, zIndex: 100 }}
                src={props.streamUrl}
                sandbox="allow-pointer-lock allow-scripts allow-forms allow-same-origin allow-popups"
            />
        </div>
    );
};

VagonStreamIntraverse.propTypes = {
    streamUrl: PropTypes.string.isRequired,
    spotifyPlayer: PropTypes.object,
};

export default VagonStreamIntraverse;
