import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import BackgroundContainer from '../../components/common/Backgrounds/BackgroundContainer';
import NavigationMenu from './components/NavigationMenu';
import MainContainer from './layout/MainContainer';

const Console = () => (
    <BackgroundContainer
        height={'100vh'}
        width={'100vw'}
        backgroundColor="rgba(32, 23, 41, 1)"
        variant="DiamondBackground"
        padding={'0px'}
    >
        <ConsoleContainer>
            <NavigationMenu />
            <MainContainer>
                <Outlet />
            </MainContainer>
        </ConsoleContainer>
    </BackgroundContainer>
);
export default Console;

const ConsoleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
`;
