import React from 'react';
import styled from 'styled-components';

const Description = ({ text }) => {
    return <PageDescription>{text}</PageDescription>;
};
export default Description;

const PageDescription = styled.div`
    margin-top: 10px;
    font-size: 1.5em;
    color: white;
    font-family: 'Barlow', sans-serif;
    letter-spacing: 0.05em;
    font-weight: 50;

    @media (max-width: 960px) {
        margin-top: 10px;
        font-size: 1em;
        text-align: center;
    }
`;
