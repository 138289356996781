import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Title from '../../components/Title';
import { getRecordInfo,updateRecordDynamically } from '../../../../api/AdminController';
import Body from '../../layout/Body';
import Typography from '../../../../components/common/text/Typography';
import Button from '../../../../components/common/button/Button';

const RecordDetails = () => {
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState([]);
    const [record, setRecord] = useState(null);
    const [error, setError] = useState(null);
    
    const [searchParams, setSearchParams] = useSearchParams();
    const token = useSelector((state) => state?.user?.token);

    const id = searchParams.get('id');
    const tableName = searchParams.get('table');
    const primaryKey = searchParams.get('primary_key');

    const fetchRecordData = async () => {
        const response = await getRecordInfo(token, tableName, id, primaryKey);
        if (response.status === 200) {
            setRecord(response.data.record);
            setColumns(response.data.columns);
            setLoading(false);
            return;
        }
        setLoading(false);
        setError(response.error);
    };

    const updateRecord = async (e) => {
        console.log("CLICKING", e);
        const column = e.target.name;
        console.log("column", column);
        console.log("value", record[column]);
        console.log(tableName, id, primaryKey)
        let value = record[column]
        if (value === null || value === "null" || value === "NULL" ) {
            value = "NULL"
        }
        let body = {column, value};
        if (typeof value === "object") {
            body = {column, "value" : JSON.stringify(value)};
        }
        
        const response = await updateRecordDynamically(token, tableName,id, primaryKey, body);

        if (response.status === 200) {
            window.alert("Success updating record")
        }

        if (response.status === 400) {
            window.alert(`Error updating record: ${response.response.data.message}`)
        }

    }


    useEffect(() => {
        setLoading(true);
        fetchRecordData();
    }, []);

    const handleChange = (e) => {
        e.preventDefault();
        const { value, name } = e.target;
        const copyRecord = { ...record, [name]: value };
        console.log(copyRecord);
        setRecord(copyRecord);
    };

    return (
        <>
            <Typography variant="ConsoleTitle">Record Details</Typography>
            <Link to={`/console/table/${tableName}`}>Go back to schema</Link>
            <Typography variant="ConsoleDescription">Table: {tableName}</Typography>
            <Typography variant="ConsoleDescription">
                {primaryKey}: {id}
            </Typography>
            <Body isLoading={loading}>
                <FlexContainer>
                    {columns.map((column, i) => {
                        let value = record[column.column_name];
                        if (typeof record[column.column_name] === 'object') {
                            value = JSON.stringify(value);
                        }
                        const columnSpecs = Object.entries(column);
                        const disabledColumns = [primaryKey, "created_at", "date_created", "created_at", "last_updated"]
                        const checkDisable = disabledColumns.includes(column.column_name)
                        return (
                            <RowGridContainer key={`rowgrid-${column.column_name}-${i}`}>
                                <RowCell key={`rowcell-${column.column_name}-${i}`}>
                                    <Label 
                                       key={`lable-${column.column_name}-${i}`} 
                                    >{column.column_name} { (checkDisable ? "     (disabled)" : "")}</Label>
                                    <Input
                                        disabled={
                                            checkDisable
                                        }
                                        key={`input-${column.column_name}-${i}`}
                                        value={value}
                                        name={column.column_name}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </RowCell>
                                <RowCell>
                                    {columnSpecs.map((entry) => {
                                        const key = entry[0];
                                        let value = entry[1];
                                        if (value === null) {
                                            value = 'NULL';
                                        }
                                        return (
                                            <div>
                                                <p>
                                                    <b>{key.toUpperCase()}: </b> {value}
                                                </p>
                                            </div>
                                        );
                                    })}
                                    <ButtonUpdate disabled={
                                            checkDisable
                                        } name={`${column.column_name}`} onClick={(e) => updateRecord(e)}>Update</ButtonUpdate>
                                </RowCell>
                                {/* <RowCell>
                                </RowCell> */}
                            </RowGridContainer>
                        );
                    })}
                    {/* <ButtonHolder>
                        <Button
                            exact
                            onClick={() => console.log('/save')}
                            label="Save"
                            variant="DefaultButton"
                            margin={'0'}
                        />
                        <Button
                            exact
                            onClick={() => console.log('/cancel')}
                            label="Cancel"
                            variant="DefaultButton"
                            margin={'0'}
                        />
                        <Button
                            exact
                            onClick={() => console.log('/delete')}
                            label="Delete"
                            variant="DefaultButton"
                            margin={'0'}
                        />
                    </ButtonHolder> */}
                </FlexContainer>
            </Body>
        </>
    );
};

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    padding-top: 50px;
    padding-bottom: 80px;
    grid-row-gap: 20px;
`;

const RowGridContainer = styled.div`
    display: grid;
    width: 100%;
    align-self: center;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 40px;
    font-size: 12px;
    color: white;
    padding: 10px;
    border-radius: 8px;
    &:hover {
      background-color: #beaeff;
      color: #000;
    }
`;

const ButtonHolder = styled.div`
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
`;


const ButtonUpdate = styled.button`
    width: 200px;
    margin-top: 5px;
    &:hover {
      background-color: #beaeff;
      color: #000;
    }

`;



const Label = styled.p`
    font-size: 14px;
`;

const Input = styled.textarea`
    max-width: 80%;
    height: fit-content;
`;

const RowCell = styled.div`
    display: flex;
    flex-direction: column;
    align-items: space-evenly;
    justify-items: center;
    margin-top: 20px;
`;

export default RecordDetails;
