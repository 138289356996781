import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Title from '../../../components/Title';
import Body from '../../../layout/Body';
import { formatDateTimeWithTimeZone, capitalizeFirstLetter } from '../../../../../utils/generalUtils';
import axiosInstance from '../../../../../config/axiosConfig';
import { setColor } from '../../../../../assets/styles/styles';
import LoadingIcon from '../../../../../components/common/loading/LoadingIcon';
import DiamondBackground from '../../../../../assets/images/backgrounds/Diamond_Checkered_Background.png';
import { ReactComponent as ChevronIcon } from '../../../../../assets/images/icons/Chevron.svg';
import { ReactComponent as Checkmark } from '../../../../../assets/images/icons/Checkmark.svg';
import { ReactComponent as Cross } from '../../../../../assets/images/icons/Cross.svg';
import { default as Button } from '../../../../../components/common/button/Button';
import Typography from '../../../../../components/common/text/Typography';
import DeleteModal from './DeleteModal';
import { ReactComponent as NotFoundIcon } from '../../../../../assets/images/icons/NotFound.svg';
import Modal from '../../../components/Modal';

const RoomsDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [roomData, setRoomData] = useState();
    const [length, setLength] = useState(0);
    const [editedRoomName, setEditedRoomName] = useState('');
    const [editedIsFeatured, setEditedIsFeatured] = useState('');
    const [editedRoomType, setEditedRoomType] = useState('');
    const [editedOwnerName, setEditedOwnerName] = useState('');
    const [editedThumbnail, setEditedThumbnail] = useState('');
    const [editedOwnerLogo, setEditedOwnerLogo] = useState('');
    const [editedLogo, setEditedLogo] = useState('');
    const [editedSubtitle, setEditedSubtitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [label, setLabel] = useState('');
    const [hasChanges, setHasChanges] = useState(false);
    const [fieldsObj, setFieldsObj] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const fetchData = async (url) => {
        try {
            setIsLoading(true);
            const response = await axiosInstance.get(url);
            if (response.data.room) {
                const isFeatured = response.data.room?.isFeatured !== undefined ? response.data.room?.isFeatured : '';
                const createdDateTime = formatDateTimeWithTimeZone(response.data.room.createdAt);
                const updatedDateTime = formatDateTimeWithTimeZone(response.data.room.updatedAt);
                response.data.room.createdDateTime = createdDateTime;
                response.data.room.updatedDateTime = updatedDateTime;
                setRoomData(response.data);
                setEditedRoomName(response.data.room.name);
                setEditedIsFeatured(capitalizeFirstLetter(String(isFeatured)));
                setEditedRoomType(response.data.room?.roomType);
                setEditedOwnerName(response.data.room?.ownerName);
                setEditedThumbnail(response.data.room?.thumbnail);
                setEditedOwnerLogo(response.data.room?.ownerLogo);
                setEditedLogo(response.data.room?.logo);
                setEditedSubtitle(response.data.room?.subtitle);
                setLength(1);
                const fields = [
                    { label: 'Name', key: 'name', value: editedRoomName, rowNo: 1 },
                    { label: 'Feature Room', key: 'isFeatured', value: editedIsFeatured, rowNo: 1 },
                    { label: 'Room Type', key: 'roomType', value: editedRoomType, rowNo: 2 },
                    { label: 'Owner Name', key: 'ownerName', value: editedOwnerName, rowNo: 2 },
                    { label: 'Thumbnail', key: 'thumbnail', value: editedThumbnail, rowNo: 3 },
                    { label: 'Owner Logo', key: 'ownerLogo', value: editedOwnerLogo, rowNo: 3 },
                    { label: 'Logo', key: 'logo', value: editedLogo, rowNo: 4 },
                    { label: 'Subtitle', key: 'subtitle', value: editedSubtitle, rowNo: 4 },
                ];
                setFieldsObj(fields);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setRoomData('');
            setIsLoading(false);
        }
    };

    const handleCloseClick = (label) => {
        if (hasChanges) {
            setErrorMessage(`Changes not saved for ${label.toLowerCase()}`);
            setHasChanges(false);
            fetchData(`${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}`);
        }
        setLabel('');
        setErrorMessage('');
    };

    const handleCheckmarkClick = async (label) => {
        setLabel(label);
        if (!hasChanges) {
            setErrorMessage(`No changes to update for ${label.toLowerCase()}`);
        } else {
            setErrorMessage('');
        }
        const updateBody = {};
        const fieldToUpdate = fieldsObj.find((field) => field.key === label);

        if (fieldToUpdate && hasChanges) {
            const fieldName = fieldToUpdate.key;
            const fieldValue = getFieldValue(fieldToUpdate);
            updateBody[fieldName] = fieldValue;
        }

        if (Object.keys(updateBody).length !== 0) {
            try {
                const response = await axiosInstance.put(`${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}`, {
                    ...updateBody,
                });

                if (response.status === 200) {
                    setSuccessMessage(`Successfully updated ${label.toLowerCase()}`);
                } else {
                    setErrorMessage(`Failed to update ${label.toLowerCase()}`);
                }
                setHasChanges(false);
                fetchData(`${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}`);
            } catch (error) {
                console.error('Error updating data:', error);
                setErrorMessage(`Failed to update ${label.toLowerCase()}`);
                fetchData(`${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}`);
            }
        }
    };

    useEffect(() => {
        if (errorMessage || successMessage) {
            const timer = setTimeout(() => {
                setErrorMessage('');
                setSuccessMessage('');
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [errorMessage, successMessage]);

    useEffect(() => {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}`;
        fetchData(url);
    }, [id]);

    const handleBack = () => {
        // window.history.back();
        navigate(`/console/rooms`);
    };

    const getFieldValue = (field) => {
        switch (field.key) {
            case 'name':
                return editedRoomName;
            case 'isFeatured':
                return editedIsFeatured;
            case 'roomType':
                return editedRoomType;
            case 'ownerName':
                return editedOwnerName;
            case 'thumbnail':
                return editedThumbnail;
            case 'ownerLogo':
                return editedOwnerLogo;
            case 'logo':
                return editedLogo;
            case 'subtitle':
                return editedSubtitle;
            default:
                return '';
        }
    };

    const handleFieldChange = (e, field) => {
        setHasChanges(true);
        switch (field.key) {
            case 'name':
                setEditedRoomName(e.target.value);
                break;
            case 'isFeatured':
                setEditedIsFeatured(e.target.value);
                break;
            case 'roomType':
                setEditedRoomType(e.target.value);
                break;
            case 'ownerName':
                setEditedOwnerName(e.target.value);
                break;
            case 'thumbnail':
                setEditedThumbnail(e.target.value);
                break;
            case 'ownerLogo':
                setEditedOwnerLogo(e.target.value);
                break;
            case 'logo':
                setEditedLogo(e.target.value);
                break;
            case 'subtitle':
                setEditedSubtitle(e.target.value);
                break;
            default:
                break;
        }
    };

    const uniqueRowNumbers = [...new Set(fieldsObj.map((field) => field.rowNo))];

    return (
        <>
            <Title
                title={'ROOMS OVERVIEW'}
                description={
                    'Manage all rooms in  Intraverse. Changes made here are automatically pushed to Production.'
                }
            />
            <UserConsoleText style={{ marginBottom: '0.5rem' }} onClick={handleBack}>
                <StyledIconPrev /> BACK TO TABLE
            </UserConsoleText>
            <Body isLoading={isLoading}>
                <>
                    {roomData && (
                        <>
                            <CardTable>
                                <TableRow>
                                    <TableColumn>
                                        <Typography variant="PinkText">{roomData.room.id}</Typography>
                                    </TableColumn>

                                    <TableColumnButton>
                                        <Button
                                            label="DELETE"
                                            buttonWidth="auto"
                                            onClick={() => openModal()}
                                            border={`${setColor.accent4}`}
                                        />
                                    </TableColumnButton>
                                </TableRow>

                                <TableRow>
                                    <TableColumn>
                                        <strong>CREATED:</strong> {roomData.room.createdDateTime}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <strong>UPDATED:</strong> {roomData.room.updatedDateTime}
                                    </TableColumn>
                                </TableRow>
                            </CardTable>

                            <Table>
                                {uniqueRowNumbers.map((rowNo) => (
                                    <Row key={rowNo}>
                                        {fieldsObj
                                            .filter((field) => field.rowNo === rowNo)
                                            .map((field) => (
                                                <React.Fragment key={field.key}>
                                                    <Column errorMessage={errorMessage}>
                                                        <Label>{field.label}</Label>
                                                        {label === field.key && errorMessage && (
                                                            <MessageDesc type="error">{errorMessage}</MessageDesc>
                                                        )}
                                                        {label === field.key && successMessage && (
                                                            <MessageDesc type="success">{successMessage}</MessageDesc>
                                                        )}
                                                        <InputBox
                                                            type="text"
                                                            id={field.key}
                                                            value={getFieldValue(field)}
                                                            onChange={(e) => handleFieldChange(e, field)}
                                                        />
                                                    </Column>
                                                    <Column small>
                                                        <IconDiv>
                                                            <StyledCheckmark
                                                                onClick={() => handleCheckmarkClick(field.key)}
                                                            />
                                                            <StyledCloseIcon
                                                                onClick={() => handleCloseClick(field.key)}
                                                            />
                                                        </IconDiv>
                                                    </Column>
                                                </React.Fragment>
                                            ))}
                                    </Row>
                                ))}
                            </Table>
                        </>
                    )}
                    {length === 0 && <NotFoundImage alt="Not Found" />}
                    <Modal
                        isOpen={isModalOpen}
                        setIsOpen={setIsModalOpen}
                        children={<DeleteModal id={id} closeModal={() => setIsModalOpen(false)} />}
                    />
                </>
            </Body>
        </>
    );
};

export default RoomsDetails;

const UserConsoleText = styled.h1`
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    width: fit-content;
    color: ${setColor.accent4};
    position: relative;
    cursor: pointer;
`;
const SmallSubtitle = styled.p`
    font-family: 'Barlow', Bold;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    width: fit-content;
    background: ${setColor.pinkButtonBgGradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
`;
const StyledIconPrev = styled(ChevronIcon)`
    transition: transform 0.3s;
    transform: rotate(180deg);
    border-radius: 5px !important;
    margin-top: 12px !important;
    transform: rotate(90deg) !important;
`;

const IconDiv = styled.div`
    justify-content: center;
    align-items: center;
    margin: 2.5rem 2rem 2rem 1rem;
    width: fit-content;
    color: ${setColor.accent4};
    justify-content: flex-end;
`;

const StyledCheckmark = styled(Checkmark)`
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
        #Union {
            fill: #93ecc7;
        }
    }
`;

const StyledCloseIcon = styled(Cross)`
    border-radius: 5px !important;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:hover {
        #Union {
            fill: #ff8484;
        }
    }
`;

const CardTable = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0rem 1rem 1rem 1rem;
    padding: 20px 20px 20px 20px;
    color: ${setColor.inputText};
    background: ${setColor.onHoverTextcolor2};
    border-radius: 5px;
`;

const TableRow = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const TableColumn = styled.div`
    flex: 1;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const TableColumnButton = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Table = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 20px;
    color: ${setColor.inputText};
`;

const Row = styled.div`
    display: flex;
`;

const Column = styled.div`
    margin-top: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0')};
    flex: ${(props) => (props.small ? '0.5' : '2')};
    width: ${(props) => (props.small ? '5%' : '95%')};
    @media (max-width: 768px) {
        flex: ${(props) => (props.small ? '1' : '2')};
    }
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;
const MessageDesc = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
    color: ${(props) => (props.type === 'success' ? setColor.successColor : setColor.errorColor)};
`;
const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    height: 50%;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
`;

const NotFoundImage = styled(NotFoundIcon)`
    width: 300px;
    height: 300px;

    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;
