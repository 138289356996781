import React from 'react';
import styled from 'styled-components';
import { ReactComponent as PlusSign } from '../images/plusSign.svg';
import { ReactComponent as SadFace } from '../images/sad_face.svg';
import { ReactComponent as Stripe } from '../images/stripe.svg';
import { ReactComponent as Terrazero } from '../images/terrazero.svg';
import Button from '../../../../../components/common/button/Button';
import Typography from '../../../../../components/common/text/Typography';
import { setColor } from '../../../../../assets/styles/styles';

const AddCartPopUp = ({ fetchClientSecret }) => (
    <Card>
        <IconHolder>
            <SadFace />
        </IconHolder>
        <Typography variant="h2PurpleHeading" fontSize={'40px'}>
            No payment method found
        </Typography>
        <Typography variant="pWhite">
            Please connect a payment method to your account.
            <br />
            <Italic>Powered by Stripe</Italic>
        </Typography>
        <Button variant="PinkButton" label="Setup payment method" onClick={() => fetchClientSecret()} />
        <LogoHolder>
            <Terrazero />
            <PlusSign />
            <Stripe />
        </LogoHolder>
    </Card>
);

const Card = styled.div`
    width: 600px;
    height: auto;
    border-radius: 20px;
    background: ${setColor.passwordValidBox};
    padding: 3rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        padding: 1rem;
        width: 100%;
    }
`;

const IconHolder = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const Italic = styled.p`
    padding-top: 20px;
    font-style: italic;
`;

const LogoHolder = styled.div`
    margin-top: 23px;
    width: 100px;
    display: flex;
    justify-content: space-between;
`;

export default AddCartPopUp;
