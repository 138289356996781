import axiosInstance from '../../../config/axiosConfig';

export const createCheckoutSession = async ( token, successUrl, cancelUrl, roomName, productId ) => {
    
    const config = {
        method: 'post',
        url: `${process.env.REACT_APP_CORE_API_URI}/v1/web/stripe/create-checkout-session`,
        data: {
            roomName,
            productId,
            successUrl,
            cancelUrl,
            currency: 'usd',
            quantity: 1, 
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    try {
        const response = await axiosInstance.request(config);
        return response;
    } catch (error) {
        console.log('error', error);
        return error;
    }
};

export const getPaymentInfoBySessionId = async (token, checkoutSessionId, roomName, productId) => {
    try {
        const config = {
            method: 'get',
            url: `${process.env.REACT_APP_CORE_API_URI}/v1/web/stripe/checkout-session/${roomName}/${productId}/${checkoutSessionId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await axiosInstance.request(config);
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
};


export const getProductInfo = async (token, productId) => {
    try {
        const config = {
            method: 'get',
            url: `${process.env.REACT_APP_CORE_API_URI}/web/products/${productId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await axiosInstance.request(config);
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
};
