import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getTableInfo } from '../../../../api/AdminController';
import Title from '../../components/Title';
import DataTable from '../../../../components/common/dataTable/DataTable';
import { setColor } from '../../../../assets/styles/styles';

const DynamicTable = () => {
    const { tableName } = useParams();
    const token = useSelector((state) => state?.user?.token);
    const [tableData, setTableData] = useState([]);
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [primaryKey, setPrimaryKey] = useState(null);
    const [isDeletedColumn, setIsDeletedColumn] = useState("NO");
    const [selectedRow, setSelectedRow] = useState(null);
    const navigate = useNavigate();
    const [headers, setHeaders] = useState(Object.keys(tableData));
    
    const openModal = (row) => {
        setSelectedRow(row);
        setIsModalOpen(true);
        navigate(`/console/record_details?table=${tableName}&primary_key=${primaryKey}&id=${row[primaryKey]}`);
    };

    const renderRow = (record) => {
      return Object.values(record)
    };

    const fetchTableInfo = async () => {
        const response = await getTableInfo(token, tableName);
        if (response.status === 200) {
          const keys = Object.values(response.data.columns_data)
          let isDeletedFilter = false 
          for (let i = 0; i < keys.length; i+=1) {
            const key = keys[i];
            if (key.column_name === "is_deleted") {
                isDeletedFilter = true;
                break;
            }
          }
          if (isDeletedFilter) {
            setIsDeletedColumn(true);
            const filteredRecords = await response.data.records.filter((record) => {
                return record.is_deleted !== true
            })
            setTableData(filteredRecords)
          } else {
            setTableData(response.data.records)
          }
          setHeaders(keys)
          setPrimaryKey(response.data.primary_key)
        } else {
          console.log(`Error querying record from table ${tableName}`)
        }
    };

    useEffect(() => {
        fetchTableInfo();
    }, []);

    return (
        <>
            <Title
                title={`Table ${tableName}`}
                description={
                    'Columns information and record on table'
                }
            />
           <InfoContainer>
              <Text>Primary Key: {primaryKey}</Text>
              <Text>Number of records: {tableData.length}</Text>
              <Text>Number of columns: {headers.length}</Text>
              <Text>IsDeleted Column: {isDeletedColumn.toString()}</Text>
            </InfoContainer> 
            <Link to={`/console/tables`}>Go back to schema</Link>
            <DataTable
              headers={headers}
              data={tableData}
              renderRow={renderRow}
              openModal={openModal}
              isModalOpen={isModalOpen}
              selectedRow={selectedRow}
              // detailsComponent={RoomsDetails}
              />
        </>
    );
};

const Text = styled.p`
    /* margin-bottom: 15px; */
    text-align: left;
    height: 50px;
    color: ${setColor.inputText};
`;

const InfoContainer = styled.div`
    display: flex;
    grid-gap: 20px;
`;



export default DynamicTable;
