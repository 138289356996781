import { store } from '../Store';

import axiosInstance from '../../config/axiosConfig';

export const UpdateData = (user, role) => ({
    type: 'UPDATE_USER',
    payload: {
        user,
        role,
    },
});

export const SaveUser = (email, user, token, userId, role, isMerchant, expiration, profileIcon = '') => ({
    type: 'USER_SAVE',
    payload: {
        email,
        user,
        token,
        userId,
        role,
        isMerchant,
        profileIcon,
        expiration,
    },
});

export const updateEmail = (email) => ({
    type: 'UPDATE_EMAIL',
    payload: {
        email,
    },
});
export const UpdateUsername = (username) => ({
    type: 'UPDATE_USERNAME',
    payload: {
        username,
    },
});

export const DeleteUser = () => {
    store.dispatch({ type: 'USER_DELETE' });
};

export const refreshAccessToken = (userId) => async (dispatch) => {
    try {
        const response = await axiosInstance.post('/web/users/auth-session', {
            userId,
        });
        const newToken = response.data.token;
        const { expiration } = response.data;

        dispatch({
            type: 'REFRESH_TOKEN',
            payload: { token: newToken, expiration },
        });
    } catch (error) {
        console.error('Failed to refresh access token:', error);
        throw error;
    }
};
