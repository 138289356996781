import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import axiosInstance from '../../../../config/axiosConfig';
import { setColor } from '../../../../assets/styles/styles';
import Title from '../../components/Title';
import Body from '../../layout/Body';
import { formatDateTimeWithTimeZone } from '../../../../utils/generalUtils';
import { ReactComponent as DropdownIcon } from '../../../../assets/images/icons/Chevron.svg';
import Pagination from './components/Pagination';
import RoomsTable from './components/RoomsTable';
import Banner from '../../../../components/common/ui/Banner';

const RoomsPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isDropdownOpenType, setIsDropdownOpenType] = useState(false);
    const [selectedOptionType, setSelectedOptionType] = useState('allrooms');
    const [isDropdownOpenState, setIsDropdownOpenState] = useState(false);
    const [selectedOptionState, setSelectedOptionState] = useState('allstates');
    const [totalNumber, setTotalNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredTableData, setFilteredTableData] = useState([]);
    const defaultRequestType = 'Banner';
    const defaultURLSet = `${process.env.REACT_APP_CORE_API_URI}/web/rooms?requestType=${defaultRequestType}&sortBy=newest`;
    const [onLoadDefaultURL, setOnLoadDefaultURL] = useState(defaultURLSet);
    const [successMessage, setSuccessMessage] = useState('');
    const pageLimit = 5;
    const deleteBannerSuccess = localStorage.getItem('deleteBannerSuccess');
    const deleteBannerFlag = localStorage.getItem('deleteBannerFlag');

    if (deleteBannerSuccess !== '' && deleteBannerFlag === 'true' && successMessage !== deleteBannerSuccess) {
        setSuccessMessage(deleteBannerSuccess);
        setTimeout(() => {
            setSuccessMessage('');
            localStorage.setItem('deleteBannerFlag', 'false');
            localStorage.setItem('deleteBannerSuccess', '');
        }, 5000);
    }

    const fetchData = async (url, page) => {
        try {
            setIsLoading(true);
            const response = await axiosInstance.get(url, {
                params: {
                    page,
                    limit: pageLimit,
                },
            });
            const roomsCardsData = [];

            if (response.data.rooms) {
                for (let i = 0; i < response.data.rooms.length; i += 1) {
                    const createdDateTime = formatDateTimeWithTimeZone(response.data.rooms[i].createdAt);
                    const updatedDateTime = formatDateTimeWithTimeZone(response.data.rooms[i].updatedAt);
                    const isFeatured =
                        response.data.rooms[i].isFeatured !== undefined ? response.data.rooms[i].isFeatured : null;
                    roomsCardsData.push({
                        ...response.data.rooms[i],
                        createdDateTime,
                        updatedDateTime,
                        isFeatured,
                    });
                }
                roomsCardsData.sort((a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime));
                setTotalNumber(response.data.totalRoomsCount);
                setTableData(roomsCardsData);
                setFilteredTableData(roomsCardsData);

                if (response.data.totalRoomsCount > 0) {
                    const pages = Math.ceil(response.data.totalRoomsCount / pageLimit);
                    setTotalPages(pages);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setTotalNumber(0);
            setTableData([]);
            setFilteredTableData([]);
            setIsLoading(false);
        }
    };

    const applyFiltersType = async (type) => {
        if (type !== 'allrooms' && type !== 'Navigation') {
            const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms?requestType=${defaultRequestType}&categories=${type}&sortBy=newest`;
            setOnLoadDefaultURL(url);
            setCurrentPage(1);
            fetchData(url, currentPage);
        } else if (type === 'Navigation') {
            const url = `${process.env.REACT_APP_CORE_API_URI}/v1/web/rooms?requestType=${type}`;
            setOnLoadDefaultURL(url);
            setCurrentPage(1);
            fetchData(url, currentPage);
        } else {
            setCurrentPage(1);
            setOnLoadDefaultURL(defaultURLSet);
            fetchData(defaultURLSet, currentPage);
        }
    };

    const applyFiltersState = async (state) => {
        if (state === 'allstates') {
            fetchData(onLoadDefaultURL, currentPage);
        } else if (state === 'true') {
            const filteredDataTrue = tableData.filter((item) => item.isFeatured === true);
            setFilteredTableData(filteredDataTrue);
        } else {
            const filteredData = tableData.filter((item) => item.isFeatured === false);
            setFilteredTableData(filteredData);
        }
    };

    const toggleDropdownState = () => {
        setIsDropdownOpenState(!isDropdownOpenState);
    };

    const handleOptionClickState = (state) => {
        setIsDropdownOpenState(false);
        setSelectedOptionState(state);
        applyFiltersState(state);
    };

    const toggleDropdownType = () => {
        setIsDropdownOpenType(!isDropdownOpenType);
    };

    const handleOptionClickType = (type) => {
        setIsDropdownOpenType(false);
        setSelectedOptionType(type);
        applyFiltersType(type);
    };

    useEffect(() => {
        const url = onLoadDefaultURL;
        fetchData(url, currentPage);
    }, [currentPage]);

    return (
        <>
            <Title
                title={'ROOMS OVERVIEW'}
                description={
                    'Manage all rooms in  Intraverse. Changes made here are automatically pushed to Production.'
                }
            />
            <Body isLoading={isLoading}>
                <>
                    <DropdownContainer>
                        <DropdownButton onClick={toggleDropdownType} isopen={isDropdownOpenType.toString()}>
                            All Rooms
                            <StyledDropdownIcon isopen={isDropdownOpenType.toString()} />
                            {isDropdownOpenType && (
                                <DropdownContent>
                                    <DropdownItem
                                        onClick={() => handleOptionClickType('allrooms')}
                                        isSelected={selectedOptionType === 'allrooms'}
                                    >
                                        All Rooms
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => handleOptionClickType('Apartment')}
                                        isSelected={selectedOptionType === 'Apartment'}
                                    >
                                        Apartment
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => handleOptionClickType('Garage')}
                                        isSelected={selectedOptionType === 'Garage'}
                                    >
                                        Garage
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => handleOptionClickType('Healthcare')}
                                        isSelected={selectedOptionType === 'Healthcare'}
                                    >
                                        Healthcare
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => handleOptionClickType('Navigation')}
                                        isSelected={selectedOptionType === 'Navigation'}
                                    >
                                        Navigation
                                    </DropdownItem>
                                </DropdownContent>
                            )}
                        </DropdownButton>

                        <DropdownButton onClick={toggleDropdownState} isopen={isDropdownOpenState.toString()}>
                            All States
                            <StyledDropdownIcon isopen={isDropdownOpenState.toString()} />
                            {isDropdownOpenState && (
                                <DropdownContent>
                                    <DropdownItem
                                        onClick={() => handleOptionClickState('allstates')}
                                        isSelected={selectedOptionState === 'allstates'}
                                    >
                                        All States
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => handleOptionClickState('true')}
                                        isSelected={selectedOptionState === 'true'}
                                    >
                                        True
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => handleOptionClickState('false')}
                                        isSelected={selectedOptionState === 'false'}
                                    >
                                        False
                                    </DropdownItem>
                                </DropdownContent>
                            )}
                        </DropdownButton>

                        <PaginationContainer>
                            <Pagination
                                totalNumber={totalNumber}
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                                totalPages={totalPages}
                            />
                        </PaginationContainer>
                    </DropdownContainer>
                    <RoomsTable roomsData={filteredTableData} />
                </>
            </Body>
            <Banner
                width={'100%'}
                zIndex="1000"
                message={`${successMessage}`}
                type={'SUCCESS'}
                openBanner={!!successMessage}
                setOpenBanner={setSuccessMessage}
                topDisplay
                showCloseButton
                gameScreen={false}
                timer={8000}
                top={'78px'}
                left={'0'}
            />
        </>
    );
};

export default RoomsPage;

const DropdownContainer = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
`;

const DropdownButton = styled.button`
    position: relative;
    padding: 10px;
    background-color: TRANSPARENT;
    color: ${setColor.inputText};
    font-family: 'Barlow-Bold', Helvetica;
    font-size: 16px;
    font-weight: 700;
    border-radius: 3px;
    margin: 1rem 1rem 0rem 0rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    border: ${(props) =>
        props.isopen === 'true' ? `1px solid ${setColor.accent4}` : `1px solid ${setColor.modalOutline}`};

    @media (max-width: 768px) {
        font-size: 13px;
    }
`;
const StyledDropdownIcon = styled(DropdownIcon)`
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    margin-left: 5px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isopen === 'true' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    background: ${(props) => (props.isSelected ? `${setColor.pinkButtonBgGradient}` : 'transparent')};
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    border-radius: 5px;
    &:hover {
        color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.accent4}`)};
    }
`;

const DropdownContent = styled.div`
    position: absolute;
    right: 0;
    padding: 5px;
    top: 3rem;
    left: 0rem;
    width: fit-content;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    text-align: left;
    z-index: 1;
`;

const PaginationContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    background-color: TRANSPARENT;
    color: ${setColor.inputText};
    margin-left: auto;
    margin-top: 1rem;
`;
