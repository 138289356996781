import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as GreenFeatureCheckmark } from '../intraverseLandingPage/IntraverseHomeImages/GreenFeatureCheckmark.svg';
import { ReactComponent as RedCross } from '../intraverseLandingPage/IntraverseHomeImages/redCross.svg';
import { setColor } from '../../assets/styles/styles';
import { minLengthRegex, uppercaseRegex, numberRegex, specialCharacterRegex } from '../../utils/generalUtils';

export default function PasswordChecker({ password, backgroundColor, borderRadius }) {
    // Password checker states
    const [lengthError, setLengthError] = useState(true);
    const [uppercaseError, setUppercaseError] = useState(true);
    const [numberError, setNumbersError] = useState(true);
    const [specialCharError, setSpecialCharError] = useState(true);

    useEffect(() => {
        if (minLengthRegex.test(password)) {
            setLengthError(false);
        } else {
            setLengthError(true);
        }
        if (uppercaseRegex.test(password)) {
            setUppercaseError(false);
        } else {
            setUppercaseError(true);
        }
        if (numberRegex.test(password)) {
            setNumbersError(false);
        } else {
            setNumbersError(true);
        }
        if (specialCharacterRegex.test(password)) {
            setSpecialCharError(false);
        } else {
            setSpecialCharError(true);
        }
    }, [password]);

    return (
        <PasswordValidBox backgroundColor={backgroundColor} borderRadius={borderRadius}>
            <PasswordValidText style={{ display: 'flex', flexDirection: 'column' }}>
                <p>
                    {lengthError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                    ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                    )}{' '}
                    8 characters long
                </p>
                <p>
                    {uppercaseError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                    ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                    )}{' '}
                    One uppercase
                </p>
            </PasswordValidText>
            <PasswordValidText style={{ display: 'flex', flexDirection: 'column' }}>
                <p>
                    {' '}
                    {numberError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                    ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                    )}{' '}
                    One number
                </p>
                <p>
                    {specialCharError ? (
                        <RedCross style={{ marginRight: '5px' }} />
                    ) : (
                        <GreenFeatureCheckmark height={'15px'} />
                    )}{' '}
                    One special character
                </p>
            </PasswordValidText>
        </PasswordValidBox>
    );
}

PasswordChecker.propTypes = {
    password: PropTypes.string,
};

const PasswordValidBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '5px')};
    background: ${(props) => (props.backgroundColor ? props.backgroundColor : setColor.passwordValidBox)};
    width: 100%;
    height: fit-content;
    flex-shrink: 0;
    padding: 0.5rem 0.5rem 0.7rem 0.5rem;
    margin-top: 15px;

    @media (max-width: 320px) {
        /* width: 100vw; */
        padding: 0.5rem 0.5rem 1rem 0.5rem;
        /* border-radius: 0px; */
        margin-top: 5px;
        flex-direction: column;
    }
`;
const PasswordValidText = styled.div`
    flex-basis: 100%;
    & > p {
        color: ${setColor.inputText};
        font-family: 'Barlow', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 5px;
        margin-left: 5px;

        @media (max-width: 425px) {
            font-size: 12px;
        }
    }
    @media (max-width: 320px) {
        width: 120%;
        font-size: 14px;
    }
`;
