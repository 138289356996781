import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserToken, fetchUserId } from '../../../redux/slices/users';
import { LogInWithEmail } from '../../../api/UserController';
import { SUPERADMIN_ROLE } from '../../../constants';
import { SaveMerchant } from '../../../redux/actions/MerchantActions';
import { SaveUser } from '../../../redux/actions/UserActions';
import Button from '../../../components/common/button/Button';
import { isValidEmail } from '../../../utils/generalUtils';
import { CheckBoxMain } from '../../../components/common/checkBox/CheckBoxMain';
import { setColor } from '../../../assets/styles/styles';
import { Input } from '../../../components/common/input/Input';

const LoginForm = ({ setMessage }) => {
    const dispatch = useDispatch();

    const [emailValue, setEmailValue] = useState('');
    const [emailErrorMsg, setEmailErrorMsg] = useState('');
    const [isEmailError, setIsEmailError] = useState(false);

    const [passwordValue, setPasswordValue] = useState('');
    const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
    const [isPasswordError, setIsPasswordError] = useState(false);

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const userToken = useSelector(fetchUserToken);
    const userId = useSelector(fetchUserId);

    const password = useRef();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const res = await LogInWithEmail(emailValue, passwordValue);

                if (res.status === 200) {
                    const role = res.userRoles[0];
                    if (role === SUPERADMIN_ROLE || res.isMerchant) {
                        setMessage('Login Successful. Admin');
                        if (res.isMerchant) {
                            const {
                                merchantName,
                                merchantId,
                                street,
                                city,
                                country,
                                postalCode,
                                merchantBusinessType,
                                stripeClientId,
                            } = res.merchant;
                            const address = { street, city, country, postalCode };

                            dispatch(
                                SaveMerchant(merchantName, merchantId, address, merchantBusinessType, stripeClientId)
                            );
                        }

                        if (res.userRoles[0] === SUPERADMIN_ROLE || res.isMerchant) {
                            dispatch(
                                SaveUser(
                                    res.user.email,
                                    res.user.displayName,
                                    res.token,
                                    res.userId,
                                    res.userRoles[0],
                                    res.isMerchant,
                                    res.expiration,
                                    res.profileIconBase64
                                )
                            );
                        }
                    } else {
                        dispatch(
                            SaveUser(
                                res.user.email,
                                res.user.displayName,
                                res.token,
                                res.userId,
                                '',
                                res.isMerchant,
                                res.expiration,
                                res.profileIconBase64
                            )
                        );
                    }
                } else {
                    setMessage('Failed to login, please ensure your information is correct');
                    setTimeout(() => {
                        window.location.reload();
                    }, 4000);
                }
            } catch (error) {
                console.error('API call error:', error);
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
            } finally {
                setIsLoading(false);
            }
        };

        if (isFormSubmitted && !isEmailError && !isPasswordError && emailErrorMsg === '' && passwordErrorMsg === '') {
            fetchData();
        }
    }, [isFormSubmitted, isEmailError, isPasswordError, emailErrorMsg, passwordErrorMsg, emailValue, passwordValue]);

    const handleEmailChange = (e) => {
        const { value } = e.target;
        setEmailValue(value);

        if (isEmailError && value !== '') {
            setIsEmailError(false);
            setEmailErrorMsg('');
        }
    };

    const handlePasswordChange = (e) => {
        const { value } = e.target;
        setPasswordValue(value);

        if (isPasswordError && value !== '') {
            setIsPasswordError(false);
            setPasswordErrorMsg('');
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsFormSubmitted(true);

        setIsEmailError(false);
        setEmailErrorMsg('');
        setIsPasswordError(false);
        setPasswordErrorMsg('');

        if (emailValue === '') {
            setIsEmailError(true);
            setEmailErrorMsg('Email is required');
            setIsFormSubmitted(false);
        } else if (!isValidEmail(emailValue)) {
            setIsEmailError(true);
            setEmailErrorMsg('Email must be a valid email address');
            setIsFormSubmitted(false);
        }

        if (passwordValue === '') {
            setIsPasswordError(true);
            setPasswordErrorMsg('Password is required');
            setIsFormSubmitted(false);
        }
    };

    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            console.log('ticked');
        }
    };

    function addMessageListener() {
        window.vuplex.addEventListener('message', (event) => {
            const json = event.data;
            // > JSON received: { "type": "greeting", "message": "Hello from C#!" }
            console.log(`JSON received: ${json}`);
        });
    }
    function sendMessageToCSharp() {
        // This object passed to postMessage() automatically gets serialized as JSON
        // and is emitted via the C# MessageEmitted event. This API mimics the window.postMessage API.
        window.vuplex.postMessage({ type: 'login', message: `{"userToken": "${userToken}", "userId": "${userId}"}` });
    }

    if (window.vuplex) {
        // The window.vuplex object already exists, so go ahead and send the message.
        sendMessageToCSharp();
        addMessageListener();
    } else {
        window.addEventListener('vuplexready', addMessageListener);
        // The window.vuplex object hasn't been initialized yet because the page is still
        // loading, so add an event listener to send the message once it's initialized.
        window.addEventListener('vuplexready', sendMessageToCSharp);
    }

    // await webViewPrefab.WaitUntilInitialized();
    // webViewPrefab.WebView.CloseRequested += (sender, eventArgs) => {
    //         Debug.Log("Destroying the WebViewPrefab because window.close() was called.");
    //         webViewPrefab.Destroy();
    // };

    return (
        <StyledForm id="formLogin" onSubmit={(e) => onSubmit(e)}>
            <Input
                variant="LoginInput"
                type="email"
                placeholder="Email address"
                value={emailValue}
                onChangeHandler={handleEmailChange}
            />
            {isEmailError && !isValidEmail(emailValue) && <ErrorMessage>{emailErrorMsg}</ErrorMessage>}

            <Input
                variant="LoginInput"
                type="password"
                placeholder="Password"
                ref={password}
                value={passwordValue}
                onChangeHandler={handlePasswordChange}
            />
            {isPasswordError && <ErrorMessage>{passwordErrorMsg}</ErrorMessage>}

            <StyledCheckboxLabel>
                <CheckBoxMain
                    height={'25px'}
                    width={'25px'}
                    fontSize={16}
                    labelTextRight={'Stay signed in'}
                    onChange={handleCheckboxChange}
                />{' '}
                {/* <p style={{ color: 'red' }}>UNITY TEST</p> */}
            </StyledCheckboxLabel>
            <Button
                label="SIGN IN"
                buttonWidth={'100%'}
                variant={!isValidEmail(emailValue) || passwordValue === '' ? 'DefaultButton' : 'PinkButton'}
                type="submit"
            >
                SIGN IN
            </Button>

            <StyledLink to="/reset-password">Forgot password?</StyledLink>
        </StyledForm>
    );
};

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    padding: 20px;
    box-sizing: border-box;

    @media (max-width: 768px) {
        width: 90%;
    }

    @media (max-width: 480px) {
        width: 100%;
    }
`;

const ErrorMessage = styled.div`
    line-height: 1.5;
    font-size: 0.75rem;
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    text-align: left;
    color: ${setColor.errorColor};
    margin-left: 10px;
`;

const StyledCheckboxLabel = styled.div`
    width: fit-content;
    display: flex;
    padding: 5px 10px 30px 1px;
    margin-top: 10px;
`;

const StyledLink = styled(RouterLink)`
    color: ${setColor.accent4};
    text-decoration: none;
    text-align: center;
    font-size: 18px;
    color: rgb(190, 174, 255);
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin: 20px;
`;

export default LoginForm;
