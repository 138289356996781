import React from 'react';
import styled from 'styled-components';
import Brush from './IntraverseHomeImages/Brush.png';
import LineChart from './IntraverseHomeImages/LineChart.png';
import Shop from './IntraverseHomeImages/Shop.png';
import PeopleHug from './IntraverseHomeImages/PeopleHug.png';
import Joystick from './IntraverseHomeImages/Joystick.png';
import GreenCheckmark from './IntraverseHomeImages/GreenFeatureCheckmark.svg';
import IntraverseMiniLogo from './IntraverseHomeImages/IntraverseMiniLogo.svg';
import { setColor } from '../../assets/styles/styles';

const features = [
    {
        icon: Brush,
        title: 'Personalize',
        points: ['Customizable Virtual Environments', 'Customizable Avatars'],
    },
    {
        icon: LineChart,
        title: 'Track',
        points: ['Metrics and Analytics', 'Data Capture', 'KYC / AML'],
    },
    {
        icon: Shop,
        title: 'Sell',
        points: ['E-Commerce Using Credit Cards/Fiat', 'Sales of Virtual and Physical Products'],
    },
    {
        icon: PeopleHug,
        title: 'Engage',
        points: ['Host Audio Broadcast', 'Text and Audio Chat', 'Video Playback', 'Linkouts', 'Letter to the Artist'],
    },
    {
        icon: Joystick,
        title: 'Experience',
        points: ['Mobile Ready (Streaming or App)', 'Gamification / Quests', ' Game Mechanics'],
    },
];

const IntraverseFeatures = () => (
    <FeaturesPanelBackground>
        <FeaturesPanelBackgroundBorder>
            <FeatureMainTitle>FEATURES</FeatureMainTitle>
            <FeaturesPanelContainer>
                {features.map((features, index) => (
                    <FeaturesPanel key={index}>
                        <IconContainer>
                            <Icon src={features.icon} alt={features.title} />
                            <Title>{features.title}</Title>
                        </IconContainer>
                        <FeaturesList>
                            {features.points.map((point, pointIndex) => (
                                <CheckmarkContainer key={pointIndex}>
                                    <img
                                        style={{ width: '15px', marginRight: '1rem' }}
                                        src={GreenCheckmark}
                                        alt="Green Checkmark"
                                    />
                                    <PointItem key={pointIndex}>{point}</PointItem>
                                </CheckmarkContainer>
                            ))}
                        </FeaturesList>
                    </FeaturesPanel>
                ))}
            </FeaturesPanelContainer>
            <ContactUsText>
                Curious to learn more?
                <a style={{ marginLeft: '10px' }} href="https://terrazero.com/contact" target="_blank" rel="noreferrer">
                    Contact Us
                </a>
                <img
                    src={IntraverseMiniLogo}
                    style={{ height: '26px', marginLeft: '10px' }}
                    alt="Intraverse Mini Logo"
                />
            </ContactUsText>
        </FeaturesPanelBackgroundBorder>
    </FeaturesPanelBackground>
);

export default IntraverseFeatures;

const FeaturesPanelBackground = styled.div`
    width: 100%;
    background-color: ${setColor.landingBackground};
`;

const FeaturesPanelBackgroundBorder = styled.div`
    width: 96.7%;
    height: 100%;
    background-color: ${setColor.landingBackground};
    border-top: 3px solid ${setColor.tickerBorder};
    border-bottom: 5px solid ${setColor.tickerBorder};
    margin: 0 1.66%;
    padding: 5rem 3rem;

    @media (max-width: 768px) {
        padding: 1rem 0.5rem;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 2rem 1rem;
    }
`;

const FeaturesPanelContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    @media (max-width: 960px) {
        flex-direction: column;
        padding: 0px;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        padding: 5px;
    }
`;

const FeaturesPanel = styled.div`
    text-align: center;
    border-radius: 10px;
    border: 3px solid rgba(190, 174, 255, 0.2);
    padding: 30px 10px 30px 15px;
    border-radius: 8px;
    flex: 1;
    margin: 0 15px;
    height: 490px;

    @media (max-width: 768px) {
        margin: 1rem;
    }

    @media (min-width: 768px) and (max-width: 960px) {
        margin: 1rem 5rem;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        padding: 25px 15px;
        height: 490px;
        margin: 5px;
    }
`;

const Icon = styled.img`
    width: 50px;
    align-self: center;

    @media (min-width: 960px) and (max-width: 1440px) {
        width: 40px;
    }
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-direction: column;
`;

const CheckmarkContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    flex-wrap: nowrap;

    @media (max-width: 768px) {
        margin-left: 0.5rem;
    }

    @media (min-width: 425px) and (max-width: 960px) {
        text-align: center;
        margin-left: 2rem;
    }
`;

const Title = styled.h3`
    font-size: 26px;
    margin: 30px 0;
    background: linear-gradient(45deg, #f0153f 5.13%, #dd42ce 100.27%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    font-family: 'Source Sans Pro', sans-serif;

    @media (min-width: 960px) and (max-width: 1440px) {
        margin: 20px 0;
        font-size: 22px;
    }
`;

const FeatureMainTitle = styled.h3`
    color: ${setColor.nextStepText};
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 3.5rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
`;

const ContactUsText = styled.h3`
    color: ${setColor.inputText};
    font-family: 'Barlow', sans-serif;
    font-size: 24px;
    text-align: center;
    margin-top: 3.5rem;
    display: flex;
    flex-direction: row;
    font-weight: 500;
    justify-content: center;
    align-items: center;

    & > a {
        color: ${setColor.accent4};
        font-weight: 700;
        text-decoration: none;
    }

    @media (max-width: 768px) {
        font-size: 16px;
        margin-bottom: 2rem;
    }
`;

const FeaturesList = styled.ul`
    list-style: none;
    padding: 0;
`;

const PointItem = styled.li`
    display: flex;
    align-items: left;
    text-align: left;
    margin: 5px 0;
    color: ${setColor.circularProgress};
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
    line-height: 1.8;

    @media (max-width: 960px) {
        font-size: 22px;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        font-size: 16px;
    }
`;
