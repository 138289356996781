import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from '../../../components/common/text/Typography';

const Title = ({ title, description, button }) => (
    <HeaderContainer>
        <Text>
            <Typography variant="ConsoleTitle">{title}</Typography>
            <Typography variant="ConsoleDescription">{description}</Typography>
        </Text>
        {button && <Button>{button}</Button>}
    </HeaderContainer>
);

Title.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    button: PropTypes.node,
};

export default Title;

const HeaderContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem 0;

    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
        margin: 2rem 0 1.5rem 0;
    }
`;

const Text = styled.div`
    height: auto;
    width: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (max-width: 768px) {
        width: 100%;
        text-align: center;
    }
`;

const Button = styled.div`
    @media (max-width: 768px) {
        margin-top: 2rem;
    }
`;
