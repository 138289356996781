export const SUPERADMIN_ROLE = 'superadmin';
export const ADMIN_ROLE = 'admin';
export const USER_ROLE = 'user';
export const MERCHANT = 'merchant';
export const SUPERADMIN_DISPLAY_ROLE = 'Super Admin';
export const ADMIN_DISPLAY_ROLE = 'Admin';
export const USER_DISPLAY_ROLE = 'User';
export const COREAPI_WEB_USERS_URL = '/web/users';
export const COREAPI_WEB_MERCHANTS_URL = '/web/merchant';
export const COREAPI_WEB_STATUSES_URL = '/web/statuses';
export const COREAPI_WEB_EVENTS_URL = '/web/events';
export const COREAPI_WEB_VANITY_URL = '/web/vanity_links';
export const COREAPI_WEB_ROOM_URL = '/web/rooms';

export const spotifyTracks = {
    TRACK_TEDDYSWIMS_LOSE_CONTROL: 'spotify:album:7nacKlk586eLRBSIsrk9DB',
    // Add more cases here
};

export const ALBUM = 'ALBUM';
export const TRACK = 'TRACK';
export const CONTEXT = 'context';
export const OPEN_TEDDY_NOTE = 'OPEN_TEDDY_NOTE';
export const OPEN_TEDDY_SELFIE = 'OPEN_TEDDY_SELFIE';
export const OPEN_TEDDY_CONTEST = 'OPEN_TEDDY_CONTEST';
export const STREAM_SECONDS = 31;
export const CLIENT_ID = 'diAr304KPO679nsf251epcMw8';
export const REDIRECT_URI = 'https://dev.da8tzvwvdozxp.amplifyapp.com/estee-lauder';
export const SCOPE = 'email';
export const RESP_TYPE = 'code';

export const OPEN_BROADCAST_MODAL = 'OPEN_BROADCAST_MODAL';
export const PDF_OPEN = 'PDF_OPEN';
export const PDF_CLOSE = 'PDF_CLOSE';
export const NEW_ROOM_LOADING = 'NEW_ROOM_LOADING';
export const ROOM_UNLOADED = 'ROOM_UNLOADED';
export const SETUPCARD = 'SETUPCARD';
export const OPEN_SPOTIFY = 'OPEN_SPOTIFY';
export const FULL_SCREEN = 'FULL_SCREEN';
export const PAUSE_BACKGROUND_MUSIC = 'PAUSE_BACKGROUND_MUSIC';
export const PLAY_BACKGROUND_MUSIC = 'PLAY_BACKGROUND_MUSIC';
export const ENTERING_NAV_MENU = 'ENTERING_NAV_MENU';

export const TZ_FREE_FORM_JSON = {
    nameTitle: '',
    urlSuffix: '',
    clientName: 'Intraverse Privacy Policy',
    emailTitle: '',
    nameSubtitle: '',
    emailSubtitle: '',
    spotifySignupTitle: '',
    spotifySignupSubtitle: '',
    termsAndConditionsText1:
        'By pressing <strong>Accept</strong>, you agree to receive personalized updates and marketing messages based on your information, interests, activities, website visits, and device data.',
    termsAndConditionsText2: 'For more information about how we use your personal information, please see ',
    termsAndConditionsLinkTZLink: 'https://terrazero.com/privacy-policy',
    tzName: 'TerraZero Privacy Policy',
    termsAndConditionsClientLink: 'https://terrazero.com/privacy-policy',
};

// Unity Messages
export const UNITY_OPEN_WEB_INTERFACE = 'Web Interface Opened';
export const UNITY_CLOSE_WEB_INTERFACE = 'Web Interface Closed';

export const blurbTitleData = [
    {
        title: ' Intraverse',
        // text: 'Intraverse is a new platform innovating on how music reaches fans, and what artists can do with their music. It is the future of immersive social engagement, e-commerce, community development and virtual experiences on the Internet--where users can immediately join and interact with the artists, creators, brands and content they love. '
    },
    {
        title: ' Innovation',
    },
    {
        title: ' Endless Possibilities',
    },
    {
        title: ' Future of web',
    },
];

export const stripeCountries = [
    { country: 'Australia', code: 'AU' },
    { country: 'Austria', code: 'AT' },
    { country: 'Belgium', code: 'BE' },
    { country: 'Brazil', code: 'BR' },
    { country: 'Bulgaria', code: 'BG' },
    { country: 'Canada', code: 'CA' },
    { country: 'Croatia', code: 'HR' },
    { country: 'Cyprus', code: 'CY' },
    { country: 'Czech Republic', code: 'CZ' },
    { country: 'Denmark', code: 'DK' },
    { country: 'Estonia', code: 'EE' },
    { country: 'Finland', code: 'FI' },
    { country: 'France', code: 'FR' },
    { country: 'Germany', code: 'DE' },
    { country: 'Gibraltar', code: 'GI' },
    { country: 'Greece', code: 'GR' },
    { country: 'Hong Kong', code: 'HK' },
    { country: 'Hungary', code: 'HU' },
    { country: 'India', code: 'IN' },
    { country: 'Indonesia', code: 'ID' },
    { country: 'Ireland', code: 'IE' },
    { country: 'Italy', code: 'IT' },
    { country: 'Japan', code: 'JP' },
    { country: 'Latvia', code: 'LV' },
    { country: 'Liechtenstein', code: 'LI' },
    { country: 'Lithuania', code: 'LT' },
    { country: 'Luxembourg', code: 'LU' },
    { country: 'Malaysia', code: 'MY' },
    { country: 'Malta', code: 'MT' },
    { country: 'Mexico ', code: 'MX' },
    { country: 'Netherlands', code: 'NL' },
    { country: 'New Zealand', code: 'NZ' },
    { country: 'Norway', code: 'NO' },
    { country: 'Poland', code: 'PL' },
    { country: 'Portugal', code: 'PT' },
    { country: 'Romania', code: 'RO' },
    { country: 'Singapore', code: 'SG' },
    { country: 'Slovakia', code: 'SK' },
    { country: 'Slovenia', code: 'SI' },
    { country: 'Spain', code: 'ES' },
    { country: 'Sweden', code: 'SE' },
    { country: 'Switzerland', code: 'CH' },
    { country: 'Thailand', code: 'TH' },
    { country: 'United Arab Emirates', code: 'AE' },
    { country: 'United Kingdom', code: 'GB' },
    { country: 'United States', code: 'US' },
];

export const countryOptions = ['USA', 'Canada'];
export const genderOptions = ['Male', 'Female', 'Non-binary', 'Prefer not to say'];
export const tshirtFileId = '553b15bf-fde7-4b01-8ffa-13d50176d273';
export const DISCOVERSE_OWNER_ID = '5a4764cf-c5c8-47e2-bedd-78e9b00a53c8';
export const discoverseFileId = '7e0f95a8-5a83-4db2-a293-dda30735c5e2';

export const LOG_EVENTS_LISTENER_DATA = 'event_listener_data';
export const LOG_EVENTS_USER_LOGIN_DATA = 'user_login';
export const LOG_EVENTS_DEVICE_DATA = 'device_info';
export const LOG_EVENTS_AVATAR_SELECTION = 'avatar_select';
