import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { setColor } from '../../assets/styles/styles';
// sections
import LoginForm from './components/LoginForm';
// import AuthSocial from '../../api/AuthSocial';
import IntraverseLogoFull from '../intraverseLandingPage/IntraverseHomeImages/IntraverseLogoFull.png';
import Button from '../../components/common/button/Button';
import Footer from '../../components/layout/footer/Footer';
import BackgroundContainer from '../../components/common/Backgrounds/BackgroundContainer';

const Login = () => {
    const [message, setMessage] = useState(null);

    return (
        <BackgroundContainer loginBorder>
            <HeaderDiv>
                <Link to="/">
                    <LoginLogo src={IntraverseLogoFull} alt="Company Logo" />
                </Link>
                <Link to="/register">
                    <Button variant="OutlinedButton" label="Create Account" margin="0 20px 0 0" />
                </Link>
            </HeaderDiv>
            <ContentContainer>
                <LoginTitle>JUMP IN THE INTRAVERSE</LoginTitle>
                <LoginSubtitle>Sign in with your email below.</LoginSubtitle>
                {message && <ErrorMessage>{message}</ErrorMessage>}
                <LoginForm setMessage={setMessage} />
                {/* <AuthSocial /> */}
                <Footer />
            </ContentContainer>
        </BackgroundContainer>
    );
};

export default Login;

// ----------------------------------------------------------------------

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-top: -20px;
`;

const ErrorMessage = styled.div`
    color: ${setColor.errorColor};
    margin-bottom: 3px;
`;

const LoginLogo = styled.img`
    display: flex;
    align-self: flex-start;
    width: 250px;
    height: auto;
    margin-bottom: 30px;
    margin-left: 10px;

    @media (max-width: 768px) {
        align-self: center;
        margin-top: 0px;
        margin-right: 0px;
        margin-left: 0px;
    }
`;
const HeaderDiv = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 10px;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        margin-left: 0px;
        margin-top: 10px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const LoginTitle = styled.h3`
    color: ${setColor.accent4};
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 50px;
    margin-top: -0.7rem;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

    @media (max-width: 960px) {
        font-size: 40px;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        font-size: 40px;
    }
`;

const LoginSubtitle = styled.p`
    color: ${setColor.circularProgress};
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin-top: 1.5rem;
    @media (max-width: 960px) {
        margin: 1rem -10px;
    }
`;
