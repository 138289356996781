import React, { useState } from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import NativeSelect from '@mui/material/NativeSelect';
import Alert from '../assets/images/spotify/Alert.svg';
import TicketIcon from '../assets/images/spotify/TicketIcon.svg';
import { sendVagonMessage } from '../features/vagon/helpers/utils';
import * as constants from '../constants';

function ContestComponent(props) {
    const [city, setCity] = useState([]);

    const handleChange = (event) => {
        if (event.target.value === '') {
            setCity([]);
        } else {
            const splitString = event.target.value.split(',');
            const result = [`${splitString[0]}, ${splitString[1]}`].concat(splitString.slice(2));
            setCity(result);
        }
    };

    const menuItemStyle = {
        color: 'black', // Style for the dropdown menu items
    };

    return (
        <>
            <ContestModalContainer>
                <ContestModalContent>
                    <ContestHeader>
                        <CityLabel>CITY</CityLabel>
                        <CityInputWrapper>
                            <Box
                                sx={{
                                    width: '100%',
                                    marginRight: '10px',
                                }}
                            >
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel id="demo-simple-select-label" sx={{ color: '#fff' }}>
                                        Choose your city
                                    </InputLabel>
                                    <NativeSelect
                                        MenuProps={{
                                            style: {
                                                color: 'black !important',
                                            }, // Style for the dropdown menu items
                                        }}
                                        sx={{
                                            width: '100%',
                                            color: '#fff',
                                            fontSize: '20px',
                                            paddingTop: '5px',
                                            paddingLeft: '10px',
                                            '.MuiSvgIcon-root ': {
                                                fill: 'white !important',
                                            },
                                        }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Choose your city"
                                        onChange={handleChange}
                                        inputProps={{
                                            name: 'city',
                                            id: 'uncontrolled-native',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        <option
                                            style={menuItemStyle}
                                            value={['Minneapolis, MN', '44.9781206', '-93.2751684']}
                                        >
                                            Minneapolis, MN
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Chicago, IL', '41.9126° N', '87.6303° W']}
                                        >
                                            Chicago, IL
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Richmond, VA', '37.62727859684375', '-77.59120861166221']}
                                        >
                                            Richmond, VA
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Norfolk, VA', '36.87950734302601', '-76.27992468180366']}
                                        >
                                            Norfolk, VA
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Fort Lauderdale, FL', '26.116603356860182', '-80.10761014153651']}
                                        >
                                            Fort Lauderdale, FL
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['St. Petersburg, FL', '27.776441394592034', '-82.63135309808082']}
                                        >
                                            St. Petersburg, FL
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['New Orleans, LA', '29.920520', '-90.100900']}
                                        >
                                            New Orleans, LA
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Houston, TX', '29.733697098550643', '-95.41127497468463']}
                                        >
                                            Houston, TX
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Dallas, TX', '32.827835704635106', '-96.70901600032995']}
                                        >
                                            Dallas, TX
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['San Antonio, TX', '29.412649779400603', '-98.48834619826204']}
                                        >
                                            San Antonio, TX
                                        </option>
                                        <option style={menuItemStyle} value={['Wichita, KS', '37.6914 N', '97.3497 W']}>
                                            Wichita, KS
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Tulsa, OK', '36.148136471849156', '-95.97256962420967']}
                                        >
                                            Tulsa, OK
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Oklahoma City, OK', '35.50799500', '-97.53004890']}
                                        >
                                            Oklahoma City, OK
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Oakland, CA', '37.8049° N', '122.2719° W']}
                                        >
                                            Oakland, CA
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Portland, OR', '45.523260722574086', '-122.63216318717448']}
                                        >
                                            Portland, OR
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Seattle, WA', '47.584906', '-122.367867']}
                                        >
                                            Seattle, WA
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Salt Lake City, UT', '40.753083', '-111.900678']}
                                        >
                                            Salt Lake City, UT
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Denver, CO', '39.74005029143603', '-104.95701586997322']}
                                        >
                                            Denver, CO
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Omaha, NE', '41.258112363747465', '-95.93122151000958']}
                                        >
                                            Omaha, NE
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Cleveland, OH', '41°30′14″N', '81°39′13″W']}
                                        >
                                            Cleveland, OH
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Pittsburgh, PA', '40.441710655491775', '-80.00790743950616']}
                                        >
                                            Pittsburgh, PA
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Boston, MA', '42.36194562278512', '-71.14485701723042']}
                                        >
                                            Boston, MA
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['New York, NY', '40.7422° N', '73.9880° W']}
                                        >
                                            New York, NY
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Philadelphia, PA', '39.96133996177305', '-75.14154406863584']}
                                        >
                                            Philadelphia, PA
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Washington, DC', '38.87969081658682', '-77.02561011811828']}
                                        >
                                            Washington, DC
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Nashville, TN', '36.1612° N', '86.7785° W']}
                                        >
                                            Nashville, TN
                                        </option>
                                        <option
                                            style={menuItemStyle}
                                            value={['Atlanta, GA', '33.75961569991967', '-84.39160626930897']}
                                        >
                                            Atlanta, GA
                                        </option>
                                    </NativeSelect>
                                </FormControl>
                            </Box>
                            <CloseIcon
                                sx={{ paddingRight: '5px' }}
                                onClick={() => {
                                    sendVagonMessage(constants.UNITY_CLOSE_WEB_INTERFACE);
                                    window.Vagon?.focusIframe();
                                    props.setOpenContestModalState(false);
                                }}
                            />
                        </CityInputWrapper>
                    </ContestHeader>
                    {city.length === 0 ? (
                        <Coordinates
                            style={{
                                flexDirection: 'column',
                            }}
                        >
                            <TicketIconContainer src={TicketIcon} alt="Ticket Icon" />
                            <GetTickets>Get free Teddy Swims concert tickets!</GetTickets>
                            <TicketsBody>
                                Way to go! You have the chance to claim two free tickets to Teddy’s concert. Tap on the
                                bar above and select your city to get started.
                                <br />
                                <p>
                                    <img src={Alert} alt="Alert" style={{ marginRight: '10px' }} />
                                    Act fast! Tickets are limited and can only be claimed once per city. First come,
                                    first serve.
                                </p>
                            </TicketsBody>
                        </Coordinates>
                    ) : (
                        <Coordinates>
                            <CityContainer>
                                <CityTitle>{city[0]}</CityTitle>
                            </CityContainer>
                            <CoordContainer>
                                <Long>
                                    <LatText>
                                        <p style={{ paddingBottom: '0.8rem' }}>Latitude & Longitude:</p>
                                        <br />
                                        {city[1]}, {city[2]}
                                    </LatText>
                                </Long>
                            </CoordContainer>
                        </Coordinates>
                    )}
                </ContestModalContent>
            </ContestModalContainer>
            ;
        </>
    );
}
export default ContestComponent;

const ContestModalContainer = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;

    display: flex;
    align-items: center;
    justify-content: center;

    transform: ${(props) => (props.landscape ? 'rotate(-90deg)' : 'none')};
    ${(props) => (props.landscape ? 'bottom: 0; left: 0;' : 'top: 0; left: 0;')}
`;

const ContestModalContent = styled.div`
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
`;
const TicketIconContainer = styled.img`
    margin-bottom: 2rem;

    @media (max-width: 960px) {
        margin-bottom: 0.5rem;
        margin-top: -1.5rem;
        height: 70px;
    }
`;

const LatText = styled.h1`
    color: #fbfcff;
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    font-weight: 300;
    @media (max-width: 960px) {
        font-size: 16px;
    }
    p {
        font-size: 16px;
        font-weight: 400;
    }
`;
const CityTitle = styled.h1`
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
    font-family: 'Signika Negative', sans-serif;
    font-weight: 700;
    @media (max-width: 960px) {
        font-size: 18px;
    }
`;
const GetTickets = styled.h3`
    font-size: 18px;
    margin-bottom: 10px;
    color: #fff;
    font-family: 'Signika Negative', sans-serif;
    font-weight: 700;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 0.5rem;

    @media (max-width: 960px) {
        font-size: 16px;
    }
`;
const TicketsBody = styled.p`
    color: #ced1d9;
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    margin: 1rem;
    width: 450px;
    @media (max-width: 960px) {
        font-size: 16px;
        width: 90vw;
    }

    p {
        color: #ffe664;
        text-align: center;
        font-size: 16px;
        font-style: italic;
        font-weight: 500;
        line-height: normal;
        margin-top: 2rem;
    }
`;
const ContestHeader = styled.div`
    background: #1b1b23;
    backdrop-filter: blur(17.5px);
    color: #fff;
    /* padding: 10px; */
    display: flex;
    align-items: center;
`;

const CityInputWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;
const CityLabel = styled.div`
    background: #313139;
    color: #fff;
    font-family: 'Barlow', sans-serif;
    padding: 25px;
    @media (max-width: 960px) {
        padding: 25px 8px;
    }
`;

const CityContainer = styled.div`
    position: fixed;
    top: 15%;
    display: flex;
    flex-direction: column;
`;
const CoordContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    background-color: none;

    @media (max-width: 960px) {
        flex-direction: column;
    }
`;

const Coordinates = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    background: rgba(27, 27, 35, 0.65);
    backdrop-filter: blur(17.5px);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
`;
const Long = styled.div`
    width: 320px;
    height: 140px;
    margin: -50px 0 0 1rem;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(50px);
    padding: 2rem 1rem 1rem 1rem;

    @media (max-width: 960px) {
        margin: 0.5rem;
        height: 100px;
    }
`;
