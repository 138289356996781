/* eslint-disable */
import styled from 'styled-components';
import { setBoxShadow, setColor } from '../../../assets/styles/styles';

export const CheckBoxMain = ({
    height,
    width,
    shape,
    checkMarkColor,
    borderColor,
    uncheckedBg,
    checkedBg,
    checked,
    onChange,
    float,
    margin,
    labelTextLeft,
    labelTextRight,
    fontSize,
    ...props
}) => {
    return (
        <CheckboxContainer>
            {labelTextLeft && <CheckBoxLabel fontSize={fontSize}>{labelTextLeft}</CheckBoxLabel>}
            <CheckBoxMainDiv
                float={float}
                height={height}
                width={width}
                shape={shape}
                checkMarkColor={checkMarkColor}
                borderColor={borderColor}
                uncheckedBg={uncheckedBg}
                checkedBg={checkedBg}
                checked={checked}
                onChange={onChange}
                margin={margin}
                fontSize={fontSize}
            />
            {labelTextRight && <CheckBoxLabel fontSize={fontSize}>{labelTextRight}</CheckBoxLabel>}
        </CheckboxContainer>
    );
};

const CheckBoxLabel = styled.p`
    color: #ced1d9;
    text-align: center;
    vertical-align: middle;
    font-family: 'Barlow', sans-serif;
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '16px')};
    margin: 0 15px;
`;

const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
export const CheckBoxMainDiv = styled.input.attrs({ type: 'checkbox' })`
    float: ${(props) => (props.float ? props.float : 'left')};
    appearance: none;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    display: inline-block;
    height: ${(props) =>
        props.height ? props.height : !props.height && props.fontSize ? `${props.fontSize}px` : '30px'};
    width: ${(props) => (props.width ? props.width : !props.width && props.fontSize ? `${props.fontSize}px` : '30px')};
    min-width: ${(props) =>
        props.width ? props.width : !props.width && props.fontSize ? `${props.fontSize}px` : '30px'};
    min-height: ${(props) =>
        props.height ? props.height : !props.height && props.fontSize ? `${props.fontSize}px` : '30px'};
    vertical-align: center;
    transition: background-color 0.2s ease-in-out;
    border: 2.5px solid ${(props) => (props.borderColor ? props.borderColor : setColor.grey1)};
    border-radius: ${(props) => (props.shape === 'circle' ? '50%' : '0px')};
    background-color: ${(props) => (props.uncheckedBg ? props.uncheckedBg : 'transperant')};
    /* box-shadow: ${setBoxShadow.dark}; */
    margin: ${(props) => (props.margin ? props.margin : 'auto')};

    :after {
        content: '';
        position: absolute;
        border-bottom: 3.5px solid;
        border-left: 3.5px solid;
        border-color: transparent;
        top: 25%;
        left: 0;
        right: 0;
        width: 63%;
        height: 35%;
        margin: auto;
        transform: rotate(-50deg);
        transition: border-color 0.2s ease-in-out;
    }

    :hover:not(:disabled):not(:checked) {
        box-shadow: ${setBoxShadow.dark};
    }

    :focus {
        outline: none;
        box-shadow: ${setBoxShadow.dark};
    }

    :checked {
        background-color: 'transparent';
        &:after {
            border-color: ${(props) => (props.checkMarkColor ? props.checkMarkColor : setColor.grey1)};
        }
        background-color: ${(props) => (props.checkedBg ? props.checkedBg : setColor.iconColor3)};
        border: 2.5px solid ${setColor.iconColor3};
    }

    :disabled {
        cursor: default;
        opacity: 0.6;
    }
`;
