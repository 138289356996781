import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchUserToken } from '../../../../redux/slices/users';
import axiosInstance from '../../../../config/axiosConfig';
import PaymentMethod from './components/PaymentMethod';
import AddCartPopUp from './components/AddCartPopUp';
import StripeContainer from './components/StripeContainer';
import DisconnectTab from './components/DisconnectTab';
import Button from '../../../../components/common/button/Button';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import Body from '../../layout/Body';

const Wallet = () => {
    const token = useSelector(fetchUserToken);
    const email = useSelector((state) => state?.user?.email);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [toggleAddCart, setToggleAddCart] = useState(false);
    const [loading, setLoading] = useState(true);
    const [disconnectTabOn, setDisconnectTabOn] = useState(false);
    const [displayStripe, setDisplayStripe] = useState(false);
    const [clientSecret, setClientSecret] = useState(false);

    const fetchClientSecret = async () => {
        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_CORE_API_URI}/web/stripe/generate_intent`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        axiosInstance
            .request(config)
            .then((response) => {
                console.log(response);
                setClientSecret(response.data.client_secret);
                setToggleAddCart(!toggleAddCart);
                setDisplayStripe(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const setupStripeAccount = () => {
        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_CORE_API_URI}/web/stripe/customers`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                email,
            },
        };

        axiosInstance
            .request(config)
            .then((response) => {
                console.log('SUCCESS', response);
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchCustomerPaymentMethods = () => {
        const config = {
            method: 'get',
            url: `${process.env.REACT_APP_CORE_API_URI}/web/stripe/payment_methods`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        axiosInstance
            .request(config)
            .then((response) => {
                if (response.status === 200) {
                    const { paymentMethods } = response.data;
                    setPaymentMethods(paymentMethods);

                    setLoading(false);
                }
            })
            .catch(async (error) => {
                if (error.response?.status === 404 || error.response?.status === 400) {
                    const { userData } = error.response.data;
                    await setupStripeAccount();
                }
            });
    };

    useEffect(() => {
        setLoading(true);
        fetchCustomerPaymentMethods();
    }, []);

    return (
        <>
            <Title
                title={'My Wallet'}
                description={'Manage and review your cards, payments, and billing information.'}
                button={
                    paymentMethods.length > 0 && (
                        <Button
                            buttonWidth="100%"
                            variant={'OutlinedButton'}
                            label="REMOVE PAYMENT METHOD"
                            onClick={() => {
                                setDisconnectTabOn(true);
                            }}
                        />
                    )
                }
            />
            <Body isLoading={loading}>
                {paymentMethods.length > 0
                    ? paymentMethods.map((paymentMethod, i) => (
                          <PaymentMethod key={`${i}`} paymentMethod={paymentMethod} />
                      ))
                    : !displayStripe && (
                          <AddCartPopUp toggleAddCart={toggleAddCart} fetchClientSecret={fetchClientSecret} />
                      )}
                {displayStripe && <StripeContainer clientSecret={clientSecret} />}
            </Body>
            <Modal
                isOpen={disconnectTabOn}
                setIsOpen={setDisconnectTabOn}
                children={<DisconnectTab setDisconnectTabOn={setDisconnectTabOn} />}
            />
        </>
    );
};

export default Wallet;
