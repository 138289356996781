import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import { fetchVanityLinkData } from '../../api/VanityLinkController';
import { SaveVanityLink } from '../../redux/actions/VanityLinkActions';
import AvatarPresetPage from '../presets/AvatarPresetPage';
import VanityURLRedirects from '../../features/vagon/VanityURLRedirects';
import { getFromSessionStorage, setToSessionStorage, clearAllSessionStorage } from '../../hooks/useSessionStorage';
import IntraverseLogoIcon from '../../assets/images/logos/Intraverse_Logo_Icon.png';
import { getSpotifyToken, getProfile } from '../../features/spotify/api/SpotifyController';
import EmailForm from '../presets/EmailForm';
import NameCollectionForm from '../presets/NameCollectionForm';
import GenericTCModal from '../../components/GenericTCModal';
import { LogInWithForgerock, sendInfoToActionLog } from '../../api/UserController';
import Banner from '../../components/common/ui/Banner';
import { generateUniqueEmail } from '../../utils/generalUtils';
import NapsterLogo from '../../assets/images/clients/Napster_Logo_White.png';
import NapsterIcon from '../../assets/images/icons/faviconNapster.ico';
import TZLogo from '../../assets/images/logos/TZLogo.svg';
import pinkTZLogo from '../../assets/images/logos/pink_intraverse_logo.svg';
import Page from '../../components/Page';
import { actionTypes } from '../../utils/actionTypes';
import { DISCOVERSE_OWNER_ID } from '../../constants';
import { SaveUser } from '../../redux/actions/UserActions';

const VanityLogin = () => {
    const dispatch = useDispatch(); // Get the dispatch function
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    // Extracting the ID from the URL. This assumes that the wildcard path captures the ID. Adjust accordingly.
    const pathId = window.location.pathname.split('/').pop();

    const [navigateBackToEmailPage, setNavigateBackToEmailPage] = useState(false);
    const [renderComponent, setRenderComponent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // User States
    const [userId, setUserId] = useState(getFromSessionStorage('userId'));
    const [userToken, setUserToken] = useState(getFromSessionStorage('userToken'));
    const [displayName, setDisplayName] = useState(getFromSessionStorage('displayName'));
    const [email, setEmail] = useState(getFromSessionStorage('email'));
    const [vanityLinkData, setVanityLinkData] = useState({});
    const hasAcceptedTerms = localStorage.getItem('acceptedTerms') === 'true';
    const [acceptedTerms, setAcceptedTerms] = useState(hasAcceptedTerms);
    const [checkboxStatus, setCheckboxStatus] = useState(true);
    const [selectedAvatar, setSelectedAvatar] = useState(getFromSessionStorage('selectedAvatar'));

    // Spotify States
    const [spotifyNotPremiumAccount, setSpotifyNotPremiumAccount] = useState(false);
    const [spotifyToken, setSpotifyToken] = useState(getFromSessionStorage('spotifyAccessToken'));

    const [forgeRockError, setForgeRockError] = useState('');
    const [spotifyError, setSpotifyError] = useState('');

    // Meta tags
    const [pageTitle, setPageTitle] = useState('');
    const [ogImage, setOgImage] = useState('');
    const [iconHref, setIconHref] = useState('');
    const [descriptionContent, setDescriptionContent] = useState('');

    useEffect(() => {
        setIsLoading(true);

        const getVanityLinkData = async (pathId) => {
            try {
                const data = await fetchVanityLinkData(pathId);

                if (data.status !== 200) {
                    window.location.replace(process.env.REACT_APP_HOME_PAGE_URL);
                }

                setVanityLinkData(data);
                dispatch(
                    SaveVanityLink(
                        data.clientLogo,
                        data.backgroundImage,
                        data.freeformJson,
                        data.clientName,
                        data.emailLogin,
                        data.spotifyLogin,
                        data.broadcast,
                        data.ambientTrack
                    )
                );
            } catch (error) {
                if (process.env.REACT_APP_REDIRECT_TOGGLE) {
                    window.location.replace(process.env.REACT_APP_HOME_PAGE_URL);
                }
                console.error('Error fetching the vanity link data:', error);
                // Handle this error accordingly. Maybe redirect them to a 404 page or a custom error page?
            }
        };

        if (pathId === '[object%20Object]') {
            return;
        }

        if (pathId) {
            getVanityLinkData(pathId);
        }

        if (code && pathId === 'estee-lauder') {
            const fetchForgeRockData = async () => {
                const resp = await LogInWithForgerock(code);

                if (resp.status !== 200) {
                    if (resp.data.error && resp.data.error_description) {
                        setForgeRockError(`${resp.data.error_description} Please try again!`);
                    } else {
                        setForgeRockError('Unable to Login. Please try after sometime.');
                    }
                }
                if (resp.status === 200) {
                    const userInfo = resp.data;
                    setToSessionStorage('email', userInfo.email);
                    setToSessionStorage('username', userInfo.sub);
                    setToSessionStorage('token', userInfo.token);
                    setToSessionStorage('userId', userInfo.userId);
                    setEmail(userInfo.email);

                    if (getFromSessionStorage('email')) {
                        window.location.href = `/estee-lauder`;
                    }
                }
            };

            fetchForgeRockData();
        }

        if (code && pathId === 'teddyswims') {
            getSpotifyToken(code, urlParams, process.env.REACT_APP_WEB_URL_TEDDY).then((res) => {
                if (res?.accessToken) {
                    getProfile(res.accessToken).then((profile) => {
                        if (profile.product === 'free') {
                            setSpotifyNotPremiumAccount(true);
                            setSpotifyError('Unable to Login. Please try after sometime.');
                            return;
                        }

                        if (profile.product === 'premium') {
                            const script = document.createElement('script');
                            script.src = 'https://sdk.scdn.co/spotify-player.js';
                            script.async = true;
                            document.body.appendChild(script);
                            window.onSpotifyWebPlaybackSDKReady = () => {};

                            setToSessionStorage('spotifyAccessToken', res.accessToken);
                            setToSessionStorage('spotifyRefreshToken', res.refreshToken);
                            setToSessionStorage('email', profile.email);

                            setEmail(profile.email);
                            setSpotifyToken(res.accessToken);
                        }
                    });
                }
            });
        }

        setTimeout(() => {
            if (isLoading) {
                setIsLoading(false);
            }
        }, 3000);
    }, []);

    useEffect(() => {
        const handleBeforeUnload = () => {
            if (email && userToken && userId) {
                clearAllSessionStorage();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [acceptedTerms]);

    useEffect(() => {
        // This logic clears the login for discoverse and ensures users go through the normal login process for other vanity links.
        const handleClearLocalStorage = async () => {
            try {
                await clearAllSessionStorage();
                window.location.reload();
            } catch (error) {
                console.error('Error clearing session storage:', error);
            }
        };

        if (pathId !== 'discoverse' && email?.includes('discoverse')) {
            handleClearLocalStorage();
        }
        const attributes = {
            room: vanityLinkData?.clientName,
            email: email || null,
            name: displayName || null,
        };

        window.pushHotJarAttributes(userId || null, attributes);

        if (pathId === 'discoverse') {
            if (!getFromSessionStorage('fullscreen')) {
                setToSessionStorage('fullscreen', true);
            }
            if (!email) {
                const generatedEmail = generateUniqueEmail(pathId);
                setToSessionStorage('email', generatedEmail);
                setEmail(generatedEmail);
            }
            dispatch(
                SaveUser(
                    email,
                    displayName,
                    userToken,
                    userId,
                    '',
                    false,
                    getFromSessionStorage('expiration'),
                    ''
                )
            );
            if (email && !displayName && vanityLinkData !== undefined && selectedAvatar === null) {
                setRenderComponent(
                    <NameCollectionForm
                        pathId={pathId}
                        vanityLinkData={vanityLinkData}
                        email={email}
                        setUserId={setUserId}
                        setUserToken={setUserToken}
                        setDisplayNameHome={setDisplayName}
                        setIsLoading={setIsLoading}
                    />
                );
            }

            if (email && displayName && vanityLinkData !== undefined && selectedAvatar === null) {
                setRenderComponent(
                    <AvatarPresetPage
                        selectedAvatar={selectedAvatar}
                        setSelectedAvatar={setSelectedAvatar}
                        userToken={userToken}
                    />
                );
            }
        } else {
            if (
                ((!email || email === undefined) && !userId && !userToken && vanityLinkData !== undefined) ||
                navigateBackToEmailPage
            ) {
                setRenderComponent(
                    <EmailForm
                        vanityLinkData={vanityLinkData}
                        setEmail={setEmail}
                        spotifyError={spotifyError}
                        forgeRockError={forgeRockError}
                        setForgeRockError={setForgeRockError}
                        setSpotifyError={setSpotifyError}
                    />
                );
                setNavigateBackToEmailPage(false);
            }

            if (email && !displayName && vanityLinkData !== undefined && selectedAvatar === null) {
                setRenderComponent(
                    <AvatarPresetPage selectedAvatar={selectedAvatar} setSelectedAvatar={setSelectedAvatar} />
                );
            }

            if (email && !displayName && vanityLinkData !== undefined && selectedAvatar) {
                setRenderComponent(
                    <NameCollectionForm
                        vanityLinkData={vanityLinkData}
                        email={email}
                        setUserId={setUserId}
                        setUserToken={setUserToken}
                        setDisplayNameHome={setDisplayName}
                        setIsLoading={setIsLoading}
                    />
                );
            }
        }

        if (
            userToken &&
            userId &&
            selectedAvatar &&
            (acceptedTerms || vanityLinkData?.spotifyLogin || pathId === 'discoverse')
        ) {
            if (pathId === 'discoverse') {
                sendInfoToActionLog(actionTypes.SESSION_START, {}, userToken, DISCOVERSE_OWNER_ID);
            }
            setRenderComponent(<VanityURLRedirects userId={userId} />);
        }

        setTimeout(() => {
            if (isLoading) {
                setIsLoading(false);
            }
        }, 3000);
    }, [
        displayName,
        email,
        userToken,
        userId,
        isLoading,
        vanityLinkData,
        acceptedTerms,
        selectedAvatar,
        navigateBackToEmailPage,
    ]);

    const handleAcceptTerms = () => {
        setAcceptedTerms(true);
        setToSessionStorage('acceptedTerms', 'true');
    };

    useEffect(() => {
        // Set the page title based on vanityLinkData
        if (vanityLinkData.clientName === 'discoverse') {
            setPageTitle(`Napster Presents: Ric Wilson's Discoverse`);
            setOgImage('https://ric-wilson-html-page.s3.amazonaws.com/ricwilson-preview-800-418.png');
            setIconHref(NapsterIcon);
            setDescriptionContent('Together we can change the world, one disco at a time.');
        } else {
            setPageTitle(`Welcome to the Intraverse!`);
            setOgImage('https://storage.googleapis.com/amadea_static/TyBigImages/metatag-Intraverse-preview.png');
            setIconHref(TZLogo);
            setDescriptionContent('Explore, create, and connect in a digital world without boundaries.');
        }
    }, [vanityLinkData]);

    return (
        <Page
            meta={
                <>
                    <title>{pageTitle}</title>
                    <meta property="og:image" content={ogImage} />
                    <meta property="og:image:width" content="800" />
                    <meta property="og:image:height" content="418" />
                    <meta name="description" content={descriptionContent} />
                    <meta name="keywords" content="home, terraZero, technology" />
                    <meta name="author" content="TerraZero Technologies Inc." />
                    <meta property="og:image" content={ogImage} />
                    <meta property="og:image:width" content="800" />
                    <meta property="og:image:height" content="418" />
                    <link rel="icon" type="image/png" sizes="32x32" href={iconHref} />
                    <link rel="icon" type="image/png" sizes="16x16" href={iconHref} />
                    <link rel="apple-touch-icon" sizes="180x180" href={iconHref} />
                    <meta name="twitter:card" content={ogImage} />
                    <meta name="twitter:title" content={pageTitle} />
                    <meta name="twitter:description" content={descriptionContent} />
                    <meta name="twitter:image" content={ogImage} />
                </>
            }
        >
            <HomeContainer style={{ backgroundImage: `url(${vanityLinkData?.backgroundImage})` }}>
                <IntraverseLogo
                    client={pathId}
                    src={pathId === 'discoverse' ? NapsterLogo : IntraverseLogoIcon}
                    alt="Intraverse Logo"
                    onClick={() => {
                        if (pathId !== 'discoverse') {
                            setNavigateBackToEmailPage(true);
                            setEmail(null);
                        } else {
                            window.open('https://www.napster.com', '_blank');
                        }
                    }}
                />
                <div>
                    {pathId !== 'discoverse' &&
                        email &&
                        userToken &&
                        selectedAvatar &&
                        userId &&
                        !acceptedTerms &&
                        !vanityLinkData?.spotifyLogin && (
                            <GenericTCModal
                                setAcceptedTerms={handleAcceptTerms}
                                vanityLinkData={vanityLinkData.freeformJson}
                                setCheckboxStatus={setCheckboxStatus}
                                checkboxStatus={checkboxStatus}
                            />
                        )}
                </div>
                {pathId !== 'discoverse' && <BackgroundOverlay />}
                {isLoading && Object.keys(vanityLinkData).length === 0 ? (
                    <Box
                        sx={{
                            width: '50%',
                            color: '#968DFD',
                        }}
                    >
                        <LinearProgress color="inherit" size="lg" />
                    </Box>
                ) : (
                    renderComponent
                )}
                <Banner
                    width={'auto'}
                    zIndex="3000"
                    message={'We failed to connect to Spotify. Please connect a Premium Spotify account and try again.'}
                    type={'ERROR'}
                    openBanner={spotifyNotPremiumAccount}
                    setOpenBanner={setSpotifyNotPremiumAccount}
                    topDisplay
                    showCloseButton
                    gameScreen={false}
                    timer={8000}
                />
            </HomeContainer>
        </Page>
    );
};

export default VanityLogin;

const HomeContainer = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;

    /* background-image: url(); */
    background-size: cover;
    background-position: center;

    ::-webkit-scrollbar {
        display: none;
    }
`;

const BackgroundOverlay = styled.div`
    overflow: hidden;
    background-color: #1b1b23;
    opacity: 0.7;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 2;
`;

const IntraverseLogo = styled.img`
    cursor: pointer;
    position: absolute;
    width: ${(props) => (props.client === 'discoverse' ? '200px' : '40px')};
    height: ${(props) => (props.client === 'discoverse' ? '116px' : '40px')};
    top: ${(props) => (props.client === 'discoverse' ? '0px' : '20px')};
    left: 20px;
    z-index: 5;

    @media (max-width: 960px) {
        width: ${(props) => (props.client === 'discoverse' ? '86px' : '25px')};
        height: ${(props) => (props.client === 'discoverse' ? '50px' : '25px')};
        top: 5px;
        left: 10px;
    }
    @media (min-width: 960px) and (max-width: 1440px) {
        width: ${(props) => (props.client === 'discoverse' ? '120px' : '30px')};
        height: ${(props) => (props.client === 'discoverse' ? '68px' : '30px')};
        top: 20px;
        left: 20px;
    }
`;
