import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import RegisterUserPage from './pages/login/RegisterUserPage';
import VanityLogin from './pages/vanityLogin/VanityLogin';
import StartingPage from './pages/startingPage/StartingPage';
import VanityStartingPage from './pages/vanityLogin/VanityStartingPage';
import Login from './pages/login/Login';
import { Logout } from './api/UserController';
import Profile from './pages/console/user/profile/Profile';
import Wallet from './pages/console/user/wallet/Wallet';
import Orders from './pages/console/user/orders/Orders';
import Rooms from './pages/console/admin/rooms/Rooms';
import RoomsDetails from './pages/console/admin/rooms/components/RoomsDetails';
import { fetchUserId, fetchUserName, fetchUserRole, fetchUserToken } from './redux/slices/users';
import { SUPERADMIN_ROLE } from './constants';
import IntraverseLandingHome from './pages/intraverseLandingPage/IntraverseLandingHome';
import Console from './pages/console/Console';
import NotFound from './pages/Page404';
import Integrations from './pages/console/user/integrations/Integrations';
import Users from './pages/console/admin/users/Users';
import VanityLinks from './pages/console/admin/vanityLinks/VanityLinks';
import GuestCheckout from './pages/guestCheckout/GuestCheckout';
import Checkout from './pages/guestCheckout/components/checkout';
import CheckoutSuccess from './pages/guestCheckout/components/success';
import CheckoutCancel from './pages/guestCheckout/components/cancel';
import Tables from './pages/console/admin/tables/Tables';
import DynamicTable from './pages/console/admin/tables/DynamicTable';
import RecordDetails from './pages/console/admin/record/RecordDetails';
import ForgotPassword from './pages/login/ForgotPassword';
import UpdatePassword from './pages/login/UpdatePassword';
import Igt from './features/igt/Igt';

window.addEventListener('storage', (e) => {
    if (e.key === 'persist:user') {
        const json = JSON.parse(e.newValue).user;
        if (!JSON.parse(json).user) {
            Logout();
        }
    }
});

const DynamicLoginComponent = () => {
    const location = useLocation();
    const path = location.pathname;

    // Comment or remove below to change old vanity pages to new vanity flow
    if (path === '/estee-lauder' || path === '/teddyswims' || path === '/discoverse') {
        return <VanityLogin />;
    }
    return <VanityStartingPage />;
};

export default function Router() {
    const user = useSelector(fetchUserName);
    const role = useSelector(fetchUserRole);
    const token = useSelector(fetchUserToken);
    const userId = useSelector(fetchUserId);
    // const isMerchant = useSelector(fetchIsMerchant);
    // const merchant = useSelector(fetchMerchantInfo);
    // const { merchantId } = merchant;

    return useRoutes([
        {
            path: 'reset-password',
            element: (
                <RedirectIfLoggedIn user={user} token={token} userId={userId} role={role}>
                    {' '}
                    <ForgotPassword />
                    {/* <ResetPassword /> */}
                </RedirectIfLoggedIn>
            ),
        },
        {
            path: 'reset-password/:token',
            element: <UpdatePassword />,
        },
        {
            path: ':roomName/:productId/guest-checkout',
            element: (
                <UserConsoleRoute user={user} token={token} userId={userId} role={role}>
                    <GuestCheckout />
                </UserConsoleRoute>
            ),
            children: [
                {
                    path: '',
                    element: <Checkout />,
                },
                {
                    path: 'success',
                    element: <CheckoutSuccess />,
                },
                {
                    path: 'cancel',
                    element: <CheckoutCancel />,
                },
            ],
        },
        {
            path: 'console',
            element: (
                <UserConsoleRoute user={user} token={token} userId={userId} role={role}>
                    <Console />
                </UserConsoleRoute>
            ),
            children: [
                {
                    path: 'profile',
                    element: <Profile />,
                },
                {
                    path: 'orders',
                    element: <Orders />,
                },
                {
                    path: 'wallet',
                    element: <Wallet />,
                },
                {
                    path: 'integrations',
                    element: <Integrations />,
                },
                {
                    path: 'users',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <Users />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'rooms',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <Rooms />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'rooms/:id',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <RoomsDetails />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'record_details',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <RecordDetails />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'vanityLinks',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <VanityLinks />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'tables',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <Tables />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'table/:tableName',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <DynamicTable />
                        </RedirectIfNotAdmin>
                    ),
                },
            ],
        },
        {
            path: 'shell',
            element: <Igt />,
        },
        {
            path: 'autologin',
            element: <VanityStartingPage autoLogin />,
        },
        {
            path: 'login',
            element: (
                <RedirectIfLoggedIn user={user} token={token} userId={userId} role={role}>
                    <Login style={{ Index: 1000 }} />
                </RedirectIfLoggedIn>
            ),
        },
        {
            path: 'register',
            element: (
                <RedirectIfLoggedIn user={user}>
                    <RegisterUserPage />
                </RedirectIfLoggedIn>
            ),
        },
        {
            path: '404',
            element: <NotFound />,
        },
        {
            path: '*',
            element: <DynamicLoginComponent />,
        },
        {
            path: '/',
            element: !user ? <IntraverseLandingHome /> : <StartingPage />,
            children: [
                { path: '/', element: <Navigate to="/dashboard/app" /> },
                { path: '404', element: <NotFound /> },
                { path: '*', element: user ? <Navigate to="/404" /> : <Navigate to="/login" /> },
            ],
        },
    ]);
}

function ProtectedRoute({ children, user, token, userId, role }) {
    if ((user === '' && token === '' && userId === '' && role === '') || role !== SUPERADMIN_ROLE) {
        return <Navigate to="/" replace />;
    }
    return children;
}
ProtectedRoute.propTypes = {
    children: PropTypes.any,
    user: PropTypes.any,
    token: PropTypes.any,
    userId: PropTypes.any,
    role: PropTypes.any,
};
function UserConsoleRoute({ children, user, token, userId, role }) {
    if (user === '' && token === '' && userId === '' && role === '') {
        return <Navigate to="/" replace />;
    }
    return children;
}
UserConsoleRoute.propTypes = {
    children: PropTypes.any,
    user: PropTypes.any,
    token: PropTypes.any,
    userId: PropTypes.any,
    role: PropTypes.any,
};

function RedirectIfLoggedIn({ children, user, token, userId }) {
    if (user !== '' && token !== '' && userId !== '') {
        // if (role === SUPERADMIN_ROLE) {
        //   return <Navigate to="/call-to-action" replace />;
        // }
        return <Navigate to="/" replace />;
    }

    return children;
}

function RedirectIfNotAdmin({ role, children }) {
    console.log(role);
    if (role !== SUPERADMIN_ROLE) {
        return <Navigate to="/console/profile" replace />;
    }

    return children;
}

RedirectIfLoggedIn.propTypes = {
    children: PropTypes.any,
    user: PropTypes.any,
    token: PropTypes.any,
    userId: PropTypes.any,
    // role: PropTypes.any,
};
