import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { fetchUserToken } from '../../../../../redux/slices/users';
import axiosInstance from '../../../../../config/axiosConfig';
import { ReactComponent as PurpleDot } from '../images/purple_dot.svg';
import Button from '../../../../../components/common/button/Button';
import { setColor } from '../../../../../assets/styles/styles';
import { hexToRGBA } from '../../../../../utils/generalUtils';
import Typography from '../../../../../components/common/text/Typography';

const DisconnectTab = ({ setDisconnectTabOn }) => {
    const token = useSelector(fetchUserToken);

    const disconnectAccount = () => {
        const config = {
            method: 'delete',
            url: `${process.env.REACT_APP_CORE_API_URI}/web/stripe/disconnect_account`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        axiosInstance
            .request(config)
            .then((response) => {
                console.log(response);
                window.location.reload();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div>
            <Typography variant="h2PurpleHeading" margin={'0'}>
                Remove payment method?
            </Typography>
            <Typography variant="pWhite" margin={'30px 0'}>
                This will remove the payment method setup on your Intraverse account. Doing so will prevent the
                following:
            </Typography>

            <Card>
                <BulletPointRow>
                    <IconHolder>
                        <PurpleDot />
                    </IconHolder>
                    <Typography
                        variant="pWhite"
                        margin={'0'}
                        fontSize={'18px'}
                        color={`${setColor.circularProgress}`}
                        align={'left'}
                    >
                        You can no longer purchase dollar items from Intraverse and PRO stores.
                    </Typography>
                </BulletPointRow>
                <br />
                <BulletPointRow>
                    <IconHolder>
                        <PurpleDot />
                    </IconHolder>
                    <Typography
                        variant="pWhite"
                        margin={'0'}
                        fontSize={'18px'}
                        color={`${setColor.circularProgress}`}
                        align={'left'}
                    >
                        Your PRO store will become{' '}
                        <p style={{ display: 'inline', letterSpacing: 0.6, fontStyle: 'bold', color: '#ffe664' }}>
                            inaccessible
                        </p>{' '}
                        to other players and you won’t receive or process new orders.
                    </Typography>
                </BulletPointRow>
            </Card>
            <ButtonHolder>
                <Button
                    margin={'15px 0px 0px 0px'}
                    variant="PinkButton"
                    label="No, Go Back"
                    onClick={() => setDisconnectTabOn(false)}
                    buttonWidth="100%"
                />
                <Button
                    margin={'15px 0px 0px 0px'}
                    label="Yes, remove"
                    height={'40px'}
                    variant="OutlinedButton"
                    onClick={() => {
                        disconnectAccount();
                    }}
                    buttonWidth="100%"
                    fontSize="20px"
                />
            </ButtonHolder>
        </div>
    );
};

const BulletPointRow = styled.div`
    display: flex;
    box-sizing: border-box;
`;

const Card = styled.div`
    width: 100%;
    height: fit-content;
    border-radius: 10px;
    background: ${hexToRGBA(setColor.accent4, 0.1)};
    margin: 2rem auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

const IconHolder = styled.div`
    display: flex;
    padding-top: 6px;
    min-width: 30px;
    justify-content: space-evenly;
`;

const ButtonHolder = styled.div`
    margin: auto;
    width: 600px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media (min-width: 768px) and (max-width: 1440px) {
        width: 600px;
        margin-top: 10px;
        margin-right: ${(props) => (props.noMargin ? `0px` : '')};
        width: ${(props) => (props.noMargin ? `100%` : '')};
    }
    @media (max-width: 768px) {
        width: 90%;
        margin-top: 10px;
        margin-right: ${(props) => (props.noMargin ? `0px` : '')};
        width: ${(props) => (props.noMargin ? `100%` : '')};
    }
`;

export default DisconnectTab;
