import React, { useEffect } from 'react';
import { sendInfoToActionLog, sendEventsLog } from '../../api/UserController';
import { LOG_EVENTS_LISTENER_DATA } from '../../constants';

const casinoGames = [
    {
        name: '777_gold_spin',
        url: 'https://rgs-demo04.lab.wagerworks.com/skb/gateway?nscode=SKB&countrycode=GB&securetoken=RGS-DEMO04.SKB&skincode=IDV&technology=HTML%20&presenttype=STD&minbet=1.0&denomamount=1.0&currencycode=FPY&uniqueid=paolo1&language=en&softwareid=200-1609-001',
    },
    {
        name: 'fortune_coin',
        url: 'https://rgs-demo04.lab.wagerworks.com/skb/gateway?nscode=SKB&countrycode=GB&securetoken=RGS-DEMO04.SKB&skincode=IDV&technology=HTML%20&presenttype=STD&minbet=1.0&denomamount=1.0&currencycode=FPY&uniqueid=paolo1&language=en&softwareid=200-1581-001',
    },
    {
        name: 'cleo_money_mania',
        url: 'https://rgs-demo04.lab.wagerworks.com/skb/gateway?nscode=SKB&countrycode=GB&securetoken=RGS-DEMO04.SKB&skincode=IDV&technology=HTML%20&presenttype=STD&minbet=1.0&denomamount=1.0&currencycode=FPY&uniqueid=paolo1&language=en&softwareid=200-1623-001',
    },
    {
        name: 'video_poker',
        url: 'https://rgs-demo04.lab.wagerworks.com/skb/gateway?nscode=SKB&countrycode=GB&securetoken=RGS-DEMO04.SKB&skincode=IDV&technology=HTML%20&presenttype=STD&minbet=1.0&denomamount=1.0&currencycode=FPY&uniqueid=paolo1&language=en&softwareid=200-1204-001',
    },
];

function Igt() {
    const params = new URLSearchParams(window.location.search);
    const slot = params.get('slot');
    const ownerId = params.get('ownerId');
    const displayName = params.get('displayName');
    const bearerToken = params.get('token');
    const clientSessionId = params.get('clientsessionid');
    const envUnity = params.get('envunity');
    const [url, setUrl] = React.useState(null);

    useEffect(() => {
        if (slot) {
            let url = casinoGames.find((game) => game.name === slot)?.url;

            if (url) {
                const urlObj = new URL(url);
                const params = new URLSearchParams(urlObj.search);
                params.set('uniqueid', displayName);
                urlObj.search = params.toString();
                url = urlObj.toString();

                setUrl(url);
                console.log('Web: Generated Slot url: ', url);
                sendEventsLog(
                    bearerToken,
                    'igt',
                    '',
                    LOG_EVENTS_LISTENER_DATA,
                    'info',
                    { generatedSlotUrl: url },
                    clientSessionId,
                    envUnity
                );
            }
        }
    }, [slot]);

    useEffect(() => {
        async function handleMessage(event) {
            // check the event.source is the iframe that the game is in, also event.data.messageSource should be ‘IXF’. if so:

            if (event.data.messageType !== undefined) {
                const data = {
                    messageSource: event.data.messageSource,
                    messageType: event.data.messageType,
                    messageData: event.data.messageData,
                    timestamp: new Date().toISOString(),
                    displayName,
                };

                const allUndefined = Object.values(data).every((value) => value === undefined);
                const isEmpty = Object.keys(data).length === 0;

                if (!allUndefined && !isEmpty && event.data.messageType !== 'loadProgress') {
                    await sendInfoToActionLog(LOG_EVENTS_LISTENER_DATA, data, bearerToken, ownerId);
                    await sendEventsLog(
                        bearerToken,
                        'igt',
                        '',
                        LOG_EVENTS_LISTENER_DATA,
                        'info',
                        data,
                        clientSessionId,
                        envUnity
                    );
                }
            }
        }

        window.addEventListener('message', handleMessage);

        // Cleanup function
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <div>
            {url && (
                <iframe
                    title="IGT Iframe Content"
                    id="splash"
                    scrolling="no"
                    allow="autoplay"
                    style={{
                        position: 'absolute',
                        overflow: 'hidden',
                        width: '100%',
                        height: '100%',
                        display: 'block',
                        border: 0,
                    }}
                    src={url}
                />
            )}
        </div>
    );
}

export default Igt;
