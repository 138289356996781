import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { setColor } from '../../assets/styles/styles';
import { hexToRGBA } from '../../utils/generalUtils';
import BackgroundContainer from '../../components/common/Backgrounds/BackgroundContainer';
import FooterCheckout from './components/FooterCheckout';

const GuestCheckout = () => (
    <Container>
        <BackgroundContainer
            height={'100%'}
            width={'70vw'}
            padding={'0'}
            margin={'auto'}
            backgroundColor={`${setColor.innerProfileContainer}`}
            variant="DiamondBackground"
            borderLeft={`3px solid ${hexToRGBA(setColor.accent4, 0.1)}`}
            borderRight={`3px solid ${hexToRGBA(setColor.accent4, 0.1)}`}
        >
            <GuestCheckoutContainer>
                <Outlet />
                <FooterCheckout />
            </GuestCheckoutContainer>
        </BackgroundContainer>
    </Container>
);
export default GuestCheckout;

const Container = styled.div`
    height: 100%;
    width: 100%;
    background-color: ${setColor.inputBgColor};
`;
const GuestCheckoutContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: 730px) {
        ::-webkit-scrollbar {
            display: none;
        }
    }

    /* SCROLLBAR SPECIFICATIONS */

    &::-webkit-scrollbar {
        width: 0.75em; /* Adjust the width as needed */
        border-width: 1px;
    }

    &::-webkit-scrollbar-track {
        border-top-right-radius: 18px;
        border-bottom-right-radius: 18px;
        border-width: 1px;
    }

    &::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, rgba(27, 27, 35, 1) 0%, rgba(27, 27, 35, 1) 100%);
        border-radius: 10px; /* Adjust the border-radius as needed */
        border-width: 1px;
    }
`;
