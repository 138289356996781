const defaultState = {
    email: '',
    user: '',
    token: '',
    userId: '',
    role: '',
    isMerchant: '',
    profileIcon: '',
    expiration: 0,
};

const UserReducer = (state, action) => {
    switch (action.type) {
        case 'USER_SAVE': {
            return {
                ...state,
                email: action.payload.email,
                user: action.payload.user,
                token: action.payload.token,
                userId: action.payload.userId,
                role: action.payload.role,
                isMerchant: action.payload.isMerchant,
                profileIcon: action.payload.profileIcon,
                expiration: action.payload.expiration,
            };
        }

        case 'UPDATE_USER': {
            return {
                ...state,
                user: action.payload.user,
            };
        }

        case 'UPDATE_EMAIL':
            return {
                ...state,
                email: action.payload.email,
            };

        case 'UPDATE_USERNAME':
            return {
                ...state,
                username: action.payload.username,
            };

        case 'USER_DELETE': {
            return defaultState;
        }

        case 'REFRESH_TOKEN': {
            return {
                ...state,
                token: action.payload.token,
            };
        }

        default:
            if (state === undefined) {
                return defaultState;
            }
            return state;
    }
};

export default UserReducer;
