import React, { useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { setColor } from '../../../assets/styles/styles';
import { ReactComponent as NotFoundIcon } from '../../../assets/images/icons/NotFound.svg';
import { ReactComponent as DropdownIcon } from '../../../assets/images/icons/Chevron.svg';

const DataTable = ({ headers, data, renderRow, openModal, isModalOpen, detailsComponent }) => {
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const handleRowClick = (index, row) => {
        setSelectedRowIndex(index);
        setSelectedRowData(row);
        openModal(row); // Passing row data and detailsComponent to openModal
    };

    const getCircularReplacer = () => {
        const seen = new WeakSet();
        return (key, value) => {
          if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
              return;
            }
            seen.add(value);
          }
          return value;
        };
    };

    console.log("HEADERS", headers)
    return (
        <TableContainer>
            <thead>
                <TableRow>
                    {
                        headers.map((header, index) => {
                            if (typeof header !== "string") {
                                console.log(header)
                                return (
                                    <TableHeader key={index}>
                                        <p>{header.column_name}</p>
                                    </TableHeader>
                                )
                            }
                            return <TableHeader key={index}>{header}</TableHeader>
                        })
                    }
                </TableRow>
            </thead>
            <tbody>
                {data.length === 0 ? (
                    <tr>
                        <td colSpan={headers.length} align="center">
                            <NotFoundImage alt="Not Found" />
                        </td>
                    </tr>
                ) : (
                    data.map((row, index) => (
                        <StyledTableRow
                            key={index}
                            onClick={() => openModal(row)}
                            isSelected={index === selectedRowIndex}
                        >
                            {
                            renderRow(row).map((cell, idx) => {
                                if (typeof cell === "object" || "boolean") {
                                    // Case of a react element
                                    if (cell) {
                                        if (typeof cell.type === 'symbol') return (<TableCell key={idx}>{cell}</TableCell>)
                                    }
                                    // Arrays and Json object columns
                                    const json = JSON.stringify(cell, getCircularReplacer());
                                    return (
                                        <TableCell key={idx}>{json}</TableCell>
                                    )    
                                } 
                                return (
                                <TableCell key={idx}>{cell}</TableCell>
                                )
                            })
                            }
                        </StyledTableRow>
                    ))
                )}
            </tbody>
            {/* {isModalOpen && detailsComponent && <detailsComponent />} */}
        </TableContainer>
    );
};

export default DataTable;

const TableContainer = styled.table`
    width: 100%;
    height: 70vh;
    overflow-y: none;
    border-collapse: separate;
    border-spacing: 0 15px;
    background-color: transparent;
    overflow-x: auto;

    @media (max-width: 768px) {
        height: auto;
        overflow-y: auto;
    }
`;

const TableRow = styled.tr`
    margin-bottom: 15px;
    text-align: center;
    height: 50px;
    color: ${setColor.inputText};
`;
const TableHeader = styled.th`
    font-size: 17px;
    padding: 10px;
    text-align: left;
    color: rgba(190, 174, 255, 1);
    background-color: transparent;
`;

const NotFoundImage = styled(NotFoundIcon)`
    width: 300px;
    height: 300px;

    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;
const TableCell = styled.td`
    overflow-wrap: break-word;
    font-size: 15px;
    padding: 10px;
    width: auto;
    text-align: left;
    &:first-of-type {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    &:last-of-type {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;
const StyledTableRow = styled.tr`
    background-color: ${setColor.onHoverTextcolor2};
    cursor: pointer;
    color: ${setColor.inputText};

    &:hover {
        background-color: ${setColor.accent4};
        color: ${setColor.buttonTextBlack};
    }
`;

const StyledDropdownIcon = styled(DropdownIcon)`
    cursor: pointer;
    width: 15px;
    height: 15px;
    margin: 0px 0px -2px 5px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isopen === 'acs' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
