import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ButtonBackground from '../../../assets/images/buttonIcons/buttonBackground.png';

const PurpleButton = (props) => {
    const [loading, setLoading] = useState(false);

    return (
        <>
            {!props.value ? (
                <GreyBox>
                    <GreyBoxText>{props.greyBoxText}</GreyBoxText>
                </GreyBox>
            ) : (
                <PrimaryButton onClick={props.func} disabled={loading} id="submit-button">
                    <EnterButtonText>{props.label}</EnterButtonText>
                    <EnterButton src={ButtonBackground} alt={'Submit'} />
                </PrimaryButton>
            )}
        </>
    );
};

export default PurpleButton;

PurpleButton.defaultProps = {
    label: 'Next',
    greyBoxText: 'Next',
};

PurpleButton.propTypes = {
    label: PropTypes.string,
    func: PropTypes.func.isRequired,
    greyBoxText: PropTypes.string,
    value: PropTypes.any,
};

const GreyBox = styled.div`
    height: 55px;
    width: 200px;
    background-color: rgba(69, 66, 80, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;

    @media (max-width: 960px) {
        width: 150px;
        height: 40px;
    }
`;

const GreyBoxText = styled.p`
    font-size: 1.05rem;
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    color: #9da0a7;
    text-transform: capitalize;

    @media (max-width: 960px) {
        font-size: 0.85em;
    }
`;

const PrimaryButton = styled.button`
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    outline: none;
    border: none;
    z-index: 5;
    cursor: pointer;
`;

const EnterButtonText = styled.p`
    position: absolute;
    font-size: 1.05rem;
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    color: #e4deff;
    z-index: 10;

    @media (max-width: 960px) {
        font-size: 1em;
    }
`;

const EnterButton = styled.img`
    position: relative;
    height: auto;
    width: 200px;
    background-size: cover;
    background-position: center;
    z-index: 8;

    @media (max-width: 960px) {
        width: 150px;
    }
`;
