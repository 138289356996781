/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import CreateIcon from '@mui/icons-material/Create';
import { setColor } from '../../../assets/styles/styles';
import Iconify from '../icon/Iconify';

// import { setColor, setFont } from '../../../assets/styles/styles';
/**
 * Primary UI component for user interaction
 */
export const Input = ({
    borderColor,
    label,
    placeholder,
    type,
    hasLabel,
    onChange,
    inputIconColor,
    onClickHandler,
    inputIcon,
    customIcon,
    editIcon,
    editIconColor,
    editIconHoverColor,
    readonly,
    margin,
    variant,
    error,
    value,
    onChangeHandler,
    onKeyDownHandler,
    inputStyle,
    containerStyle,
    borderRadius,
    checkMarkIcon,
    maxLength,
    name,
    required,
    ...props
}) => {
    const [valueAuto, setValueAuto] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const InputComponent = Input.variants[variant] || Input.variants['DefaultInput'];
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            {hasLabel && <InputLabel>{label}</InputLabel>}
            {variant !== 'LoginInput' ? (
                <InputContainer
                    borderRadius={borderRadius}
                    style={containerStyle}
                    borderColor={borderColor}
                >
                    {inputIcon && (
                        <Iconify
                            icon={inputIcon}
                            alt="inputIcon"
                            Color={inputIconColor ? inputIconColor : 'white'}
                            style={{ margin: '5px', cursor: 'default', height: '20px', width: '20px' }}
                        />
                    )}
                    {customIcon && (
                        <img
                            src={customIcon}
                            alt="customIcon"
                            style={{
                                margin: '5px',
                                color: inputIconColor ? inputIconColor : 'white',
                                cursor: 'default',
                                height: '20px',
                                width: '20px',
                            }}
                        />
                    )}
                    <InputComponent
                        onClick={() => {
                            onClickHandler;
                        }}
                        name={name}
                        onKeyDown={onKeyDownHandler ? onKeyDownHandler : null}
                        type={type === 'password' && showPassword ? 'text' : type}
                        readonly={readonly}
                        onChange={onChangeHandler ? onChangeHandler : (e) => setValueAuto(e.target.value)}
                        placeholder={placeholder}
                        value={value ? value : valueAuto}
                        style={inputStyle}
                        borderRadius={borderRadius}
                        maxLength={maxLength}
                    />
                    {type === 'password' && (
                        <Iconify
                            icon={showPassword ? 'ph:eye-slash-duotone' : 'mdi:eye'}
                            onClick={toggleShowPassword}
                            Color={inputIconColor ? inputIconColor : 'white'}
                            style={{ margin: '5px', cursor: 'pointer' }}
                        />
                    )}
                    {editIcon && (
                        <CreateIcon
                            onClick={() => {
                                onClickHandler;
                            }}
                            sx={{ '&:hover': { color: '#beaeff', cursor: 'pointer' }, margin: '5px', color: '#7A7784' }}
                        />
                    )}
                </InputContainer>
            ) : (
                <PasswordInputContainer borderRadius={borderRadius} style={containerStyle}>
                    {inputIcon && (
                        <Iconify
                            icon={inputIcon}
                            alt="inputIcon"
                            Color={inputIconColor ? inputIconColor : 'white'}
                            style={{ margin: '5px 10px 0 10px', cursor: 'default', height: '25px', width: '25px' }}
                        />
                    )}
                    {customIcon && (
                        <img
                            src={customIcon}
                            alt="customIcon"
                            style={{
                                margin: '5px',
                                color: inputIconColor ? inputIconColor : 'white',
                                cursor: 'default',
                                height: '30px',
                                width: '30px',
                            }}
                        />
                    )}
                    <InputComponent
                        onClick={() => {
                            onClickHandler;
                        }}
                        required={required}
                        name={name}
                        type={type === 'password' && showPassword ? 'text' : type}
                        readonly={readonly}
                        onChange={onChangeHandler ? onChangeHandler : (e) => setValueAuto(e.target.value)}
                        placeholder={placeholder}
                        error={error}
                        value={value ? value : valueAuto}
                        style={inputStyle}
                        borderRadius={borderRadius}
                        maxLength={maxLength}
                    />
                    {type === 'password' && (
                        <Iconify
                            icon={showPassword ? 'ph:eye-slash-duotone' : 'mdi:eye'}
                            onClick={toggleShowPassword}
                            size={'25px'}
                            cursor="pointer"
                            Color={inputIconColor ? inputIconColor : 'white'}
                            iconMargin="5px"
                        />
                    )}
                    {checkMarkIcon && (
                        <Iconify
                            Color={inputIconColor ? inputIconColor : 'green'}
                            icon={checkMarkIcon}
                            style={{ fontSize: '28px', margin: '0 6px' }}
                        />
                    )}
                </PasswordInputContainer>
            )}
        </>
    );
};

Input.variants = {
    DefaultInput: styled.input`
        flex: 1;
        border: none;
        background: none;
        width: 90%;
        height: 50px;
        color: ${setColor.circularProgress};
        margin-left: 8px;
        font-family: 'Barlow', sans-serif;
        font-size: 16px;
        font-style: normal;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px ${setColor.passwordValidBox} inset !important;
            -webkit-text-fill-color: #ced1d9 !important;
        }
    `,
    LoginInput: styled.input`
        flex: 1;
        border: none;
        outline: none;
        padding: 0.5rem 1rem;
        font-size: 18px;
        font-style: italic;
        font-family: 'Barlow', sans-serif;
        color: ${setColor.iconColor};
        height: 40px;
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
        border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '5px')};
        background-color: ${setColor.inputBgColor};

        ::placeholder {
            color: ${setColor.iconColor};
            opacity: 50%;
            @media (max-width: 1440px) {
                font-size: 14px;
            }
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px ${setColor.passwordValidBox} inset !important;
            -webkit-text-fill-color: #ced1d9 !important;
        }
    `,
};

Input.propTypes = {
    variant: PropTypes.string,
    borderColor: PropTypes.string,
    type: PropTypes.string.isRequired,
    hasLabel: PropTypes.bool.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    inputIconColor: PropTypes.string,
    onClickHandler: PropTypes.func,
    inputIcon: PropTypes.string,
    customIcon: PropTypes.string,
    editIcon: PropTypes.bool,
    editIconColor: PropTypes.string,
    editIconHoverColor: PropTypes.string,
    readonly: PropTypes.bool,
    borderRadius: PropTypes.string,
    checkMarkIcon: PropTypes.string,
};

Input.defaultProps = {
    variant: 'DefaultInput',
    type: 'text',
    borderColor: null,
    hasLabel: false,
    label: '',
    placeholder: '',
    onChange: undefined,
    inputIconColor: 'grey',
    onClickHandler: null,
    inputIcon: null,
    customIcon: null,
    editIcon: null,
    editIconColor: 'grey',
    editIconHoverColor: 'white',
    readonly: false,
    borderRadius: '',
    checkMarkIcon: '',
};

const InputContainer = styled.div`
    display: flex;
    flex: 1;
    margin-right: ${(props) => (props.margin ? props.margin : '0 0 8px 0')};
    border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '5px')};
    border: ${(props) => (props.borderColor ? `2px solid ${props.borderColor}` : '2px solid #504c5c')};
    justify-content: center;
    background: ${setColor.inputBgColor};
    cursor: ${(props) => (props.readonly ? 'default' : 'text')};
    height: 50px;
    width: 90%;
    align-items: center;
    padding: 5px;

    @media (max-width: 768px) {
        margin: 5px;
        width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        margin: 5px;
        width: 100%;
    }
`;

const InputLabel = styled.div`
    text-align: left;
    color: #ced1d9;
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 5px;

    @media (max-width: 425px) {
        font-size: 14px;
        margin-top: 5px;
    }

    @media (max-height: 742px) {
        margin-top: 5px;
    }
`;

const PasswordInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${setColor.inputBgColor};
    border: 2px solid ${setColor.loadingButtonBg};
    border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '5px')};
    width: 100%;
    height: 45px;
    margin: 0.6rem 0rem;
`;
