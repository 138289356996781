import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from '../../config/axiosConfig';
import VagonStreamIntraverse from './VagonStreamIntraverse';
import { getFromSessionStorage } from '../../hooks/useSessionStorage';
import { isMobileDevice } from '../../utils/generalUtils';
import { sendInfoToActionLog } from '../../api/UserController';
import { actionTypes } from '../../utils/actionTypes';

const VanityURLRedirects = (props) => {
    const [redirectURL, setRedirectURL] = useState(null);

    useEffect(() => {
        const fetchVanityLink = async (id) => {
            try {
                const response = id ? await axiosInstance.get(`/web/vanity_links/${id}`) : { data: {} };
                const { roomId = '', staticUrl = process.env.REACT_APP_DEFAULT_STREAM_URL } = response.data;

                const launchFlags = {
                    '-roomid': roomId,
                    '-userid': props.userId,
                    '-authtoken': getFromSessionStorage('userToken') || props.token,
                    '-api': process.env.REACT_APP_STREAM_API_ENV,
                    '-disableVoiceChat': false,
                    '-showdebugger': true,
                    '-userdevicetype': isMobileDevice() ? 'mobile' : 'desktop',
                    '-avatarpreset': getFromSessionStorage('selectedAvatar'),
                };

                if (!roomId) {
                    delete launchFlags['-roomid'];
                }

                const queryString = Object.entries(launchFlags)
                    .map(([key, value]) => `${key}%20${value}`)
                    .join('%20');
                const redirectURL = `${staticUrl}?launchFlags=${queryString}`;
                // console.log('redirectURL', redirectURL);
                setRedirectURL(redirectURL);
            } catch (error) {
                if (process.env.REACT_APP_REDIRECT_TOGGLE) {
                    window.location.replace('https://intraverse.com/');
                }
                console.error('Error fetching the vanity link:', error);
            }
        };

        const pathId = window.location.pathname.split('/').pop();
        fetchVanityLink(pathId);
    }, [props.userId]);

    useEffect(() => {
        sendInfoToActionLog(actionTypes.SESSION_START, {}, getFromSessionStorage('userToken'));
    }, []);

    return redirectURL && <VagonStreamIntraverse streamUrl={redirectURL} spotifyPlayer={props?.spotifyPlayer} />;
};

export default VanityURLRedirects;

VanityURLRedirects.propTypes = {
    spotifyPlayer: PropTypes.object,
    userId: PropTypes.string,
    token: PropTypes.string,
};
